import React, { useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, Stack, Typography } from '@mui/material';
import {
    EventBookingItem as IEventBookingItem,
    useEvents,
} from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button } from '@travelity/ui';
import { format } from 'date-fns';
import { useUpdateBooking1 } from '@travelity/api/src/queries';
import { useForm } from '@travelity/form';
import { DailyViewHeader } from '../../views/events/components/daily-view-header';
import { EventItemMinimal } from '../event-item-minimal';
import ModalLoadingOverlay from '../modal-loading-overlay/modal-loading-overlay';
import { FilterItem } from '../filters/components/filter-item';
import { FilterTypes } from '../filters/filters.types';
import { ReactComponent as LoopSvg } from '../../assets/loop.svg';
import EventItemMinimalSkeleton from '../event-item-minimal/event-item-minimal-skeleton';

export interface MoveBookingModalProps {
    handleCancel: () => void;
    handleConfirm: () => void;
    booking: IEventBookingItem;
    open: boolean;
}

const dateFilter = {
    type: FilterTypes.DATE,
    name: 'date',
    label: 'Select other date',
};

const MoveBookingModal: React.FC<MoveBookingModalProps> = ({
    handleCancel,
    handleConfirm,
    booking,
    open,
}) => {
    const { Form, watch, setValue } = useForm({
        defaultValues: {
            date: new Date(booking.date.start * 1000),
        },
        mode: 'onChange',
    });
    const date = watch('date');

    const [selected, setSelected] = React.useState<string | null>(null);

    useEffect(() => {
        if (open) {
            setSelected(null);
            setValue('date', new Date(booking.date.start * 1000));
        }
    }, [open, booking]);

    const { data: allEvents, isFetching } = useEvents(
        useMemo(
            () => ({
                startTs: date.getTime() / 1000,
                endTs: date.getTime() / 1000 + 24 * 60 * 60,
                productId: booking.product.id,
            }),
            [booking.product.id, date]
        ),
        open
    );

    const events = useMemo(() => {
        if (!allEvents) return allEvents;
        return allEvents.filter(event => event.id !== booking.event.id);
    }, [allEvents, booking]);

    const errorFields: string[] = [];
    if (!selected) errorFields.push('Event');
    let lastTime: number;

    const { mutate: moveBooking, isLoading: isSaving } = useUpdateBooking1({
        onSuccess: handleConfirm,
    });

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff', maxWidth: '736px' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                <Typography sx={{ fontWeight: 600 }}>
                    Select booking’s parent event
                </Typography>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        py: 1.5,
                        height: '72px',
                    }}
                >
                    <Box>
                        <DailyViewHeader date={date} />
                    </Box>
                    <Stack>
                        <Form>
                            <FilterItem filter={dateFilter} value={date} />
                        </Form>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    pr: 1,
                    width: '703px',
                }}
            >
                <Box
                    sx={{
                        pr: 2,
                        height: 'calc(100% - 76px)',
                        '& > div': {
                            maxHeight: 'calc(100vh - 284px)',
                        },
                    }}
                >
                    {!isFetching ? (
                        <PerfectScrollbar>
                            {events?.length ? (
                                <Stack
                                    sx={{
                                        pr: 0.5,
                                        pb: 1,
                                    }}
                                    gap={1}
                                >
                                    {events.map(event => {
                                        const newTime =
                                            lastTime !==
                                            event.date.start.getTime();
                                        lastTime = event.date.start.getTime();

                                        return (
                                            <>
                                                {newTime && (
                                                    <Divider
                                                        sx={{
                                                            my: 1,
                                                            '.MuiDivider-wrapper':
                                                                {
                                                                    color: '#9198AA',
                                                                },
                                                        }}
                                                    >
                                                        {format(
                                                            event.date.start,
                                                            'HH:mm'
                                                        )}
                                                    </Divider>
                                                )}
                                                <EventItemMinimal
                                                    key={event.id}
                                                    event={event}
                                                    selected={
                                                        selected === event.id
                                                    }
                                                    onSelect={() =>
                                                        setSelected(event.id)
                                                    }
                                                />
                                            </>
                                        );
                                    })}
                                </Stack>
                            ) : (
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        height: 1,
                                        minHeight: '200px',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    gap={1}
                                >
                                    <LoopSvg />
                                    <Typography sx={{ color: '#949BAC' }}>
                                        No Events
                                    </Typography>
                                </Stack>
                            )}
                        </PerfectScrollbar>
                    ) : (
                        <Box
                            sx={{
                                py: 2,
                                height: 'calc(100% - 72px - 21px)',
                            }}
                        >
                            <Stack
                                sx={{
                                    px: 2,
                                }}
                                gap={1}
                            >
                                {[0, 1, 2].map(v => (
                                    <EventItemMinimalSkeleton key={v} />
                                ))}
                            </Stack>
                        </Box>
                    )}
                </Box>
                {isSaving && <ModalLoadingOverlay />}
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                        px: '26px',
                        py: '10px',
                    }}
                >
                    Cancel
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    requiredFields={errorFields}
                    onClick={() => {
                        if (selected) {
                            moveBooking({
                                bookingId: booking.id,
                                eventId: selected,
                            });
                        }
                    }}
                    sx={{
                        py: '10px',
                        px: 4,
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MoveBookingModal;
