import React, { useCallback, useEffect, useMemo } from 'react';
import {
    CreatableTags,
    PaxPicker,
    SearchSelect,
    Select,
    TagsList,
    TextField,
    useForm,
} from '@travelity/form';
import { Box, Chip, Stack, Typography } from '@mui/material';
import {
    AvailableProduct,
    convertDurationDtoToDuration,
    DiscountType,
    ProductOptionType,
    AvailabilityFilterValues,
} from '@travelity/api';
import { useTranslation } from 'react-i18next';
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {
    useCreateBooking,
    useDebouncedPrice,
} from '@travelity/api/src/services/order/order.hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Customer } from '@travelity/api/src/services/customer/customer.types';
import { durationToHumanReadable } from '@travelity/utils';
import { useHelperContext } from '../../../contexts/helper-context';
import { ProductTypeIcon } from '../../../components/product-type-icon';
import { SquareCard } from '../../../components/square-card';
import { useSelectOptions } from '../../../hooks';
import SelectCustomer from '../../../components/select-customer/select-customer';
import { PriceSummary } from '../../../components/price-summary';
import { FilterPaxValue } from '../../../components/filters/filters.types';
import { RouteTooltip } from '../../../components/route-tooltip/route-tooltip';
import schema from './booking.side-from.schema';
import { SideFormLoadingOverlay } from '../../../components/side-form-loading-overlay';

export interface BookingSideFormProps {
    availableProduct: AvailableProduct;
    time: number;
    filters: AvailabilityFilterValues;
    changeTime: (v: number) => void;
    onSuccess: () => void;
    discard: () => void;
}

export interface BookingCreationForm {
    options: string[];
    customer: Customer;
    discountType: DiscountType;
    discountAmount: number;
    time: number;
    pax: FilterPaxValue;
    notes: { value: string }[];
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

const BookingSideForm: React.FC<BookingSideFormProps> = props => {
    const { t } = useTranslation('product');
    const { availableProduct, time, filters, discard, onSuccess, changeTime } =
        props;
    const { mutate: createBooking, isLoading: isSaving } = useCreateBooking({
        onSuccess: () => {
            onSuccess();
        },
    });

    const selectedEvent = useMemo(() => {
        return availableProduct.events.find(
            ({ time: timestamp }) => timestamp === time
        );
    }, [availableProduct, time]);

    const {
        Form,
        watch,
        setValue,
        formState: { errors },
    } = useForm<BookingCreationForm>({
        schema,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: {
            time,
            pax: filters.pax,
            discountType: DiscountType.RELATIVE,
        },
        onSubmit: useCallback(
            (data: BookingCreationForm, e?: React.BaseSyntheticEvent) => {
                if (!selectedEvent) return;
                const productOptions =
                    availableProduct.options
                        ?.filter(o => data.options?.includes(o.name))
                        .map(({ name, type }) => ({ name, type })) || [];
                createBooking({
                    customer: data.customer,
                    event: selectedEvent,
                    notes: data.notes?.map(({ value }) => value),
                    productOptions,
                    // @ts-ignore
                    isDraft: e?.nativeEvent.submitter.dataset.draft,
                });
            },
            []
        ),
    });
    const errorFields = Object.values(errors)
        .map(error => error?.message)
        .filter(v => v) as string[];

    const stops = useMemo(() => {
        if (!availableProduct.route) return [];
        const list = [availableProduct.route.beginningLocation];

        availableProduct.route.stops.forEach(stop => {
            list.push(stop.name);
        });

        list.push(
            availableProduct.route.endingLocation ||
                availableProduct.route.beginningLocation
        );
        return list;
    }, [availableProduct]);

    const formTime = watch('time');
    useEffect(() => changeTime(formTime), [formTime]);
    useEffect(() => {
        if (time !== formTime) setValue('time', time);
    }, [time]);

    const options = useSelectOptions(
        availableProduct.options,
        'name',
        'name',
        true
    );

    const availableTimeOptions = useMemo(
        () =>
            availableProduct.events.map(({ time: timestamp }) => ({
                value: timestamp,
                label: format(timestamp, 'HH:mm'),
            })) || [],
        [availableProduct]
    );

    const minPriceFormatted = `${numberWithSpaces(availableProduct.minPrice)} ${
        availableProduct.currency
    }`;
    const duration = selectedEvent?.rawDto?.date?.duration
        ? durationToHumanReadable(
              convertDurationDtoToDuration(selectedEvent.rawDto.date.duration)
          )
        : '';

    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo(
            <>
                Product option is an item or a service that can be supplied as
                part of the product for an additional price or for free.
                <br />
                You give the freedom to your travelers to pick only options they
                need.
                <br />
                Travelity assumes that if the main product is available, then
                any product option is available with it.
            </>
        );
    }, [setInfo]);

    const prepaymentTypeOptions = useMemo(
        () => [
            { value: 'relative', label: '%' },
            {
                value: 'absolute',
                label: availableProduct.currency || 'Absolute',
            },
        ],
        [availableProduct.currency]
    );

    const selectedOptions = watch('options');
    const discountAmount = watch('discountAmount');
    const discountType = watch('discountType');
    const priceRequestParams = useMemo(() => {
        return {
            product: {
                id: availableProduct.id,
                financials: {
                    pricing: {},
                },
                options: selectedOptions
                    ?.map(name => options.find(o => name === o.name))
                    .map(option =>
                        option
                            ? { name: option.name, type: option.type }
                            : undefined
                    )
                    .filter(v => v) as {
                    name: string;
                    type: ProductOptionType;
                }[],
            },
            participants: {
                pax: filters.pax as FilterPaxValue,
            },
            financials: discountAmount
                ? {
                      price: {
                          discount: {
                              type: discountType,
                              amount: discountAmount,
                          },
                      },
                  }
                : undefined,
        };
    }, [
        selectedOptions,
        discountAmount,
        discountType,
        time,
        availableProduct.id,
    ]);

    const { data: price, isLoading: isPriceLoading } = useDebouncedPrice(
        priceRequestParams,
        1000
    );

    return (
        <>
            <Stack sx={{ pl: 2, pr: 0.5, height: 1 }}>
                <Stack
                    direction="row"
                    sx={{ pr: 1.5, pt: 1 }}
                    alignItems="center"
                    gap={1}
                >
                    <Typography
                        sx={{
                            color: '#2B395B',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            pr: 1,
                            overflow: 'hidden',
                            textWrap: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {availableProduct.name}
                    </Typography>
                    <Chip
                        sx={{
                            color: '#fff',
                            bgcolor: '#3B4D7D',
                            px: '12px',
                            py: '6px',
                            '& .MuiChip-label': {
                                pl: 0.5,
                                pr: 0,
                            },
                        }}
                        icon={<ProductTypeIcon type={availableProduct.type} />}
                        label={t(availableProduct.type)}
                    />
                    {!!stops.length && (
                        <RouteTooltip stops={stops}>
                            <Chip
                                color="secondary"
                                sx={{
                                    color: '#36869C',
                                    bgcolor: '#DDF0F5',
                                    px: '12px',
                                    py: '6px',
                                    '& .MuiChip-label': {
                                        pl: 0.5,
                                        pr: 0,
                                    },
                                    '& .MuiChip-icon': {
                                        mx: 0,
                                    },
                                }}
                                icon={
                                    <DirectionsRoundedIcon
                                        sx={{
                                            fontSize: '12px',
                                            color: '#36869C',
                                        }}
                                    />
                                }
                                label="Route"
                            />
                        </RouteTooltip>
                    )}
                </Stack>

                <Stack
                    sx={{
                        grow: 2,
                        px: 1,
                        pr: 2.5,
                        py: 2,
                        pt: '10px',
                        height: '139px',
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                    gap={1}
                >
                    <SquareCard
                        title="Start Time"
                        value={format(time, 'HH:mm')}
                        Icon={AccessTimeIcon}
                        count={selectedEvent?.availableSeats ? 4 : 3}
                    />
                    <SquareCard
                        title="Duration"
                        value={duration}
                        Icon={AccessTimeIcon}
                        count={selectedEvent?.availableSeats ? 4 : 3}
                    />
                    {!!selectedEvent?.availableSeats && (
                        <SquareCard
                            title="Available"
                            value={`${selectedEvent?.availableSeats} seats`}
                            Icon={ConfirmationNumberIcon}
                            count={selectedEvent?.availableSeats ? 4 : 3}
                        />
                    )}
                    <SquareCard
                        title="Min Price"
                        value={minPriceFormatted}
                        Icon={PaymentsOutlinedIcon}
                        count={selectedEvent?.availableSeats ? 4 : 3}
                    />
                </Stack>
                <Form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 2,
                        position: 'relative',
                        height: 'calc(100% - 144px)',
                    }}
                >
                    <Box
                        sx={{
                            minHeight: 0,
                            flexGrow: 2,
                        }}
                    >
                        <PerfectScrollbar>
                            <Stack gap={2} sx={{ py: 1, pr: 1.5 }}>
                                {!!options?.length && (
                                    <SearchSelect
                                        name="options"
                                        label="Product Option"
                                        placeholder="Select Product Option"
                                        width="100%"
                                        options={options}
                                        multiple
                                    />
                                )}
                                <Box sx={{ overflow: 'hidden' }}>
                                    <Typography
                                        sx={{
                                            color: '#2B395B',
                                            fontSize: '12px',
                                        }}
                                    >
                                        Select Available Time
                                    </Typography>
                                    <TagsList
                                        name="time"
                                        options={availableTimeOptions}
                                        gap={1}
                                        tagSx={{
                                            bgcolor: '#DDF0F5',
                                            color: '#2B395B',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            p: '10px 16px',
                                            '&.MuiChip-colorPrimary': {
                                                bgcolor: '#55B5CF',
                                                color: '#FFFFFF',
                                                fontWeight: 400,
                                            },
                                        }}
                                    />
                                </Box>
                                <PaxPicker
                                    name="pax"
                                    withDropdown
                                    selectProps={{
                                        disabled: true,
                                        width: '100%',
                                        label: 'PAX',
                                        placeholder: 'Select PAX',
                                    }}
                                />
                                <SelectCustomer
                                    name="customer"
                                    label="Customer Name or Email"
                                    placeholder="Type Customer Name or Email"
                                    width="100%"
                                    options={[]}
                                />
                                <Stack
                                    direction="row"
                                    gap={1}
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <TextField
                                        label="Discount"
                                        placeholder="Type Discount"
                                        name="discountAmount"
                                        sx={{ flexGrow: 2 }}
                                    />
                                    <Select
                                        width="140px"
                                        name="discountType"
                                        label=""
                                        options={prepaymentTypeOptions}
                                    />
                                </Stack>
                                <CreatableTags
                                    name="notes"
                                    inputProps={{
                                        label: 'Notes',
                                        placeholder:
                                            'Type and press enter to add note',
                                    }}
                                    chipDirection="column"
                                    chipSx={{
                                        borderRadius: '12px',
                                        padding: '8px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: '10px',
                                        bgcolor: '#F4F6FA',
                                        '& > .MuiChip-label': {
                                            color: '#2B395B',
                                            fontSize: '12px',
                                            textWrap: 'wrap',
                                        },
                                    }}
                                />
                            </Stack>
                        </PerfectScrollbar>
                    </Box>
                    <Stack
                        sx={{
                            width: 1,
                            zIndex: 1,
                            pr: 1.5,
                            pb: 2,
                        }}
                    >
                        <PriceSummary
                            price={price}
                            isLoading={isPriceLoading}
                        />
                        <Stack direction="row" mt={2} gap={1}>
                            <Button
                                variant="contained"
                                color="neutral"
                                sx={{ flexGrow: 1 }}
                                onClick={discard}
                            >
                                Discard
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ flexGrow: 2 }}
                                type="submit"
                                data-draft
                                disabled={!!errorFields.length}
                            >
                                Save as Draft
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ flexGrow: 1 }}
                                disabled={!!errorFields.length}
                                type="submit"
                            >
                                Confirm
                            </Button>
                        </Stack>
                    </Stack>
                </Form>
            </Stack>
            {isSaving && <SideFormLoadingOverlay />}
        </>
    );
};

export default React.memo(BookingSideForm);
