import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from '../layouts/main-layout';
import AuthenticationGuard from './authentication-guard';
// import UserProfile from '../views/user-profile';
// import Home from '../views/home';
import Availability from '../views/availability';
import AddProduct from '../views/add-product';
import Products from '../views/products';
import SelectType from '../views/select-type';
import Error404 from '../views/error-404';
import Bookings from '../views/bookings';
import Orders from '../views/orders';
import Events from '../views/events';

const MainRoutes = {
    path: '/',
    element: <AuthenticationGuard component={MainLayout} />,
    children: [
        // {
        //     path: '/',
        //     element: <Home />,
        // },
        {
            path: '/availability',
            element: <Availability />,
        },
        {
            path: '*',
            element: <Error404 />,
        },
        {
            path: '/products/add',
            element: <SelectType />,
        },
        {
            path: '/products/add/:productType',
            element: <AddProduct />,
        },
        {
            path: '/products/:productId',
            element: <Products />,
        },
        {
            path: '/products',
            element: <Products />,
        },
        {
            path: '/orders/:orderId',
            element: <Orders />,
        },
        {
            path: '/events/:eventId/:bookingId',
            element: <Events />,
        },
        {
            path: '/events/:eventId',
            element: <Events />,
        },
        {
            path: '/events',
            element: <Events />,
        },
        {
            path: '/orders',
            element: <Orders />,
        },
        {
            path: '/order/:orderId/preview',
            element: <Bookings preview />,
        },
        {
            path: '/order/:orderId/:bookingId',
            element: <Bookings />,
        },
        {
            path: '/order/:orderId',
            element: <Bookings />,
        },
        {
            path: '/',
            element: <Navigate to="/products" />,
        },
        // {
        //     path: '/me',
        //     element: <UserProfile />,
        // },
    ],
};

export default MainRoutes;
