import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from '@travelity/form';
import { ProductOptionForm } from '../../../components/product-option-form';
import schema from '../../../components/product-option-form/product-option-form.schema';
import StepContent from './step-content';
import { useStepperContext } from '../../../components/stepper';
import { AddProductFormState } from '../add-product.types';
import { useHelperContext } from '../../../contexts/helper-context';

export interface OptionsStepProps {}

const OptionsStep: React.FC<OptionsStepProps> = () => {
    const { state, next } = useStepperContext<AddProductFormState>();
    const onSubmit = useCallback((data: Record<string, any>) => {
        next({ ...state, options: data });
    }, []);
    const { Form, formState } = useForm({
        onSubmit,
        schema,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: state.options,
    });

    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo(
            <>
                Product option is an item or a service that can be supplied as
                part of the product for an additional price or for free.
                <br />
                You give the freedom to your travelers to pick only options they
                need.
                <br />
                Travelity assumes that if the main product is available, then
                any product option is available with it.
            </>
        );
    }, [setInfo]);

    const errors = useMemo(() => {
        return Object.values(formState.errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [formState]);
    return (
        <StepContent title="Product Options" errors={errors}>
            <Form id="step">
                <ProductOptionForm currency={state.financial?.currency} />
            </Form>
        </StepContent>
    );
};

export default React.memo(OptionsStep);
