import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
} from '@mui/material';
import { Booking, patchBookingToBookingDto } from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useUpdateBooking } from '@travelity/api/src/queries';
import { useForm } from '@travelity/form';
import { PersonNameData } from '@travelity/api/src/requests';
import AccordionButtons from '../../orders/components/accordion-buttons';
import participantsSchema from '../../../components/booking-participants/booking-participants.schema';
import { BookingParticipantsForm } from '../../../components/booking-participants';
import financialInformationSchema from '../../../components/booking-financial-information/booking-financial-information.schema';
import { BookingFinancialInformationForm } from '../../../components/booking-financial-information';
import productSchema from '../../../components/booking-product/booking-product.schema';
import { BookingProductForm } from '../../../components/booking-product';
import scheduleSchema from '../../../components/booking-schedule/booking-schedule.schema';
import { BookingScheduleForm } from '../../../components/booking-schedule';
import notesSchema from '../../../components/booking-notes/booking-notes.schema';
import { BookingNotesForm } from '../../../components/booking-notes';
import { PaymentStatusTag } from '../../../components/payment-status';
import { BookingStatusTag } from '../../../components/booking-status-tag';
import { participantsToParticipantsForm } from '../../../components/booking-participants/booking-participants.converters';

export interface BookingEditFormProps {
    booking: Booking;
    updateBooking: () => void;
    // updateBooking: (id: string, booking: Booking) => void;
}

const BookingEditForm: React.FC<BookingEditFormProps> = ({
    booking,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateBooking,
}) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState<number | undefined>(
        location.state || 0
    );

    const [editing, setEditing] = useState(false);
    const toggleExpanded = (number: number) => {
        if (editing) return;
        setExpanded(s => (s === number ? undefined : number));
    };

    useEffect(() => {
        if (location.state && !editing) setExpanded(location.state);
    }, [location.state]);

    const { enqueueSnackbar } = useSnackbar();
    const { mutate: update, isLoading: isSaving } = useUpdateBooking({
        onSuccess: () => {
            setEditing(false);
        },
        onError: (error: any) => {
            if (error?.status === 409) {
                enqueueSnackbar('Nothing to save', { variant: 'error' });
            } else if (error?.body) {
                enqueueSnackbar(error.body, { variant: 'error' });
            }
        },
    });
    const onSubmit = useCallback(
        (data: any, e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
            const key = e?.target.id;
            if (key === 'participants') {
                updateBooking();
            } else {
                update(
                    {
                        bookingId: booking.id,
                        requestBody: patchBookingToBookingDto({
                            [key]:
                                // eslint-disable-next-line no-nested-ternary
                                key === 'notes'
                                    ? data.notes?.map(
                                          ({ value }: { value: string }) =>
                                              value
                                      )
                                    : data,
                        }),
                    },
                    {
                        onSuccess: () => {
                            updateBooking();
                        },
                    }
                );
            }
        },
        [booking]
    );
    const {
        Form: ScheduleForm,
        reset: resetSchedule,
        // formState: { errors: scheduleErrors },
    } = useForm({
        defaultValues: {
            start: new Date(booking.date.start * 1000),
        },
        onSubmit,
        mode: 'onChange',
        schema: scheduleSchema,
    });
    const {
        Form: ParticipantsForm,
        reset: resetParticipants,
        formState: { errors: participantsErrors },
    } = useForm({
        defaultValues: participantsToParticipantsForm(booking.participants),
        onSubmit,
        mode: 'onChange',
        schema: participantsSchema,
    });
    const {
        Form: FinancialInformationForm,
        reset: resetFinancialInformation,
        // formState: { errors: financialInformationErrors },
    } = useForm({
        defaultValues: booking.financials,
        onSubmit,
        mode: 'onChange',
        schema: financialInformationSchema,
    });
    const {
        Form: ProductForm,
        reset: resetProduct,
        // formState: { errors: productErrors },
    } = useForm({
        defaultValues: { values: [] },
        onSubmit,
        mode: 'onChange',
        schema: productSchema,
    });
    const {
        Form: NotesForm,
        reset: resetNotes,
        formState: { errors: notesErrors },
    } = useForm({
        defaultValues: {
            notes:
                booking.notes?.map(value => ({
                    value,
                })) || [],
        },
        onSubmit,
        mode: 'onChange',
        schema: notesSchema,
    });

    const customerName =
        booking?.participants?.customer?.name || ({} as PersonNameData);
    const customerFullName = `${customerName.first} ${customerName.last}`;

    const reset = useCallback(() => {
        switch (expanded) {
            case 0:
                resetSchedule({
                    start: new Date(booking.date.start * 1000),
                });
                break;
            case 1:
                resetParticipants(
                    participantsToParticipantsForm(booking.participants)
                );
                break;
            case 2:
                resetFinancialInformation(booking.financials);
                break;
            case 3:
                resetProduct({ values: [] });
                break;
            case 4:
                resetNotes({
                    notes: booking.notes?.map(value => ({
                        value,
                    })),
                });
                break;
            default:
        }
    }, [expanded, booking]);

    const onCancel = useCallback(() => {
        reset();
        setEditing(false);
    }, [reset]);

    useEffect(() => {
        reset();
        setEditing(false);
    }, [booking]);

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: 'calc(100% - 30px)',
                },
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '8px 18px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '0px 0px 12px 0px',
                        background: '#6B748C',
                        color: '#FFF',
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '100%',
                        textTransform: 'capitalize',
                    }}
                >
                    Booking Preview
                </Box>
            </Stack>
            <PerfectScrollbar>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                    sx={{
                        px: 2,
                        height: '46px',
                        py: '10px',
                        borderRadius: '0px 12px 0px 0px',
                    }}
                >
                    <BookingStatusTag status={booking.status} />
                    {booking.financials?.status && (
                        <PaymentStatusTag status={booking.financials?.status} />
                    )}
                </Stack>
                <Box
                    sx={{
                        '&&& .MuiAccordionSummary-root': {
                            borderBottom: '1px solid #DFE1ED',
                        },
                        '&&& .MuiAccordionDetails-root': {
                            py: 3,
                        },
                    }}
                >
                    <ScheduleForm id="schedule">
                        <Accordion
                            disableGutters
                            expanded={expanded === 0}
                            onChange={() => toggleExpanded(0)}
                        >
                            <AccordionSummary>
                                Booking Date and Time
                            </AccordionSummary>
                            <AccordionDetails>
                                <BookingScheduleForm
                                    date={booking.date}
                                    preview={!editing || expanded !== 0}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ScheduleForm>
                    <ParticipantsForm id="participants">
                        <Accordion
                            disableGutters
                            expanded={expanded === 1}
                            onChange={() => toggleExpanded(1)}
                        >
                            <AccordionSummary>
                                Participants
                                {expanded === 1 && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isSaving}
                                        errors={participantsErrors}
                                        onEditCancel={onCancel}
                                        onEdit={() => setEditing(true)}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <BookingParticipantsForm
                                    preview={!editing || expanded !== 1}
                                    bookingId={booking.id}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ParticipantsForm>
                    <FinancialInformationForm id="financialInformation">
                        <Accordion
                            disableGutters
                            expanded={expanded === 2}
                            onChange={() => toggleExpanded(2)}
                        >
                            <AccordionSummary>
                                Financial Information
                            </AccordionSummary>
                            <AccordionDetails>
                                <BookingFinancialInformationForm
                                    operator={customerFullName}
                                    financials={booking.financials}
                                    bookingId={booking.id}
                                    preview={!editing || expanded !== 2}
                                    refetch={updateBooking}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </FinancialInformationForm>
                    <ProductForm id="product">
                        <Accordion
                            disableGutters
                            expanded={expanded === 3}
                            onChange={() => toggleExpanded(3)}
                        >
                            <AccordionSummary>Product</AccordionSummary>
                            <AccordionDetails>
                                <BookingProductForm
                                    product={booking.product}
                                    preview={!editing || expanded !== 3}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ProductForm>
                    <NotesForm id="notes">
                        <Accordion
                            disableGutters
                            expanded={expanded === 4}
                            onChange={() => toggleExpanded(4)}
                        >
                            <AccordionSummary>
                                Notes
                                {expanded === 4 && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isSaving}
                                        errors={notesErrors}
                                        onEditCancel={onCancel}
                                        onEdit={() => setEditing(true)}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <BookingNotesForm
                                    preview={!editing || expanded !== 4}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </NotesForm>
                </Box>
            </PerfectScrollbar>
        </Box>
    );
};

export default React.memo(BookingEditForm);
