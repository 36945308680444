import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, useForm } from '@travelity/form';
import { Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import { Customer } from '@travelity/api/src/services/customer/customer.types';
import { useCreateCustomer } from '@travelity/api/src/queries';
import { getCustomerDtoToCustomer } from '@travelity/api/src/services/customer/customer.converters';
import schema from './create-customer.schema';

const formId = 'create-customer-modal';

export interface CreateCustomerModalProps {
    handleCancel: () => void;
    handleConfirm: (v: Customer) => void;
    open: boolean;
    searchText?: string;
}

type CreateCustomer = Omit<Customer, 'id'>;

const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({
    handleCancel,
    handleConfirm,
    open,
    searchText,
}) => {
    const navigate = useNavigate();

    const { mutate: createCustomer } = useCreateCustomer();
    const {
        Form,
        reset,
        trigger,
        formState: { errors },
    } = useForm<CreateCustomer>({
        schema,
        isModal: true,
        mode: 'onChange',
        validateInitially: true,
        onSubmit: useCallback(
            (data: CreateCustomer) => {
                createCustomer(
                    {
                        requestBody: {
                            contact_details: {
                                emails: [data.email],
                            },
                            name: {
                                first: data.firstName,
                                last: data.lastName,
                            },
                        },
                    },
                    {
                        onSuccess: customer => {
                            handleConfirm(getCustomerDtoToCustomer(customer));
                        },
                    }
                );
            },
            [navigate]
        ),
    });

    useEffect(() => {
        if (open) {
            if (searchText?.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                reset({ email: searchText });
            else if (searchText?.match(/ /)) {
                reset({
                    firstName: searchText?.split(' ')[0],
                    lastName: searchText?.split(' ')[1],
                });
            } else {
                reset({});
            }
            trigger();
        }
    }, [searchText, open, reset, trigger]);

    useEffect(() => {
        if (!open) {
            reset({});
            trigger();
        }
    }, [open, reset, trigger]);

    const errorFields = Object.values(errors)
        .map(error => error?.message)
        .filter(v => v) as string[];

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            sx={{
                '& .MuiDialog-container': { justifyContent: 'end' },
            }}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff', maxWidth: '388px' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                Add Customer
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    pr: 1,
                    '& > div': {
                        pr: 2,
                        maxHeight: 'calc(100vh - 212px)',
                    },
                }}
            >
                <PerfectScrollbar>
                    <Form id={formId}>
                        <Stack
                            direction="column"
                            gap={2}
                            sx={{ pt: 2, width: '340px' }}
                        >
                            <TextField
                                placeholder="Type email"
                                name="email"
                                label="Email"
                                showErrors
                                fullWidth
                            />
                            <TextField
                                placeholder="Type First Name"
                                name="firstName"
                                label="First Name"
                                fullWidth
                            />
                            <TextField
                                placeholder="Type Last Name"
                                name="lastName"
                                label="Last Name"
                                fullWidth
                            />
                        </Stack>
                    </Form>
                </PerfectScrollbar>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                        px: '26px',
                        py: '10px',
                    }}
                >
                    Discard
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    disabled={!!errorFields.length}
                    sx={{
                        py: '10px',
                        '&&&': { pointerEvents: 'initial' },
                    }}
                >
                    Add Customer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateCustomerModal;
