import React from 'react';
import { EventStatuses } from '@travelity/api';
import { StatusTag, StatusTagStatuses } from '@travelity/ui';
import { useTranslation } from 'react-i18next';

export interface EventStatusProps {
    status: EventStatuses;
}

const statusMap: Record<EventStatuses, StatusTagStatuses> = {
    [EventStatuses.DRAFT]: StatusTagStatuses.ERROR,
    [EventStatuses.ONGOING]: StatusTagStatuses.WARNING,
    [EventStatuses.FINISHED]: StatusTagStatuses.SUCCESS,
    [EventStatuses.CANCELLED]: StatusTagStatuses.NEUTRAL,
    [EventStatuses.UPCOMING]: StatusTagStatuses.NEUTRAL,
};

export const EventStatus: React.FC<EventStatusProps> = props => {
    const { t } = useTranslation('event');
    const { status } = props;
    return (
        <StatusTag
            small
            sx={{ borderRadius: '8px' }}
            status={statusMap[status]}
            value={t(status).toUpperCase()}
        />
    );
};
