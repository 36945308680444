import { Weekdays } from '@travelity/form';
import {
    Country,
    DayOfWeek,
    GetUserResDto,
    Nationality,
    UpdateUserReqDto,
    WeekData,
} from '../../requests';
import { User } from './user.types';

const convertDaysOfWeekToWeekDays = (daysOfWeek: DayOfWeek): Weekdays => {
    switch (daysOfWeek) {
        case DayOfWeek.MONDAY:
            return Weekdays.MONDAY;
        case DayOfWeek.TUESDAY:
            return Weekdays.TUESDAY;
        case DayOfWeek.WEDNESDAY:
            return Weekdays.WEDNESDAY;
        case DayOfWeek.THURSDAY:
            return Weekdays.THURSDAY;
        case DayOfWeek.FRIDAY:
            return Weekdays.FRIDAY;
        case DayOfWeek.SATURDAY:
            return Weekdays.SATURDAY;
        case DayOfWeek.SUNDAY:
        default:
            return Weekdays.SUNDAY;
    }
};

const dayOfWeek: Record<string, DayOfWeek> = {
    mon: DayOfWeek.MONDAY,
    tue: DayOfWeek.TUESDAY,
    wed: DayOfWeek.WEDNESDAY,
    thu: DayOfWeek.THURSDAY,
    fri: DayOfWeek.FRIDAY,
    sat: DayOfWeek.SATURDAY,
    sun: DayOfWeek.SUNDAY,
};

export const userDtoToUser = (userDto: GetUserResDto): User => ({
    id: userDto.id,
    fullName: `${userDto.name.first} ${userDto.name.last}`,
    languages: userDto.languages,
    occupation: userDto.occupation,
    workload: !!userDto.app_settings?.vendibility?.workload,
    maxEvents:
        userDto.app_settings?.vendibility?.workload?.max_events_per_day ||
        undefined,
    maxEventsUnlimited:
        userDto.app_settings?.vendibility?.workload?.max_events_per_day ===
        10000,
    maxHours:
        userDto.app_settings?.vendibility?.workload?.max_booked_hours_per_day ||
        undefined,
    maxHoursFullDay:
        userDto.app_settings?.vendibility?.workload
            ?.max_booked_hours_per_day === 24,
    weekdays: userDto.app_settings?.vendibility?.workweek?.days.map(
        ({ day_of_week }) => convertDaysOfWeekToWeekDays(day_of_week)
    ),
    weekdayHours: userDto.app_settings?.vendibility?.workweek?.days.reduce(
        (obj, cur) => ({
            ...obj,
            [convertDaysOfWeekToWeekDays(cur.day_of_week)]:
                cur.available_hours.map(({ start, end }) => ({
                    start: start.hours * 60 + (start?.minutes || 0),
                    end: end.hours * 60 + (end?.minutes || 0),
                })),
        }),
        {}
    ),
    concurrency: !!userDto.app_settings?.vendibility?.concurrency,
    concurrencyValue: !!userDto.app_settings?.vendibility?.concurrency?.enabled,
    concurrencyAuto: !!userDto.app_settings?.vendibility?.concurrency?.auto,
    isComplete: !!userDto.app_settings?.vendibility?.concurrency,
    email: userDto.contact_details?.emails[0],
});

export const userToUserDto = (user: User): UpdateUserReqDto => ({
    languages: user.languages,
    occupation: user.occupation,
    // fullName: `${user.name.first} ${user.name.last}`,
    // email: user.contact_details?.emails[0],
    // firstName: user.name.first,
    // lastName: user.name.last,
    // isComplete: !!user.app_settings?.vendibility?.workload,
});

export const userWeekdaysToWorkweek = (
    user: Partial<User>
): WeekData | undefined =>
    user.weekdayHours
        ? {
              days: Object.keys(user.weekdayHours).map(key => ({
                  day_of_week: dayOfWeek[key] as DayOfWeek,
                  available_hours:
                      user.weekdayHours?.[key as Weekdays]?.map(
                          ({ start, end }) => ({
                              start: {
                                  hours: Math.floor(start / 60),
                                  minutes: start % 60,
                              },
                              end: {
                                  hours: Math.floor(end / 60),
                                  minutes: end % 60,
                              },
                          })
                      ) || [],
              })),
          }
        : undefined;

export const userDetailsToUserDetailsDto = (
    user: Partial<User>
): UpdateUserReqDto => ({
    contact_details: {
        emails: user.emails?.map(({ value }) => value),
        phone_numbers: user.numbers
            ?.map(({ value }) => value)
            .map(number => ({
                calling_code: parseInt(number.split(' ')[0], 10),
                number: parseInt(number.split(' ')[1], 10),
            })),
    },
    birth_details: user.birthDate
        ? {
              nationality:
                  (user.nationality?.toLowerCase() as Nationality) || undefined,
              date: Math.round(user.birthDate.getTime() / 1000),
              place: user.birthPlace
                  ? {
                        name: user.birthPlace,
                    }
                  : undefined,
          }
        : undefined,
    passport: user.passportN
        ? {
              number: user.passportN,
              citizenship:
                  (user.citizenship?.toLowerCase() as Country) || undefined,
              authority: user.issuedBy,
              issued_at: user.issuedAt
                  ? Math.round(user.issuedAt.getTime() / 1000)
                  : undefined,
              expires_at: user.expiresAt
                  ? Math.round(user.expiresAt.getTime() / 1000)
                  : undefined,
          }
        : undefined,
});
