import { useController, useFormContext } from 'react-hook-form';
import React, { useMemo } from 'react';
import { Box, Chip, FormHelperText, Stack } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export interface WeekdayPickerProps {
    selectAll?: boolean;
    multiple?: boolean;
    name: string;
    showErrors?: boolean;
    sx?: Record<string, any>;
    chipSx?: Record<string, any>;
}

export enum Weekdays {
    MONDAY = 'mon',
    TUESDAY = 'tue',
    WEDNESDAY = 'wed',
    THURSDAY = 'thu',
    FRIDAY = 'fri',
    SATURDAY = 'sat',
    SUNDAY = 'sun',
}

const WeekdayPicker: React.FC<WeekdayPickerProps> = ({
    selectAll = true,
    multiple = true,
    name,
    showErrors,
    sx = {},
    chipSx = {},
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation('common');
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: [],
    });

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);
    const options = useMemo(() => {
        return Object.values(Weekdays).map(v => ({
            value: v,
            label: t(v).substring(0, 3),
        }));
    }, []);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ py: '3px', px: 1, ...sx }}
            >
                {selectAll && (
                    <>
                        <Chip
                            label="Everyday"
                            clickable
                            sx={{
                                '&&': chipSx,
                                m: '5px 0',
                                height: 'auto',
                                border: 0,
                                p: '8px 5px',
                                borderRadius: '12px',
                                lineHeight: '12px',
                                '& > .MuiChip-label': {
                                    p: 0,
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                },
                            }}
                            color={
                                value?.length === options.length
                                    ? 'primary'
                                    : 'light'
                            }
                            variant="filled"
                            onClick={() =>
                                value?.length === options.length
                                    ? onChange([])
                                    : onChange(options.map(({ value: v }) => v))
                            }
                        />
                        <Box
                            component="span"
                            sx={{
                                borderLeft: '1px solid #DFE1ED',
                                m: '12px 1px',
                            }}
                        />
                    </>
                )}
                {options.map(option => (
                    <Chip
                        key={option.value}
                        label={option.label}
                        size="small"
                        clickable
                        sx={{
                            '&&': chipSx,
                            m: '5px 0',
                            height: 'auto',
                            border: 0,
                            p: '8px 5px',
                            borderRadius: '12px',
                            lineHeight: '12px',
                            '& > .MuiChip-label': {
                                p: 0,
                                fontSize: '12px',
                                lineHeight: '12px',
                            },
                        }}
                        color={
                            value?.includes(option.value) ? 'primary' : 'light'
                        }
                        variant="filled"
                        onClick={() =>
                            multiple
                                ? onChange(_.xor(value, [option.value]))
                                : onChange(option.value)
                        }
                    />
                ))}
            </Stack>
            {error && <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default WeekdayPicker;
