import React from 'react';
import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    Theme,
    Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { SystemStyleObject } from '@mui/system';

export interface ButtonProps extends MuiButtonProps {
    requiredFields?: string[];
    errorMessage?: string;
}

export const Button: React.FC<ButtonProps> = props => {
    const {
        requiredFields,
        errorMessage,
        children,
        disabled,
        endIcon,
        sx = {},
        ...rest
    } = props;

    return (
        <MuiButton
            disabled={!!requiredFields?.length || !!errorMessage || !!disabled}
            sx={{
                '&': sx as SystemStyleObject<Theme>,
                '&&&': { pointerEvents: 'initial' },
            }}
            endIcon={
                !!requiredFields?.length || !!errorMessage ? (
                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    borderRadius: '8px',
                                },
                            },
                        }}
                        title={
                            requiredFields ? (
                                <>
                                    The following info is required:
                                    {requiredFields.map(v => (
                                        <li key={v}>{v}</li>
                                    ))}
                                </>
                            ) : (
                                errorMessage
                            )
                        }
                    >
                        <InfoIcon sx={{ color: '#55B5CF' }} />
                    </Tooltip>
                ) : (
                    endIcon
                )
            }
            {...rest}
        >
            {children}
        </MuiButton>
    );
};
