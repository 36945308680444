import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModalLoadingOverlay from '../modal-loading-overlay/modal-loading-overlay';

export interface ConfirmationDialogProps {
    handleCancel: () => void;
    handleConfirm: () => void;
    isLoading?: boolean;
    open: boolean;
    title?: string;
    content?: string;
    confirmText?: string;
    disableConfirm?: boolean;
    children?: React.ReactNode;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    handleCancel,
    handleConfirm,
    isLoading,
    open,
    title,
    content,
    confirmText,
    disableConfirm,
    children,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            PaperProps={{ sx: { py: 1, bgcolor: '#FFF' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                {title}
            </DialogTitle>
            <DialogContent sx={{ position: 'relative' }}>
                <DialogContentText
                    sx={{ color: '#67728B', fontSize: '14px' }}
                    id="alert-dialog-description"
                >
                    {content}
                </DialogContentText>
                {children}
                {isLoading && <ModalLoadingOverlay />}
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    disabled={isLoading}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleConfirm}
                    disabled={!!disableConfirm || isLoading}
                    autoFocus
                >
                    {confirmText || 'Ok'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
