import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { CardList, CreatableTags, TextField } from '@travelity/form';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import { emailRefEx } from '@travelity/utils';
import { Country, Nationality } from '@travelity/api/src/requests';
import { Tag } from '@travelity/ui';
import { array, mixed, object, string } from 'yup';
import { SingleLineTextField } from '../booking-participants/components/single-line-text-field';
import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';

export interface ProductDetailsFormProps {
    preview?: boolean;
}

const schema = object().shape({
    name: string().required(),
    emails: array(),
    numbers: array(),
    birthPlace: string(),
    passportN: string(),
    issuedBy: string(),
    birthDate: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    issuedAt: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    expiresAt: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    nationality: mixed<Nationality>()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr.toLowerCase()))
        .oneOf(Object.values(Nationality)),
    citizenship: mixed<string>()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr.toLowerCase()))
        .oneOf(Object.values(Country)),
});

const OrderParticipantsForm: React.FC<ProductDetailsFormProps> = ({
    preview,
}) => {
    return (
        <Stack spacing={2}>
            <CardList
                disabled={preview}
                disableAdding
                disableReorder
                disableRemove
                formParams={{ schema }}
                renderHeader={({ editing, item }) =>
                    editing ? (
                        <Stack direction="row" sx={{ width: 1 }}>
                            <TextField
                                sx={{ mx: '10px' }}
                                autoFocus
                                InputProps={{
                                    sx: { fontSize: '14px', pl: 1.5 },
                                }}
                                placeholder="Full Name"
                                name="name"
                                variant="standard"
                                showErrors
                                helperText=""
                                fullWidth
                            />
                            <Tag label="Type" values={['Traveler']} />
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            sx={{ width: 1, pl: '10px' }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box component="span">{item.name}</Box>
                            <Tag label="Type" values={['Traveler']} />
                        </Stack>
                    )
                }
                renderContent={({ item, editing }) => (
                    <Stack gap={0.5} p={2}>
                        <Divider>Contact Details</Divider>
                        {editing ? (
                            <>
                                <Typography
                                    sx={{
                                        color: '#2B395B',
                                        fontSize: '12px',
                                    }}
                                >
                                    Emails
                                </Typography>
                                <CreatableTags
                                    name="emails"
                                    regEx={emailRefEx}
                                    inputProps={{
                                        size: 'small',
                                        sx: {
                                            '&& .MuiInputBase-root': {
                                                paddingRight: '10px',
                                            },
                                        },
                                        placeholder:
                                            'Type email and press enter',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        color: '#2B395B',
                                        fontSize: '12px',
                                    }}
                                >
                                    Phone Numbers
                                </Typography>
                                <CreatableTags
                                    name="numbers"
                                    regEx={/^\+?\d+ \d+$/}
                                    inputProps={{
                                        size: 'small',
                                        sx: {
                                            '&& .MuiInputBase-root': {
                                                paddingRight: '10px',
                                            },
                                        },
                                        placeholder:
                                            'Type number and press enter',
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <SingleLineKeyValue
                                    label="Emails"
                                    value={item.emails
                                        ?.map(
                                            ({ value }: { value: string }) =>
                                                value
                                        )
                                        .join(', ')}
                                />
                                <SingleLineKeyValue
                                    label="Phone Numbers"
                                    value={item.numbers
                                        ?.map(
                                            ({ value }: { value: string }) =>
                                                value
                                        )
                                        .join(', ')}
                                />
                            </>
                        )}
                        <Divider sx={{ mt: 1.5 }}>Personal Information</Divider>
                        <SingleLineTextField
                            label="Date of Birth"
                            name="birthDate"
                            width="138px"
                            readOnly={!editing}
                        />
                        <SingleLineTextField
                            label="Place of Birth"
                            name="birthPlace"
                            width="138px"
                            readOnly={!editing}
                        />
                        <SingleLineTextField
                            label="Nationality"
                            name="nationality"
                            width="138px"
                            readOnly={!editing}
                        />
                        <Divider sx={{ mt: 1.5 }}>
                            Identification Document
                        </Divider>
                        <Stack direction="row" gap={1}>
                            <Box sx={{ width: 0.5 }}>
                                <SingleLineTextField
                                    label="Passport №"
                                    name="passportN"
                                    readOnly={!editing}
                                />
                                <SingleLineTextField
                                    label="Issued at"
                                    name="issuedAt"
                                    readOnly={!editing}
                                />
                                <SingleLineTextField
                                    label="Citizenship"
                                    name="citizenship"
                                    readOnly={!editing}
                                />
                            </Box>
                            <Box sx={{ width: 0.5 }}>
                                <SingleLineTextField
                                    label="Issued by"
                                    name="issuedBy"
                                    readOnly={!editing}
                                />
                                <SingleLineTextField
                                    label="Expires at"
                                    name="expiresAt"
                                    readOnly={!editing}
                                />
                            </Box>
                        </Stack>
                        {(!!item?.attachments?.length || editing) && (
                            <>
                                <Divider sx={{ mt: 1.5 }}>Attachments</Divider>
                                {editing && (
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        fullWidth
                                        disabled
                                        onClick={() => {}}
                                    >
                                        <UploadIcon /> Upload
                                    </Button>
                                )}
                            </>
                        )}
                    </Stack>
                )}
                addButtonText=""
                name="customers"
            />
        </Stack>
    );
};

export default React.memo(OrderParticipantsForm);
