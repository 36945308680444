import React, { forwardRef, ReactElement } from 'react';
import { Box } from '@mui/material';

export interface IconTextButtonProps {
    onClick: (e: React.MouseEvent) => void;
    text: string;
    icon: ReactElement;
    className?: string;
}

const IconTextButton = forwardRef<HTMLAnchorElement, IconTextButtonProps>(
    ({ onClick, text, icon, ...rest }, ref) => {
        const handleClick = (e: React.MouseEvent) => {
            e.preventDefault();
            onClick(e);
        };

        return (
            <Box
                component="a"
                href="#"
                ref={ref}
                {...rest}
                sx={{
                    color: '#36869C',
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px',
                    ':hover': {
                        color: '#55B5CF',
                        textDecoration: 'none',
                        '& div': {
                            background: '#55B5CF',
                            border: '1px solid #55B5CF',
                            color: '#FFFFFF',
                        },
                    },
                }}
                onClick={handleClick}
            >
                <Box
                    component="div"
                    sx={{
                        width: '44px',
                        height: '44px',
                        border: '1px solid #36869C',
                        borderRadius: '12px',
                        p: '10px',
                        mb: 2,
                    }}
                >
                    {icon}
                </Box>
                {text}
            </Box>
        );
    }
);

export default IconTextButton;
