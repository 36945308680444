import React, { useMemo } from 'react';
import { useCreateCustomer, useGetCustomer } from '../../queries';
import { getCustomerDtoToCustomer } from './customer.converters';

type UseGetCustomerParamTypes = Parameters<typeof useGetCustomer>;
type Filter = UseGetCustomerParamTypes[0];
export const useDebouncedCustomers = (
    search: string,
    debounce: number = 1000
) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        Filter | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: Filter = {};
            if (search.match(/^\+?[0-9 ]+$/)) filter.phoneNumber = search;
            else if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.email = search;
            else if (search.match(/ /)) {
                filter.name = search;
            }
            setDebouncedParams(Object.keys(filter).length ? filter : undefined);
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [search, debounce]);

    const { data, ...other } = useGetCustomer(
        debouncedParams || {},
        [debouncedParams],
        {
            keepPreviousData: true,
            staleTime: debounce,
            enabled: !!debouncedParams,
        }
    );

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? data?.items.map(getCustomerDtoToCustomer)
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

export { useCreateCustomer };
