import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import IconChevronRight from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, useMediaQuery } from '@mui/material';

import { useUser } from '@travelity/api/src/services/user/user.hooks';
import Breadcrumbs from '../../components/breadcrumbs';
import Header from './header';
import Sidebar from './sidebar';

import menuItems from './menu-items';
import LogoHeader from './logo-header';
import { RegisterSteps } from '../../views/register-steps';

const drawerWidth = 224;

function MainLayout() {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // Handle left drawer
    const [leftDrawerOpened, setLeftDrawerOpened] = useState(false);
    const handleLeftDrawerToggle = () => {
        setLeftDrawerOpened(prevState => !prevState);
    };

    const { data: user, isLoading: isUserLoading } = useUser();
    const profileIsComplete = user?.isComplete;

    // useEffect(() => {
    //     if (!isUserLoading && !user) {
    //         navigate('/register');
    //     }
    // }, [isUserLoading, user]);

    // if (isUserLoading) return null;

    return (
        <Box sx={{ display: 'flex' }}>
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderBottom: '1px solid #E7E8F2',
                    bgcolor: '#FFFFFF',
                }}
            >
                {!isUserLoading && (
                    <Toolbar sx={{ '&&&': { pl: 1 } }} variant="dense">
                        {profileIsComplete ? (
                            <Header
                                handleLeftDrawerToggle={handleLeftDrawerToggle}
                            />
                        ) : (
                            <LogoHeader />
                        )}
                    </Toolbar>
                )}
            </AppBar>

            {/* drawer */}
            {profileIsComplete ? (
                <>
                    <Sidebar
                        drawerOpen={
                            !matchDownMd ? leftDrawerOpened : !leftDrawerOpened
                        }
                        drawerToggle={handleLeftDrawerToggle}
                    />

                    {/* main content */}
                    <Box
                        component="main"
                        sx={{
                            height: 'calc(100vh - 60px)',
                            bgcolor: '#F4F6FA',
                            mt: '60px',
                            overflow: 'hidden',
                            ...theme.typography.body1,
                            ...(!leftDrawerOpened
                                ? {
                                      borderBottomLeftRadius: 0,
                                      borderBottomRightRadius: 0,
                                      width: 'calc(100% + 16px)',
                                      transition: theme.transitions.create(
                                          'margin',
                                          {
                                              easing: theme.transitions.easing
                                                  .sharp,
                                              duration:
                                                  theme.transitions.duration
                                                      .leavingScreen,
                                          }
                                      ),
                                      // [theme.breakpoints.up('md')]: {
                                      //     marginLeft: `${-(drawerWidth - 20)}px`,
                                      //     width: `calc(100% - ${drawerWidth}px)`,
                                      // },
                                      // [theme.breakpoints.down('md')]: {
                                      //     marginLeft: '20px',
                                      //     width: `calc(100% - ${drawerWidth}px)`,
                                      //     padding: '16px',
                                      // },
                                      // [theme.breakpoints.down('sm')]: {
                                      //     marginLeft: '10px',
                                      //     width: `calc(100% - ${drawerWidth}px)`,
                                      //     padding: '16px',
                                      //     marginRight: '10px',
                                      // },
                                      marginLeft: `${-drawerWidth - 16}px`,
                                  }
                                : {
                                      transition: theme.transitions.create(
                                          'margin',
                                          {
                                              easing: theme.transitions.easing
                                                  .easeOut,
                                              duration:
                                                  theme.transitions.duration
                                                      .enteringScreen,
                                          }
                                      ),
                                      marginLeft: `0`,
                                      borderBottomLeftRadius: 0,
                                      borderBottomRightRadius: 0,
                                      // width: `calc(100% - ${drawerWidth}px)`,
                                      // width: '100%',

                                      width: 'calc(100% + 16px)',

                                      [theme.breakpoints.down('md')]: {
                                          marginLeft: '20px',
                                      },
                                      [theme.breakpoints.down('sm')]: {
                                          marginLeft: '10px',
                                      },
                                  }),
                        }}
                    >
                        {/* breadcrumb */}
                        <Breadcrumbs
                            separator={<IconChevronRight />}
                            navigation={menuItems}
                            icon
                            title
                            rightAlign
                        />
                        <Outlet />
                    </Box>
                </>
            ) : (
                !isUserLoading && (
                    <Box
                        component="main"
                        sx={{
                            height: 'calc(100vh - 60px)',
                            bgcolor: '#F4F6FA',
                            mt: '60px',
                            width: 1,
                            overflow: 'hidden',
                            ...theme.typography.body1,
                        }}
                    >
                        <RegisterSteps user={user} />
                    </Box>
                )
            )}
        </Box>
    );
}

export default MainLayout;
