import React, { ReactNode } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface SideFormProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    children: ReactNode;
}

export const SideForm: React.FC<SideFormProps> = props => {
    const { isOpen, onClose, title, children } = props;

    return isOpen ? (
        <Box
            sx={{
                bgcolor: '#FFF',
                my: 2,
                ml: 2,
                mr: 2,
                borderRadius: '0px 8px 8px 8px',
                width: '420px',
                position: 'relative',
                height: 'calc(100% - 32px)',
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                {title && (
                    <Box
                        sx={{
                            display: 'inline-flex',
                            padding: '8px 18px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            borderRadius: '0px 0px 12px 0px',
                            background: '#6B748C',
                            color: '#FFF',
                            fontFamily: 'Lexend',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '100%',
                            textTransform: 'capitalize',
                        }}
                    >
                        {title}
                    </Box>
                )}
                <IconButton
                    onClick={onClose}
                    size="small"
                    sx={{ mt: 0.5, mr: 1 }}
                >
                    <CloseIcon fontSize="small" sx={{ color: '#B2B9CD' }} />
                </IconButton>
            </Stack>
            <Box
                sx={{
                    height: 'calc(100% - 34px)',
                }}
            >
                {children}
            </Box>
        </Box>
    ) : null;
};
