import { GetCustomerResItemDto } from '../../requests';
import { Customer } from './customer.types';

export const getCustomerDtoToCustomer = (
    customer: Omit<GetCustomerResItemDto, 'created'>
): Customer => ({
    // @ts-ignore
    id: customer.id,
    firstName: customer.name.first,
    lastName: customer.name.last,
    email: customer.contact_details?.emails[0] || '',
});

export const customerToCustomerDto = (
    customer: Customer
): Omit<GetCustomerResItemDto, 'created'> => ({
    id: customer.id,
    contact_details: {
        emails: [customer.email],
    },
    name: {
        first: customer.firstName,
        last: customer.lastName,
    },
});
