import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Box,
    ClickAwayListener,
    Popper,
    Stack,
    Typography,
} from '@mui/material';
import { addMonths, format, subMonths } from 'date-fns';
import {
    AvailableProduct,
    useAvailability,
    useProducts,
    AvailabilityFilterValues,
} from '@travelity/api';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation, useNavigate } from 'react-router-dom';
import { groupBy, isEmpty, pickBy } from 'lodash';
import { DayAvailability } from '../../components/day-availability';
import MonthCalendar from '../../components/month-calendar/month-calendar';
import { Filters } from '../../components/filters';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import { useDynamicState, useExpand, useSelectOptions } from '../../hooks';
import { SideForm } from '../../components/side-form';
import BookingSideForm from './components/booking-side-form';

const monthFormat = 'MM-y';

const availabilityFilters: FilterOption[] = [
    {
        name: 'dates',
        label: 'Dates',
        type: FilterTypes.DATES,
        selectText: 'Please, select the value for date filters',
    },
    {
        name: 'pax',
        label: 'PAX',
        type: FilterTypes.PAX,
        // selectText: 'Please, select the value for product type',
    },
    // {
    //     name: 'productType',
    //     label: 'Product Type',
    //     type: FilterTypes.DROPDOWN,
    //     selectText: 'Please, select the value for product type',
    //     options: [],
    // },
    {
        name: 'products',
        label: 'Products',
        type: FilterTypes.DROPDOWN,
        selectText: 'Please, select products',
        options: [],
    },
    {
        name: 'pricing',
        label: 'Pricing',
        type: FilterTypes.PRICE,
        selectText: 'Please, select the values for price filter',
    },
    // {
    //     name: 'destination',
    //     label: 'Destination',
    //     type: FilterTypes.SEARCH,
    //     // selectText: 'Please, select the value for product type',
    // },
    // {
    //     name: 'language',
    //     label: 'Language',
    //     type: FilterTypes.SEARCH,
    //     selectText: 'Please, select the values for language',
    // },
    // {
    //     name: 'tags',
    //     label: 'Tags',
    //     type: FilterTypes.SEARCH,
    //     selectText: 'Please, select the values for tags',
    // },
];

function HomePage() {
    const { data: products } = useProducts();
    const productFilterOptions = useSelectOptions(
        products,
        'productInfo.name',
        'id',
        true
    );

    const location = useLocation();
    const [filters, setFilters] = useDynamicState<AvailabilityFilterValues>(
        useMemo(() => {
            if (location.state) {
                const stateFilters = pickBy(
                    location.state as AvailabilityFilterValues,
                    v => v
                );
                if (!isEmpty(stateFilters)) return stateFilters;
            }
            return {};
        }, [location.state])
    );

    const navigate = useNavigate();
    const updateFilters = useCallback(
        (newFilters: AvailabilityFilterValues) => {
            navigate('/availability', {
                replace: true,
                state: newFilters,
            });
        },
        [setFilters]
    );

    const filledFilters = useMemo(() => {
        return availabilityFilters.map(filter =>
            filter.name === 'products'
                ? {
                      ...filter,
                      options: productFilterOptions,
                  }
                : filter
        );
    }, [productFilterOptions]);

    const {
        data: availability,
        isLoading,
        refetch,
    } = useAvailability(
        useMemo(
            () => ({
                requestBody: filters,
            }),
            [filters]
        )
    );

    const groupByMonths = useMemo(() => {
        if (availability) {
            return groupBy(availability, ({ date }) =>
                format(date, monthFormat)
            );
        }
        return {};
    }, [availability]);

    const [activeMonth, setActiveMonth] = useState(new Date());
    const prevMonth = useCallback(() => {
        setActiveMonth(subMonths(activeMonth, 1));
    }, [activeMonth]);
    const nextMonth = useCallback(() => {
        setActiveMonth(addMonths(activeMonth, 1));
    }, [activeMonth]);
    const prevMonthCount =
        groupByMonths[format(subMonths(activeMonth, 1), monthFormat)]?.length ||
        0;
    const nextMonthCount =
        groupByMonths[format(addMonths(activeMonth, 1), monthFormat)]?.length ||
        0;

    useEffect(() => {
        const months = Object.values(groupByMonths).map(([day]) => {
            return day.date;
        });
        months.sort();
        if (months[0]) {
            setActiveMonth(new Date(months[0]));
        }
    }, [groupByMonths]);

    const activeMonthGroupedByDay: Record<number, AvailableProduct[]> =
        useMemo(() => {
            const activeMonthAvailability =
                groupByMonths[format(activeMonth, monthFormat)];
            if (activeMonthAvailability) {
                return activeMonthAvailability.reduce(
                    (acc, { date, products: p }) => ({
                        ...acc,
                        [format(date, 'd')]: p,
                    }),
                    {}
                );
            }
            return {};
        }, [groupByMonths, activeMonth]);

    const [expanded, toggleExpand] = useExpand<{
        day: number;
        date: Date;
    }>();
    const refs = useRef<Record<number, HTMLDivElement | null>>({});
    const containerEl = useRef<HTMLDivElement | null>(null);

    const [selectedEvent, setSelectedEvent] = useState<
        | {
              product: AvailableProduct;
              filters: AvailabilityFilterValues;
              time: number;
          }
        | undefined
    >();

    const closeEvent = useCallback(() => {
        setSelectedEvent(undefined);
    }, []);

    const openEvent = useCallback(
        (product: AvailableProduct, time: number) => {
            setSelectedEvent({
                filters,
                product,
                time,
            });
        },
        [filters]
    );

    const changeTime = useCallback((time: number) => {
        setSelectedEvent(prevState =>
            prevState
                ? {
                      ...prevState,
                      time,
                  }
                : undefined
        );
    }, []);

    const calendarContentEl = useMemo(
        () => containerEl.current?.querySelector('.month-container'),
        [containerEl.current]
    );

    const setDayRef = useCallback((ref: HTMLDivElement) => {
        const day = ref?.getAttribute('data-day');
        if (day) {
            refs.current[parseInt(day, 10)] = ref;
        }
    }, []);

    useEffect(closeEvent, [filters, closeEvent]);

    const onBookingCreate = useCallback(() => {
        closeEvent();
        refetch();
    }, []);

    return (
        <Stack
            direction="row"
            sx={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }}
        >
            <Box
                sx={{
                    height: '100%',
                    overflow: 'hidden',
                    flexGrow: 2,
                    pr: 2,
                    pl: 4,
                }}
            >
                <Filters
                    values={filters}
                    setValues={updateFilters}
                    options={filledFilters}
                />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        py: 1,
                        height: '56px',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#B2B9CD',
                            fontSize: '16px',
                            fontWeight: '700',
                        }}
                    >
                        {format(activeMonth, 'MMMM yyyy')}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {!!prevMonthCount && (
                            <Button
                                startIcon={<NavigateBeforeIcon />}
                                sx={{
                                    color: '#565D6D',
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    borderRadius: '16px',
                                    background: '#FFF',
                                    boxShadow:
                                        '0px 0px 4px 0px rgba(178, 185, 205, 0.40)',
                                    p: '5px 12px',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(54, 134, 156, 0.04)',
                                    },
                                }}
                                onClick={prevMonth}
                            >
                                Previous {prevMonthCount}
                                {prevMonthCount > 1 ? ' Results' : ' Result'}
                            </Button>
                        )}
                        {!!nextMonthCount && (
                            <Button
                                endIcon={<NavigateNextIcon />}
                                sx={{
                                    color: '#565D6D',
                                    fontSize: '14px',
                                    lineHeight: '14px',
                                    borderRadius: '16px',
                                    background: '#FFF',
                                    boxShadow:
                                        '0px 0px 4px 0px rgba(178, 185, 205, 0.40)',
                                    p: '5px 12px',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(54, 134, 156, 0.04)',
                                    },
                                }}
                                onClick={nextMonth}
                            >
                                Next {nextMonthCount}
                                {nextMonthCount > 1 ? ' Results' : ' Result'}
                            </Button>
                        )}
                    </Stack>
                </Stack>
                <MonthCalendar
                    ref={containerEl}
                    month={activeMonth}
                    render={({ day, active, date, isToday }) => (
                        <DayAvailability
                            day={day}
                            date={date}
                            isLoading={isLoading}
                            products={
                                active ? activeMonthGroupedByDay[day] || [] : []
                            }
                            active={active}
                            isToday={isToday}
                            onEventClick={openEvent}
                            ref={setDayRef}
                            toggleExpand={toggleExpand}
                        />
                    )}
                />
                {expanded && (
                    <ClickAwayListener onClickAway={() => toggleExpand(null)}>
                        <Popper
                            open
                            anchorEl={refs.current[expanded.day]}
                            placement="bottom"
                            modifiers={[
                                {
                                    name: 'preventOverflow',
                                    enabled: true,
                                    options: {
                                        altAxis: true,
                                        altBoundary: true,
                                        tether: false,
                                        boundary: calendarContentEl,
                                    },
                                },
                            ]}
                        >
                            <DayAvailability
                                products={activeMonthGroupedByDay[expanded.day]}
                                expanded
                                day={expanded.day}
                                date={expanded.date}
                                onEventClick={openEvent}
                                width={refs.current[expanded.day]?.clientWidth}
                                maxHeight={calendarContentEl?.clientHeight}
                            />
                        </Popper>
                    </ClickAwayListener>
                )}
            </Box>

            <SideForm
                title="Booking Creation"
                isOpen={!!selectedEvent}
                onClose={closeEvent}
            >
                {!!selectedEvent && (
                    <BookingSideForm
                        availableProduct={selectedEvent?.product}
                        time={selectedEvent?.time}
                        filters={selectedEvent?.filters}
                        changeTime={changeTime}
                        discard={closeEvent}
                        onSuccess={onBookingCreate}
                    />
                )}
            </SideForm>
        </Stack>
    );
}

export default HomePage;
