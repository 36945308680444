import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider, Paper, Stack } from '@mui/material';
import { TextField, CardList, Select, Switch, TagsList } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { number, object } from 'yup';
import { Tag } from '@travelity/ui';
import { currencies, PricingType } from '@travelity/api';
import { FinancingCardContent } from './components/financing-card';
import { ConfirmationDialog } from '../confirmation-dialog';

export interface ProductFinancingFormProps {
    capacity: Record<string, any>;
    preview?: boolean;
}

const paymentMethodOptions = [
    { value: 'cash', label: 'Cash' },
    { value: 'card', label: 'Card' },
    { value: 'transfer', label: 'Transfer' },
    { value: 'promo', label: 'Promo' },
    { value: 'gift_card', label: 'Gift Card' },
];

const schema = object({
    // name: string().required('Name is a required field').uniqName(),
    price: object().when(['type'], ([type], sch) => {
        if (type === PricingType.PER_AGEBAND) {
            return sch.shape({
                counts: object().when('.', ([counts], s) => {
                    return Object.values(counts).find(v => v)
                        ? s
                        : s.shape({
                              infants: number()
                                  .required(
                                      'At least one ageband price required'
                                  )
                                  .min(
                                      1,
                                      'At least one ageband price required'
                                  ),
                          });
                }),
            });
        }
        if (type === PricingType.PER_TICKET) {
            return sch.shape({
                perPerson: number()
                    .required('Price per person is required')
                    .min(1, 'Price per person is required'),
            });
        }
        return sch.shape({
            perProduct: number()
                .required('Price per product is required')
                .min(1, 'Price per product is required'),
        });
    }),
});

const ProductFinancingForm: React.FC<ProductFinancingFormProps> = props => {
    const { capacity, preview } = props;
    const {
        watch,
        setValue,
        formState: { dirtyFields },
    } = useFormContext();
    const currency = watch('currency');

    const [showConfirmation, setShowConfirmation] = useState(false);

    const currencyOptions = useMemo(
        () =>
            currencies.map(c => ({
                label: c.name,
                value: c.abbr,
            })),
        []
    );

    const prepaymentTypeOptions = useMemo(
        () => [
            { value: 'relative', label: '%' },
            { value: 'absolute', label: currency || 'ABS' },
        ],
        [currency]
    );

    const adaptivePricing = watch('adaptivePricing');
    const defaultValue = adaptivePricing
        ? {
              capacity: capacity.capacities[watch('items')?.length || 0]?.name,
          }
        : {};

    const skipChange = useRef(true);
    useEffect(() => {
        if (
            !skipChange.current &&
            watch('items').length &&
            dirtyFields.adaptivePricing
        ) {
            setShowConfirmation(true);
        }
        skipChange.current = false;
    }, [adaptivePricing]);

    const resetAdaptivePricing = () => {
        setValue('adaptivePricing', !adaptivePricing);
        skipChange.current = true;
        setShowConfirmation(false);
    };

    return (
        <>
            <Stack spacing={2} sx={{ width: '453px' }}>
                <Select
                    name="currency"
                    label="Currency"
                    placeholder="Select Currency"
                    options={currencyOptions}
                    disabled={preview}
                />

                <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                    Pricing
                </Divider>

                <Paper
                    sx={{
                        py: 1,
                        px: 2,
                        maxWidth: '388px',
                        ...(preview
                            ? {
                                  opacity: 0.6,
                                  pointerEvents: 'none',
                              }
                            : {}),
                    }}
                >
                    <Switch
                        name="adaptivePricing"
                        label="Adaptive Pricing"
                        LabelProps={{ sx: { color: '#2B395B' } }}
                    />
                </Paper>

                <CardList
                    disabled={preview}
                    renderHeader={({ editing, item }) => (
                        <Stack
                            direction="row"
                            sx={{ width: 1 }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {editing ? (
                                <TextField
                                    sx={{ mx: '10px' }}
                                    autoFocus
                                    InputProps={{
                                        sx: { fontSize: '14px', pl: 1.5 },
                                    }}
                                    placeholder="Type name"
                                    name="name"
                                    variant="standard"
                                    fullWidth
                                />
                            ) : (
                                <Box component="span">{item.name}</Box>
                            )}
                            {item.capacity && (
                                <Tag
                                    label="Capacity"
                                    values={[item.capacity]}
                                />
                            )}
                        </Stack>
                    )}
                    renderContent={({ item, editing }) => (
                        <FinancingCardContent
                            item={item}
                            editing={editing}
                            currency={currency}
                        />
                    )}
                    defaultState={defaultValue}
                    addButtonText={
                        watch('adaptivePricing') && watch('items')?.length
                            ? `Add Pricing (${
                                  capacity.capacities.length -
                                  watch('items').length
                              } more)`
                            : 'Add Pricing'
                    }
                    disableAdding={
                        (!watch('adaptivePricing') && watch('items')?.length) ||
                        (watch('adaptivePricing') &&
                            watch('items')?.length ===
                                capacity.capacities.length)
                    }
                    formParams={{
                        schema,
                    }}
                    name="items"
                />

                <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                    Payment Requirements
                </Divider>

                <Paper sx={{ py: 1, px: 2, maxWidth: '388px' }}>
                    <Box
                        sx={{
                            color: '#2B395B',
                            fontSize: '14px',
                            width: 1,
                            justifyContent: 'space-between',
                            margin: 0,
                        }}
                    >
                        Payment Methods
                    </Box>

                    <TagsList
                        multiple
                        name="paymentMethods"
                        selectAll={false}
                        disabled={preview}
                        options={paymentMethodOptions}
                    />
                </Paper>

                <Paper
                    sx={{
                        py: 1,
                        px: 2,
                        maxWidth: '388px',
                        ...(preview
                            ? {
                                  opacity: 0.6,
                                  pointerEvents: 'none',
                              }
                            : {}),
                    }}
                >
                    <Switch
                        name="prepayment"
                        label="Prepayment required?"
                        LabelProps={{ sx: { color: '#2B395B' } }}
                    />

                    {watch('prepayment') && (
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{
                                bgcolor: '#FFFFFF',
                                borderRadius: '8px',
                                p: 1,
                            }}
                        >
                            <TextField
                                name="prepaymentAmount"
                                size="small"
                                sx={{ flexGrow: 2 }}
                            />
                            <Select
                                size="small"
                                width="140px"
                                name="prepaymentType"
                                label=""
                                options={prepaymentTypeOptions}
                            />
                        </Stack>
                    )}
                </Paper>
            </Stack>
            <ConfirmationDialog
                title="You need to know"
                content="By switching financial settings to adaptive/standard
mode, your financial options will be removed.
Do you want to proceed with this action?"
                confirmText="Confirm"
                open={showConfirmation}
                handleCancel={resetAdaptivePricing}
                handleConfirm={() => {
                    setValue('items', []);
                    setShowConfirmation(false);
                }}
            />
        </>
    );
};

export default React.memo(ProductFinancingForm);
