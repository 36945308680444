import React from 'react';
import { Divider, Skeleton, Stack } from '@mui/material';

export interface EventItemProps {}

const EventItemSkeleton: React.FC<EventItemProps> = () => {
    return (
        <Stack sx={{ mt: 1 }}>
            <Divider>
                <Skeleton width={60} />
            </Divider>
            <Stack sx={{ mt: 1 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        pl: 5,
                        height: '46px',
                        py: '10px',
                        borderRadius: '12px 0px 0px 0px',
                    }}
                >
                    <Stack direction="row" gap={1}>
                        <Skeleton width={120} sx={{ fontSize: '24px' }} />
                        <Skeleton width={80} sx={{ fontSize: '24px' }} />
                    </Stack>

                    <Stack direction="row" gap={1}>
                        <Skeleton width={100} sx={{ fontSize: '24px' }} />
                        <Skeleton width={100} sx={{ fontSize: '24px' }} />
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                    sx={{
                        height: '46px',
                        py: '10px',
                    }}
                >
                    <Skeleton variant="circular" width={24} height={24} />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            px: 2,
                            flexGrow: 2,
                            height: '46px',
                            py: 2,
                            bgcolor: '#F4F6FA',
                            borderRadius: '12px',
                        }}
                    >
                        <Stack direction="row" gap={1}>
                            <Skeleton width={80} sx={{ fontSize: '24px' }} />
                            <Skeleton width={120} sx={{ fontSize: '24px' }} />
                        </Stack>

                        <Stack direction="row" gap={1}>
                            <Skeleton width={80} sx={{ fontSize: '24px' }} />
                            <Skeleton width={100} sx={{ fontSize: '24px' }} />
                            <Skeleton width={60} sx={{ fontSize: '24px' }} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EventItemSkeleton;
