import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Button,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { omit } from 'lodash';
import Transitions from '../../../transitions/transitions';
import { FilterListItem } from '../filter-list-item';
import { FilterSettings } from '../filter-settings';
import { FilterOption, FilterValue } from '../../filters.types';

export interface FiltersProps {
    selectedFilters: Record<string, FilterValue>;
    updateSelectedFilters: (v: Record<string, FilterValue>) => void;
    options: FilterOption[];
}

export const FilterButton: React.FC<FiltersProps> = memo(
    (props: FiltersProps) => {
        const { selectedFilters, updateSelectedFilters, options } = props;

        const [values, setValues] = useState<Record<string, FilterValue>>({});

        // const nothingChanged = useMemo(() => {
        //     return isEqual(values, selectedFilters);
        // }, [values, selectedFilters]);

        // Popper state
        const [open, setOpen] = useState(false);
        const anchorRef = useRef<HTMLButtonElement | null>(null);

        const handleClose = (
            event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
        ) => {
            if (
                anchorRef.current &&
                anchorRef.current.contains(event.target as Node)
            ) {
                return;
            }
            setOpen(false);
        };

        const handleToggle = () => {
            if (!open) {
                setValues(selectedFilters);
            }
            setOpen(!open);
        };

        // Selected filter to configure
        const [activeFilterName, setActiveFilterName] = useState<
            string | undefined
        >();
        useEffect(() => {
            if (open) {
                setActiveFilterName(undefined);
            }
        }, [open]);
        const activeFilter = useMemo(
            () =>
                activeFilterName
                    ? {
                          value: values[activeFilterName],
                          option: options.find(
                              o => o.name === activeFilterName
                          ) as FilterOption,
                      }
                    : null,
            [activeFilterName, options, values]
        );

        const changeFilter = useCallback((name: string) => {
            setActiveFilterName(name);
        }, []);

        // Update a filter
        const updateFilter = useCallback(
            (value: any) => {
                setValues({
                    ...values,
                    [activeFilterName as string]: value,
                });
                setActiveFilterName(undefined);
            },
            [activeFilterName]
        );

        // Remove a filter
        const clearFilter = useCallback(
            (name: string) => {
                setValues(omit(values, [name]));
            },
            [values, setValues]
        );

        // Split filters to selected / not selected
        const unused = useMemo(() => {
            return options.filter(o => !values[o.name]);
        }, [options, values]);
        const selectedOptions = useMemo(() => {
            return options.filter(o => values[o.name]);
        }, [options, values]);

        return (
            <>
                <IconButton
                    color="secondary"
                    sx={{
                        bgcolor: '#FFF',
                        boxShadow: '0px 0px 4px 0px rgba(178, 185, 205, 0.40)',
                    }}
                    ref={anchorRef}
                    onClick={handleToggle}
                >
                    <TuneIcon sx={{ fontSize: '20px' }} />
                </IconButton>

                <Popper
                    placement="bottom-end"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    sx={{ zIndex: 1200 }}
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions in={open} {...TransitionProps}>
                            <Paper
                                sx={{
                                    borderRadius: '12px',
                                    boxShadow:
                                        '0px 0px 16px 0px rgba(178, 185, 205, 0.40)',
                                    background: '#FFF',
                                    p: 2,
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    {/* <div> */}
                                    {activeFilter ? (
                                        <FilterSettings
                                            filter={activeFilter.option}
                                            value={activeFilter.value}
                                            onCancel={() =>
                                                setActiveFilterName(undefined)
                                            }
                                            onUpdate={updateFilter}
                                        />
                                    ) : (
                                        <>
                                            <Typography
                                                sx={{
                                                    color: '#2B395B',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Filter Configuration
                                            </Typography>
                                            {!!selectedOptions.length && (
                                                <>
                                                    <Divider sx={{ mt: 1 }}>
                                                        Selected filters
                                                    </Divider>
                                                    <List>
                                                        {selectedOptions.map(
                                                            ({
                                                                label,
                                                                name,
                                                            }) => (
                                                                <FilterListItem
                                                                    label={
                                                                        label
                                                                    }
                                                                    selected
                                                                    onClick={() =>
                                                                        changeFilter(
                                                                            name
                                                                        )
                                                                    }
                                                                    onRemove={() =>
                                                                        clearFilter(
                                                                            name
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </List>
                                                </>
                                            )}
                                            {!!unused.length && (
                                                <>
                                                    <Divider sx={{ mt: 1 }}>
                                                        Filters to Select
                                                    </Divider>
                                                    <List
                                                        component="nav"
                                                        sx={{
                                                            width: '100%',
                                                            borderRadius:
                                                                '24px',
                                                            py: 0.5,
                                                            '& .MuiListItemButton-root':
                                                                {
                                                                    mt: 0.5,
                                                                },
                                                        }}
                                                    >
                                                        {unused.map(
                                                            ({
                                                                label,
                                                                name,
                                                            }) => (
                                                                <FilterListItem
                                                                    label={
                                                                        label
                                                                    }
                                                                    onClick={() =>
                                                                        changeFilter(
                                                                            name
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </List>
                                                </>
                                            )}
                                            <Stack
                                                direction="row"
                                                mt={1}
                                                gap={1}
                                            >
                                                {/* <Button */}
                                                {/*    variant="contained" */}
                                                {/*    color="secondary" */}
                                                {/*    sx={{ flexGrow: 1 }} */}
                                                {/*    // disabled={nothingChanged} */}
                                                {/*    onClick={() => */}
                                                {/*        updateSelectedFilters( */}
                                                {/*            values */}
                                                {/*        ) */}
                                                {/*    } */}
                                                {/* > */}
                                                {/*    Apply */}
                                                {/* </Button> */}
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    sx={{ flexGrow: 2 }}
                                                    // disabled={nothingChanged}
                                                    onClick={() => {
                                                        updateSelectedFilters(
                                                            values
                                                        );
                                                        setOpen(false);
                                                    }}
                                                >
                                                    Update Filters
                                                </Button>
                                            </Stack>
                                        </>
                                    )}
                                    {/* </div> */}
                                </ClickAwayListener>
                            </Paper>
                        </Transitions>
                    )}
                </Popper>
            </>
        );
    }
);
