/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BookingStatus {
    DRAFT = 'draft',
    HOLD = 'hold',
    RESERVED = 'reserved',
    CANCELLED = 'cancelled',
}
