import { addSeconds, format } from 'date-fns';

const startDate = new Date(
    (60 * 24 + new Date().getTimezoneOffset()) * 60 * 1000
);
export const minutesToTime = (minutes: number) =>
    format(addSeconds(startDate, minutes * 60), 'HH:mm');

export const durationToString = (duration: Record<string, number>): string => {
    const arr = [];
    if (duration.day) {
        arr.push(`${duration.day}d`);
    }
    if (duration.hour) {
        arr.push(`${duration.hour}h`);
    }
    if (duration.minute) {
        arr.push(`${duration.minute}m`);
    }

    return arr.length ? arr.join(' ') : '-';
};

export const durationToHumanReadable = (
    duration: Record<string, number>
): string => {
    const arr = [];
    if (duration.day) {
        arr.push(`${duration.day} day${duration.day > 1 ? 's' : ''}`);
    }
    if (duration.hour) {
        arr.push(`${duration.hour} hour${duration.hour > 1 ? 's' : ''}`);
    }
    if (duration.minute) {
        arr.push(`${duration.minute} minute${duration.minute > 1 ? 's' : ''}`);
    }

    return arr.length ? arr.join(' ') : '-';
};
