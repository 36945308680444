import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@mui/material';

// assets
import {
    IconBrandTelegram,
    IconBuildingStore,
    IconMailbox,
} from '@tabler/icons';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.grey.A200,
    },
    '& .MuiListItem-root': {
        padding: 0,
    },
}));

function NotificationList() {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px',
        color: theme.palette.primary.contrastText,
    };
    const chipErrorSX = {
        ...chipSX,
        backgroundColor: theme.palette.primary.light,
        marginRight: '5px',
    };

    const chipWarningSX = {
        ...chipSX,
        backgroundColor: theme.palette.secondary.light,
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300,
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22,
                },
                '& .MuiDivider-root': {
                    my: 0,
                },
                '& .list-container': {
                    pl: 7,
                },
            }}
        >
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                border: 'none',
                            }}
                        >
                            <IconBuildingStore size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="John Doe" />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                >
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">
                            It is a long established fact that a reader will be
                            distracted
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Unread" sx={chipErrorSX} />
                            </Grid>
                            <Grid item>
                                <Chip label="New" sx={chipWarningSX} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                backgroundColor: theme.palette.success.light,
                                border: 'none',
                                borderColor: theme.palette.success.main,
                            }}
                        >
                            <IconBuildingStore size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography variant="subtitle1">
                                Store Verification Done
                            </Typography>
                        }
                    />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                >
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">
                            We have successfully received your request.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Unread" sx={chipErrorSX} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                backgroundColor: theme.palette.primary.light,
                                border: 'none',
                                borderColor: theme.palette.primary.main,
                            }}
                        >
                            <IconMailbox size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography variant="subtitle1">
                                Check Your Mail.
                            </Typography>
                        }
                    />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                >
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">
                            All done! Now check your inbox as you&apos;re in for
                            a sweet treat!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    endIcon={
                                        <IconBrandTelegram size="1.3rem" />
                                    }
                                >
                                    Mail
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
        </List>
    );
}

export default NotificationList;
