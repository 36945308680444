/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SelectionStatus {
    PENDING_CONFIRMATION = 'pending_confirmation',
    CONFIRMED = 'confirmed',
}
