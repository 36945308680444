/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EventStatus {
    DRAFT = 'draft',
    UPCOMING = 'upcoming',
    ONGOING = 'ongoing',
    FINISHED = 'finished',
    CANCELLED = 'cancelled',
}
