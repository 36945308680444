import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    useQuery,
    // useMutation,
    // UseMutationOptions,
} from '@tanstack/react-query';
import axios from 'axios';
import { RequestStatus, useRequestStatus } from './utils';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

// export const useRegisterUsers = (
//     options?: Omit<
//         UseMutationOptions<
//             Awaited<ReturnType<typeof registerUsers>>,
//             unknown,
//             {
//                 requestBody: {};
//             },
//             unknown
//         >,
//         'mutationFn'
//     >
// ) => {
//     const { getAccessTokenSilently } = useAuth0();
//     useMutation(
//         ({ requestBody }) =>
//             async () => {
//                 const authorization = await getAccessTokenSilently();
//                 return await registerUsers(authorization, requestBody);
//             },
//         options
//     );
// };

export const useGetUser = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            const accessToken = await getAccessTokenSilently();
            const { data } = await axios({
                url: `${apiServerUrl}/users/me`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return data;
        },
        retry: false,
        cacheTime: 10,
    });
};

export interface UpdateUser extends RequestStatus {
    updateUser: (id: string, fields: Record<string, any>) => void;
}

export const useUpdateUser = (): UpdateUser => {
    const { requestStatus, startRequest, endRequest } = useRequestStatus();
    const { getAccessTokenSilently } = useAuth0();

    const updateUser = useCallback(
        async (id: string, fields: Record<string, any>) => {
            startRequest();

            const accessToken = await getAccessTokenSilently();
            await axios({
                url: `${apiServerUrl}/user/${id}`,
                method: 'PUT',
                data: { fields },
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            endRequest();
        },
        [getAccessTokenSilently]
    );

    return {
        updateUser,
        ...requestStatus,
    };
};

export interface RegisterUser extends RequestStatus {
    registerUser: (fields: Record<string, any>) => void;
}

export const useRegisterUser = (): RegisterUser => {
    const { requestStatus, startRequest, endRequest } = useRequestStatus();
    const { getAccessTokenSilently } = useAuth0();

    const registerUser = useCallback(
        async (data: Record<string, any>) => {
            startRequest();

            const accessToken = await getAccessTokenSilently();
            await axios({
                url: `${apiServerUrl}/users/register`,
                method: 'POST',
                data,
                withCredentials: false,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            endRequest();
        },
        [getAccessTokenSilently]
    );

    return {
        registerUser,
        ...requestStatus,
    };
};
