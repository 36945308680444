import React from 'react';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import Route from '../route/route';

export interface RouteTooltipProps {
    children: React.ReactElement;
    stops: string[];
}

export const RouteTooltip: React.FC<RouteTooltipProps> = props => {
    const { stops, children } = props;

    return (
        <Tooltip
            slotProps={{
                tooltip: {
                    sx: {
                        px: 0,
                        borderRadius: '12px',
                        border: '1px solid #DFE1ED',
                        background: '#FFF',
                        opacity: 1,
                        boxShadow: '0px 0px 16px 0px #B2B9CD',
                    },
                },
            }}
            title={
                <Box>
                    <Typography
                        sx={{
                            my: 2,
                            mx: 2,
                            color: '#949BAC',
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '100%',
                        }}
                    >
                        Route
                    </Typography>
                    <Divider />
                    <Route
                        stops={stops}
                        timelineProps={{
                            sx: {
                                mb: 0,
                            },
                        }}
                    />
                </Box>
            }
        >
            {children}
        </Tooltip>
    );
};
