import { useController, useFormContext } from 'react-hook-form';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MuiTextField from '@mui/material/TextField';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField/TextField';
import _ from 'lodash';
import { DialogContent, IconButton, InputAdornment } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export type TextAreaProps = MuiTextFieldProps & {
    name: string;
    label: string;
    required?: boolean;
    fullWidth?: boolean;
    showErrors?: boolean;
};

const TextArea: React.FC<TextAreaProps> = ({
    name,
    required,
    label,
    fullWidth,
    showErrors,
    ...rest
}) => {
    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();
    const { field } = useController({ control, name });
    const inputRef = useRef<HTMLInputElement>();
    const buttonRef = useRef<HTMLButtonElement>(null);

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);
    const [modalState, setStateModal] = useState({
        text: '',
        open: false,
    });

    const onModalSave = () => {
        field.onChange(modalState.text);
        field.onBlur();

        setStateModal({
            text: '',
            open: false,
        });
        setTimeout(() => {
            buttonRef.current?.focus();
        }, 100);
    };

    const onModalClose = () => {
        setStateModal({
            text: '',
            open: false,
        });
        setTimeout(() => {
            buttonRef.current?.focus();
        }, 100);
    };

    const value = watch(name);
    const onModalOpen = () => {
        setStateModal({
            text: value,
            open: true,
        });
    };

    const onTextFieldChange = useCallback(
        (e: React.ChangeEvent<{ value: unknown }>) => {
            setStateModal(prevState => ({
                ...prevState,
                text: e.target.value as string,
            }));
        },
        []
    );

    return (
        <>
            <MuiTextField
                inputRef={inputRef}
                fullWidth={false}
                margin="dense"
                InputLabelProps={{
                    required: !!required,
                    shrink: !!rest.placeholder,
                }}
                label={label}
                value={field.value}
                type="text"
                error={!!error}
                helperText={error}
                {...rest}
                sx={{
                    m: 0,
                    width: fullWidth ? '100%' : '388px',
                    '&&&& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #DFE1ED',
                    },
                    '&&&& .Mui-focused.MuiFormLabel-root': {
                        color: '#949BAC',
                    },
                    ...(rest.sx || {}),
                }}
                inputProps={{
                    tabIndex: -1,
                }}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                ref={buttonRef}
                                onClick={onModalOpen}
                                edge="end"
                                sx={{
                                    border: '2px solid transparent',
                                    '&.Mui-focusVisible': {
                                        border: '2px solid #55B5CF',
                                    },
                                }}
                            >
                                <FullscreenIcon sx={{ color: '#B2B9CD' }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <Dialog
                open={modalState.open}
                onClose={onModalClose}
                disableRestoreFocus={true}
                sx={{
                    '& .MuiDialog-container': {
                        justifyContent: 'end',
                        alignItems: 'end',
                    },
                }}
                PaperProps={{
                    sx: {
                        width: '400px',
                        height: '300px',
                        m: 2,
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{ fontSize: '16px', fontWeight: 600 }}
                    id="alert-dialog-title"
                >
                    {label}
                </DialogTitle>
                <DialogContent>
                    <MuiTextField
                        multiline
                        fullWidth
                        autoFocus
                        InputProps={{
                            sx: {
                                p: 0,
                                '& .MuiOutlinedInput-input': {
                                    p: 0,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    display: 'none',
                                },
                            },
                        }}
                        placeholder={rest.placeholder}
                        rows={10}
                        value={modalState.text}
                        onChange={onTextFieldChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="neutral"
                        onClick={onModalClose}
                    >
                        Discard
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onModalSave}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TextArea;
