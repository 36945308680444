import { useFieldArray, useFormContext } from 'react-hook-form';
import React, { ReactElement, useCallback } from 'react';
import { ButtonProps, Divider, Paper } from '@mui/material';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { Sortable } from '@travelity/web/src/components/sortable';
import { IconTextButton } from '@travelity/web/src/components/icon-text-button';
import CardItem from './card-item';
import { UseFormParams } from '../../use-form';

// TODO auto scroll on add
export interface RenderContentProps {
    editing: boolean;
    item: Record<string, any>;
    listeners?: SyntheticListenerMap[];
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
}

export interface CardItemOption {
    label: string;

    [k: string]: any;
}

export interface CardListProps extends Partial<ButtonProps> {
    disabled?: boolean;
    renderHeader: (p: RenderContentProps) => ReactElement;
    renderContent: (p: RenderContentProps) => ReactElement;
    name: string;
    headerSx?: Record<string, any>;
    addButtonText: string;
    itemOptions?: CardItemOption[];
    formParams?: Partial<UseFormParams<Record<string, any>>>;
    defaultState?: Record<string, any>;
    disableAdding?: boolean;
    disableReorder?: boolean;
    disableRemove?: boolean;
    onItemSave?: (v: Record<string, any>) => void;
    onItemRemove?: (v: Record<string, any>) => void;
}

const CardList: React.FC<CardListProps> = ({
    disabled,
    name,
    renderHeader,
    renderContent,
    addButtonText,
    headerSx,
    itemOptions,
    formParams,
    defaultState,
    disableAdding,
    disableReorder,
    disableRemove,
    onItemSave,
    onItemRemove,
}) => {
    const { control } = useFormContext();
    const {
        fields: items,
        update,
        replace,
        remove,
        append,
    } = useFieldArray<Record<string, any>, string, string>({
        control,
        name,
    });

    const sortableIndexes = items.map(v => v.id);

    const setSorting = (newSorting: string[]) => {
        replace(newSorting.map(id => items.find(item => item.id === id)));
    };

    const onEdit = useCallback(
        (index: number) => {
            const newItem = { ...items[index], isEdit: true };
            update(index, newItem);
        },
        [items, update]
    );

    const onAdd = useCallback(
        (initial = {}) => {
            append({ isNew: true, ...initial });
        },
        [append]
    );

    const onSave = useCallback(
        (index: number, item: Record<string, any>) => {
            const newItem = { ...item, isNew: false, isEdit: false };
            if (onItemSave) {
                onItemSave(item);
            }
            update(index, newItem);
        },
        [onItemSave, update]
    );

    const onCancel = useCallback(
        (index: number) => {
            const item = items[index];
            if (item?.isNew) {
                remove(index);
            } else {
                update(index, { ...item, isEdit: false });
            }
        },
        [items, remove, update]
    );

    const onDelete = useCallback(
        (index: number) => {
            if (onItemRemove) onItemRemove(items[index]);
            remove(index);
        },
        [items, remove, update]
    );

    const hasAnyEdit = items.some(item => item.isEdit || item.isNew);

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'demoMenu',
    });

    return (
        <Paper
            sx={{
                p: 2,
                height: items.length > 0 ? 'auto' : '244px',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                ...(disabled
                    ? {
                          opacity: 0.6,
                          pointerEvents: 'none',
                      }
                    : {}),
            }}
        >
            <Sortable
                items={sortableIndexes}
                setItems={setSorting}
                renderItem={obj => (
                    <CardItem
                        index={obj.index}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onEditSave={onSave}
                        onEditDiscard={onCancel}
                        editing={
                            !!items[obj.index]?.isEdit ||
                            !!items[obj.index]?.isNew
                        }
                        items={items}
                        item={items[obj.index]}
                        setActivatorNodeRef={obj.setActivatorNodeRef}
                        listeners={obj.listeners}
                        style={obj.style}
                        placeholder={obj.dragging}
                        renderHeader={renderHeader}
                        headerSx={headerSx}
                        renderContent={renderContent}
                        ref={obj.ref}
                        formParams={formParams}
                        disableReorder={disableReorder}
                        disableRemove={disableRemove}
                    />
                )}
            />
            {!disableAdding && !disabled && (
                <>
                    {items.length === 0 ? (
                        <IconTextButton
                            text={addButtonText}
                            icon={<Add />}
                            className="add-button"
                            {...(itemOptions
                                ? bindTrigger(popupState)
                                : {
                                      onClick: () => onAdd(defaultState),
                                  })}
                        />
                    ) : (
                        !hasAnyEdit && (
                            <Button
                                sx={{ bgcolor: '#FFFFFF' }}
                                variant="outlined"
                                className="add-button"
                                fullWidth
                                {...(itemOptions
                                    ? bindTrigger(popupState)
                                    : {
                                          onClick: () => onAdd(defaultState),
                                      })}
                            >
                                <Add /> {addButtonText}
                            </Button>
                        )
                    )}
                </>
            )}

            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                MenuListProps={{
                    sx: { width: popupState.anchorEl?.clientWidth },
                }}
                {...bindMenu(popupState)}
            >
                {itemOptions?.map(({ label, ...rest }, index) => (
                    <>
                        {!!index && (
                            <Divider sx={{ mx: 2, '&&&': { my: 0.5 } }} />
                        )}
                        <MenuItem
                            sx={{
                                justifyContent: 'center',
                                color: '#2B395B',
                                fontSize: '12px',
                                margin: '0 16px',
                                borderRadius: '4px',
                                '&:before': {
                                    borderTop: index ? '1px solid #DFE1ED' : 0,
                                },
                                '&:hover': {
                                    backgroundColor: '#D7E7EB',
                                },
                            }}
                            onClick={() => {
                                popupState.close();
                                onAdd(rest);
                            }}
                        >
                            {label}
                        </MenuItem>
                    </>
                ))}
            </Menu>
        </Paper>
    );
};

export default CardList;
