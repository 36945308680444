import React, { memo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from '@travelity/form';
import { useWatch } from 'react-hook-form';
import { FilterItem } from './components/filter-item';
import { FilterOption, FilterValue } from './filters.types';
import { FilterButton } from './components/filter-button';

export interface FiltersProps {
    values: Record<string, FilterValue> | {};
    setValues: (v: Record<string, FilterValue>) => void;
    options: FilterOption[];
}

export const Filters: React.FC<FiltersProps> = memo((props: FiltersProps) => {
    const { values: appliedValues, setValues: applyValues, options } = props;
    const { Form, reset, control, getValues } = useForm({
        defaultValues: appliedValues,
        mode: 'onChange',
    });
    // useEffect(() => {
    //     console.log('something changed 1');
    //     reset(appliedValues2);
    // }, [reset, appliedValues2]);
    //
    useWatch({ control });
    // console.log('something changed');
    // // const appliedValues = appliedValues2;
    const values = getValues();

    const valuesArray = Object.keys(values)
        .map(name => ({
            name,
            value: values[name],
        }))
        .filter(
            ({ value, name }) => value && options.find(o => name === o.name)
        );

    return (
        <Stack
            direction="row"
            justifyContent={!valuesArray.length ? 'left' : 'space-between'}
            gap={1}
            alignItems="center"
            sx={{
                py: 1,
                height: '56px',
            }}
        >
            {!valuesArray.length && (
                <Typography
                    sx={{
                        color: '#B2B9CD',
                        fontSize: '20px',
                        fontWeight: '700',
                    }}
                >
                    Configure Filtering
                </Typography>
            )}
            {!!valuesArray.length && (
                <Stack sx={{ flexGrow: 2, borderRight: '#DFE2EC 1px solid' }}>
                    <Form>
                        <Stack direction="row" gap={1}>
                            {valuesArray.map(filter => (
                                <FilterItem
                                    value={filter.value}
                                    filter={
                                        options.find(
                                            ({ name }) => name === filter.name
                                        ) as FilterOption
                                    }
                                />
                            ))}
                        </Stack>
                    </Form>
                </Stack>
            )}
            {!!valuesArray.length && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => applyValues(values)}
                >
                    Filter
                </Button>
            )}

            <FilterButton
                selectedFilters={values}
                updateSelectedFilters={reset}
                options={options}
            />
        </Stack>
    );
});
