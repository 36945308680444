import React from 'react';
import { Box, Divider, Paper, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { EventTraveler, useSetTravelerParticipation } from '@travelity/api';
import { ActivityType } from '@travelity/api/src/requests';
import { Tag } from '@travelity/ui';
import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';

export interface BookingParticipantsFormProps {
    eventId: string;
    // preview?: boolean;
}

const BookingParticipantsForm: React.FC<BookingParticipantsFormProps> = ({
    eventId,
}) => {
    // const { t } = useTranslation();
    const { watch } = useFormContext();
    const travelers = watch('travelers') as EventTraveler[];

    const { mutate: setParticipation } = useSetTravelerParticipation();

    return (
        <Stack spacing={2}>
            {!!travelers?.length && (
                <Paper
                    sx={{
                        mt: 2,
                        p: 2,
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {travelers?.map(traveler => (
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{
                                width: 1,
                                alignItems: 'flex-start',
                            }}
                        >
                            <Stack
                                flexGrow={2}
                                sx={{
                                    bgcolor: '#FFFFFF',
                                    boxShadow:
                                        '0px 1px 12px rgba(178, 185, 205, 0.3)',
                                    borderRadius: '12px',
                                }}
                            >
                                <Box
                                    sx={{
                                        py: '22px',
                                        px: 2,
                                        color: '#9298ab',
                                        bgcolor: '#f7f8fa',
                                        height: '36px',
                                        fontWeight: 600,
                                        borderRadius: '12px 12px 0px 0px',
                                        lineHeight: '10px',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        sx={{ width: 1, pl: '10px' }}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box component="span">
                                            {traveler.name}
                                        </Box>
                                        <Tag
                                            label="Type"
                                            values={['Traveler']}
                                        />
                                    </Stack>
                                </Box>
                                <Stack gap={0.5} p={2}>
                                    <Divider>Contact Details</Divider>
                                    <SingleLineKeyValue
                                        label="Emails"
                                        value={traveler.emails
                                            ?.map(
                                                ({
                                                    value,
                                                }: {
                                                    value: string;
                                                }) => value
                                            )
                                            .join(', ')}
                                    />
                                    <SingleLineKeyValue
                                        label="Phone Numbers"
                                        value={traveler.numbers
                                            ?.map(
                                                ({
                                                    value,
                                                }: {
                                                    value: string;
                                                }) => value
                                            )
                                            .join(', ')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack gap={0.5}>
                                <Stack
                                    flexShrink={0}
                                    direction="column"
                                    sx={{
                                        width: '34px',
                                        background: '#FFFFFF',
                                        boxShadow:
                                            '0px 1px 12px rgba(178, 185, 205, 0.6)',
                                        borderRadius: '12px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            px: 0.5,
                                            py: 1,
                                            lineHeight: '12px',
                                            color: '#C9CEDC',
                                            '&:hover': {
                                                color: '#2CAC60',
                                            },
                                            ...(traveler.status ===
                                            ActivityType.ATTENDANCE
                                                ? {
                                                      color: '#2CAC60',
                                                      '&:hover': {},
                                                      cursor: 'default',
                                                      pointerEvents: 'none',
                                                  }
                                                : {}),
                                        }}
                                        href="#"
                                        component="a"
                                        onClick={() => {
                                            if (
                                                traveler.status !==
                                                ActivityType.ATTENDANCE
                                            ) {
                                                setParticipation({
                                                    bookingId:
                                                        traveler.bookingId,
                                                    eventId,
                                                    travelerId: traveler.id,
                                                    activityType:
                                                        ActivityType.ATTENDANCE,
                                                });
                                            }
                                        }}
                                    >
                                        <EmojiPeopleIcon fontSize="small" />
                                    </Box>
                                </Stack>
                                <Stack
                                    flexShrink={0}
                                    direction="column"
                                    sx={{
                                        width: '34px',
                                        background: '#FFFFFF',
                                        boxShadow:
                                            '0px 1px 12px rgba(178, 185, 205, 0.6)',
                                        borderRadius: '12px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            px: 0.5,
                                            py: 1,
                                            lineHeight: '12px',
                                            color: '#C9CEDC',
                                            '&:hover': {
                                                color: '#D84F5F',
                                            },
                                            ...(traveler.status ===
                                            ActivityType.ABSENCE
                                                ? {
                                                      color: '#D84F5F',
                                                      '&:hover': {},
                                                      cursor: 'default',
                                                      pointerEvents: 'none',
                                                  }
                                                : {}),
                                        }}
                                        href="#"
                                        component="a"
                                        onClick={() => {
                                            if (
                                                traveler.status !==
                                                ActivityType.ABSENCE
                                            ) {
                                                setParticipation({
                                                    bookingId:
                                                        traveler.bookingId,
                                                    eventId,
                                                    travelerId: traveler.id,
                                                    activityType:
                                                        ActivityType.ABSENCE,
                                                });
                                            }
                                        }}
                                    >
                                        <PersonOffIcon fontSize="small" />
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    ))}
                </Paper>
            )}
        </Stack>
    );
};

export default React.memo(BookingParticipantsForm);
