import React, { forwardRef, useMemo, useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    BookingStatus,
    EventBookingItem as IEventBookingItem,
    PaymentStatus,
} from '@travelity/api';
import { useCancelBookings } from '@travelity/api/src/queries';
import { formatValue } from '@travelity/form';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Tag } from '@travelity/ui';
import { ConfirmationDialog } from '../confirmation-dialog';
import { ActionIconButton } from '../action-icon-button';
import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';
import { MoveBookingModal } from '../move-booking-modal';

export interface EventBookingItemProps {
    booking: IEventBookingItem;
    isSelected: boolean;
    eventId: string;
    refetch: () => void;
}

const EventBookingItem = forwardRef<HTMLDivElement, EventBookingItemProps>(
    ({ booking, isSelected, eventId, refetch }, ref) => {
        const { t } = useTranslation('booking');

        const [moveBooking, setMoveBooking] = useState(false);

        const [deletingBooking, setDeletingBooking] = useState<string>();
        const { mutate: removeBooking, isLoading: isRemoveBookingLoading } =
            useCancelBookings({
                onSuccess: () => {
                    setDeletingBooking(undefined);
                    refetch();
                },
            });
        const options = useMemo(
            () =>
                booking.product.options?.map(({ name, participants }) => ({
                    name,
                    count: Object.values(participants?.pax || {}).reduce(
                        (s, c) => s + (c || 0),
                        0
                    ),
                })),
            []
        );

        const navigate = useNavigate();

        return (
            <>
                <Stack
                    direction="row"
                    gap={1}
                    ref={ref}
                    sx={{
                        width: 1,
                        alignItems: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            width: '88px',
                            height: '88px',
                        }}
                    />
                    <Stack
                        flexGrow={2}
                        sx={{
                            border: isSelected
                                ? '1px solid #55B5CF'
                                : '1px solid transparent',
                            bgcolor: '#FFFFFF',
                            boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                            borderRadius: '12px',
                        }}
                    >
                        <Stack gap={1} direction="row">
                            <Stack
                                sx={{
                                    width: '82px',
                                    py: 1,
                                    px: 2,
                                    borderRight: '1px solid #D6DAE4',
                                }}
                                gap={0.5}
                            >
                                <Stack
                                    sx={{
                                        height: '50px',
                                    }}
                                    justifyContent="center"
                                    alignItems="center"
                                    gap={0.5}
                                >
                                    <ConfirmationNumberIcon
                                        sx={{
                                            color:
                                                booking.status ===
                                                BookingStatus.RESERVED
                                                    ? '#2CAC60'
                                                    : '#D84F5F',
                                            fontSize: '24px',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            color: '#B2B9CD',
                                        }}
                                    >
                                        {t(booking.status)}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack
                                    sx={{
                                        height: '50px',
                                    }}
                                    justifyContent="center"
                                    alignItems="center"
                                    gap={0.5}
                                >
                                    <MonetizationOnIcon
                                        sx={{
                                            color:
                                                booking.financials.status ===
                                                PaymentStatus.PAID
                                                    ? '#2CAC60'
                                                    : '#D84F5F',
                                            fontSize: '24px',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            color: '#B2B9CD',
                                            overflow: 'hidden',
                                            textWrap: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%',
                                        }}
                                    >
                                        {t(booking.financials.status)}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack sx={{ px: 2, py: 1 }} flexGrow={2}>
                                <SingleLineKeyValue
                                    label="Order #"
                                    darkValue
                                    value={`${booking.order.number}`}
                                    onValueClick={() =>
                                        navigate(`/order/${booking.order.id}`)
                                    }
                                />
                                <Divider sx={{ marginTop: 0.5 }} />
                                <SingleLineKeyValue
                                    label="PAX"
                                    darkValue
                                    value={`${formatValue(
                                        booking.participants.pax
                                    )}`}
                                />
                                <Divider sx={{ marginTop: 0.5 }} />
                                <SingleLineKeyValue
                                    label="Notes"
                                    darkValue
                                    value={`${booking.notes?.length || 0}`}
                                />
                            </Stack>
                        </Stack>
                        {!!options?.length && (
                            <>
                                <Divider />
                                <Stack
                                    direction="row"
                                    gap={0.5}
                                    sx={{ px: 2, py: 1.5 }}
                                >
                                    {options.map(option => (
                                        <Tag
                                            label={option.name}
                                            values={[`${option.count}`]}
                                        />
                                    ))}
                                </Stack>
                            </>
                        )}
                    </Stack>
                    <Stack
                        flexShrink={0}
                        direction="column"
                        gap={1}
                        sx={{
                            width: '34px',
                        }}
                    >
                        <Stack
                            direction="column"
                            sx={{
                                width: '34px',
                                background: '#FFFFFF',
                                boxShadow:
                                    '0px 1px 12px rgba(178, 185, 205, 0.6)',
                                borderRadius: '12px',
                            }}
                        >
                            <ActionIconButton
                                onClick={() =>
                                    navigate(`/events/${eventId}/${booking.id}`)
                                }
                                icon={<VisibilityIcon fontSize="small" />}
                            />
                            <Divider sx={{ mx: 1 }} />
                            <ActionIconButton
                                onClick={() => {
                                    setMoveBooking(true);
                                }}
                                icon={<ExitToAppIcon fontSize="small" />}
                            />
                            <Divider sx={{ mx: 1 }} />
                            <ActionIconButton
                                hoverColor="error.main"
                                onClick={() => setDeletingBooking(booking.id)}
                                icon={<DeleteIcon fontSize="small" />}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <ConfirmationDialog
                    title="Are you sure you want to delete this booking?"
                    content="This will delete this booking permanently. You cannot undo
                    this action."
                    confirmText="Delete"
                    open={!!deletingBooking}
                    isLoading={isRemoveBookingLoading}
                    handleCancel={() => setDeletingBooking(undefined)}
                    handleConfirm={() =>
                        removeBooking({ bookingId: deletingBooking as string })
                    }
                />
                <MoveBookingModal
                    handleCancel={() => setMoveBooking(false)}
                    handleConfirm={() => {
                        refetch();
                        setMoveBooking(false);
                    }}
                    booking={booking}
                    open={moveBooking}
                />
            </>
        );
    }
);

export default EventBookingItem;
