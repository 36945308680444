import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import mainRoutes from './main-routes';
import authenticationRoutes from './authentication-routes';
import Auth0ProviderLayout from './auth0-provider';

const router = createBrowserRouter([
    {
        element: <Auth0ProviderLayout />,
        children: [...authenticationRoutes, mainRoutes],
    },
]);

export default router;
