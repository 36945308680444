import React, { ReactElement } from 'react';
import { Box } from '@mui/material';

export interface ActionIconButtonProps {
    icon: ReactElement;
    disabled?: boolean;
    onClick?: () => void;
    color?: string;
    hoverColor?: string;
}

export const ActionIconButton: React.FC<ActionIconButtonProps> = props => {
    const {
        icon,
        disabled,
        onClick,
        color = '#C9CEDC',
        hoverColor = 'secondary.main',
    } = props;

    return (
        <Box
            sx={{
                textAlign: 'center',
                px: 0.5,
                py: 1,
                lineHeight: '12px',
                color,
                '&:hover': {
                    color: hoverColor,
                },
                ...(disabled
                    ? {
                          '&:hover': {},
                          cursor: 'default',
                          pointerEvents: 'none',
                      }
                    : {}),
            }}
            href="#"
            component="a"
            onClick={e => {
                e.preventDefault();
                onClick?.();
            }}
        >
            {icon}
        </Box>
    );
};
