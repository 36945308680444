import React, { useMemo } from 'react';
import {
    Box,
    Button,
    Divider,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { useProducts } from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ProductItem } from '../../components/product-item';

import { ReactComponent as PreviewSvg } from './assets/preview.svg';
import { ReactComponent as LoopSvg } from '../../assets/loop.svg';
import ProductEditForm from './components/product-edit-form';
import { ProductItemSkeleton } from '../../components/product-item-skeleton';

export interface ProductsProps {}

function Products() {
    // const { t } = useTranslation('product');
    const navigate = useNavigate();
    const { productId } = useParams();

    const {
        data: products,
        isLoading,
        update: updateProduct,
        refetch,
    } = useProducts(useMemo(() => ({}), []));
    const product = useMemo(() => {
        return productId
            ? products?.find(({ id }) => id === productId)
            : undefined;
    }, [products, productId]);

    return (
        <Stack
            direction="row"
            sx={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }}
        >
            <Box
                sx={{
                    bgcolor: '#FFF',
                    flexGrow: 2,
                    ml: 2,
                    my: 2,
                    px: 2,
                    height: 'calc(100% - 32px)',
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        py: 1.5,
                        height: '72px',
                    }}
                >
                    <Typography variant="h5" sx={{ color: '#C0C4CE' }}>
                        All Products
                    </Typography>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/products/add')}
                    >
                        Create Product
                    </Button>
                </Stack>
                {/* <Box> */}
                {/*    <Divider> */}
                {/*        <Filters /> */}
                {/*    </Divider> */}
                {/* </Box> */}
                {products && (
                    <>
                        {products.length > 1 && (
                            <Divider>{products.length} products found</Divider>
                        )}
                        {products.length === 1 && (
                            <Divider>1 product found</Divider>
                        )}
                        {products.length === 0 && (
                            <Divider>No products found</Divider>
                        )}
                        <Box
                            sx={{
                                py: 1,
                                bgcolor: products.length
                                    ? '#F8F9FA'
                                    : undefined,
                                height: 'calc(100% - 72px - 21px)',
                                '& > div': {
                                    py: 1,
                                },
                            }}
                        >
                            <PerfectScrollbar>
                                {products.length ? (
                                    <Stack
                                        sx={{
                                            px: 2,
                                        }}
                                        gap={1}
                                    >
                                        {products.map(p => (
                                            <ProductItem
                                                key={p.id}
                                                product={p}
                                                refetch={refetch}
                                            />
                                        ))}
                                    </Stack>
                                ) : (
                                    <Stack
                                        sx={{
                                            display: 'flex',
                                            height: 1,
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LoopSvg />
                                        <Typography sx={{ color: '#949BAC' }}>
                                            Filter results will be here
                                        </Typography>
                                    </Stack>
                                )}
                            </PerfectScrollbar>
                        </Box>
                    </>
                )}
                {isLoading && (
                    <>
                        <Divider>
                            <Skeleton width={100} />
                        </Divider>

                        <Box
                            sx={{
                                py: 2,
                                bgcolor: '#F8F9FA',
                                height: 'calc(100% - 72px - 21px)',
                            }}
                        >
                            <Stack
                                sx={{
                                    px: 2,
                                }}
                                gap={1}
                            >
                                {[0, 1, 2].map(v => (
                                    <ProductItemSkeleton key={v} />
                                ))}
                            </Stack>
                        </Box>
                    </>
                )}
            </Box>
            <Box
                sx={{
                    bgcolor: '#FFF',
                    my: 2,
                    ml: 2,
                    width: '483px',
                    position: 'relative',
                    height: 'calc(100% - 32px)',
                    ...(!productId
                        ? {
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 2,
                          }
                        : {}),
                }}
            >
                {!productId && (
                    <>
                        <PreviewSvg />
                        <Typography sx={{ color: '#949BAC' }}>
                            No product is selected for preview
                        </Typography>
                    </>
                )}
                {product && (
                    <ProductEditForm
                        product={product}
                        updateProduct={updateProduct}
                    />
                )}
            </Box>
        </Stack>
    );
}

export default Products;
