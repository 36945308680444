import { useMemo } from 'react';
import { useGetUser } from '../../queries';
import { User } from './user.types';
import { userDtoToUser } from './user.converters';

export const useUser = () => {
    const { data: userDto, ...rest } = useGetUser(undefined, {
        retry: false,
        cacheTime: 10,
    });

    const data: User | undefined = useMemo(() => {
        if (!userDto) {
            return undefined;
        }

        return userDtoToUser(userDto);
    }, [userDto]);

    return {
        data,
        ...rest,
    };
};
