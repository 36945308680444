import { useController, useFormContext } from 'react-hook-form';
import React from 'react';
import {
    SwitchProps as MuiSwitchProps,
    Switch as MuiSwitch,
    FormControlLabel,
    FormControlLabelProps,
} from '@mui/material';

export interface SwitchProps
    extends Partial<Omit<MuiSwitchProps, 'defaultValue'>> {
    name: string;
    label: string;
    defaultValue?: boolean;
    LabelProps?: Partial<FormControlLabelProps>;
}

const Switch: React.FC<SwitchProps> = ({
    defaultValue,
    name,
    label,
    LabelProps = {},
    disabled,
    ...rest
}) => {
    const { control } = useFormContext();
    const {
        field: { value, ...field },
    } = useController({
        name,
        control,
        defaultValue,
    });

    return (
        <FormControlLabel
            {...LabelProps}
            sx={{
                color: '#7682A4',
                '& > span': { fontSize: '14px' },
                width: 1,
                justifyContent: 'space-between',
                margin: 0,
                ...(LabelProps.sx || {}),
            }}
            labelPlacement="start"
            control={
                <MuiSwitch
                    {...field}
                    disabled={disabled}
                    checked={!disabled && value}
                    {...rest}
                />
            }
            label={label}
        />
    );
};

export default Switch;
