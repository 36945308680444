/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductReqDto } from '../models/CreateProductReqDto';
import type { CreateProductResDto } from '../models/CreateProductResDto';
import type { DeleteProductResDto } from '../models/DeleteProductResDto';
import type { GetProductsResDto } from '../models/GetProductsResDto';
import type { ProductType } from '../models/ProductType';
import type { UpdateProductReqDto } from '../models/UpdateProductReqDto';
import type { UpdateProductResDto } from '../models/UpdateProductResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * List/Filter Products
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param type Type of the product(s) being searched
     * @param name name of the product(s) being searched
     * @param startTs Timestamp of start datetime
     * @param endTs Timestamp of end datetime
     * @param currency Currency of the pricing
     * @param minPrice Minimum price to match products with.
     * @param maxPrice Maximum price to match products with.
     * @param location location that is in the route of the search product(s)
     * @returns GetProductsResDto Successful Response
     * @throws ApiError
     */
    public static getProducts(
authorization: string,
type?: ProductType,
name?: string,
startTs?: number,
endTs?: number,
currency?: string,
minPrice?: number,
maxPrice?: number,
location?: string,
): CancelablePromise<GetProductsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/products',
            headers: {
                'authorization': authorization,
            },
            query: {
                'type': type,
                'name': name,
                'start_ts': startTs,
                'end_ts': endTs,
                'currency': currency,
                'min_price': minPrice,
                'max_price': maxPrice,
                'location': location,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Product
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createProduct(
authorization: string,
requestBody: CreateProductReqDto,
): CancelablePromise<CreateProductResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/products',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Product by ID
     * @param productId ID of the product being deleted
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteProduct(
productId: string,
authorization: string,
): CancelablePromise<DeleteProductResDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/products/{product_id}',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Product by ID
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProduct(
productId: string,
authorization: string,
requestBody: UpdateProductReqDto,
): CancelablePromise<UpdateProductResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get generated product name
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static generateNameProducts(
authorization: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/products/generate_name',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
