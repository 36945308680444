import React, { useCallback, useEffect } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import {
    CurrencyField,
    DatePicker,
    PaxPicker,
    SearchSelect,
    SearchSelectOption,
    Select,
    StaticDatePicker,
    useForm,
} from '@travelity/form';
import { useFormState } from 'react-hook-form';
import { currencies } from '@travelity/api';
import {
    FilterForm,
    FilterValue,
    FilterOption,
    FilterTypes,
} from '../../filters.types';
import { SubmitButton } from '../../../submit-button';
import { useSelectOptions } from '../../../../hooks';
import { SelectItemProduct } from '../../../select-item-product';

export interface FilterSettingsProps {
    filter: FilterOption;
    value?: FilterValue;
    onCancel: () => void;
    onUpdate: (v: any) => void;
}

export const FilterSettings: React.FC<FilterSettingsProps> = props => {
    const { filter, value, onCancel, onUpdate } = props;

    const { Form, control, watch } = useForm<FilterForm>({
        onSubmit: useCallback(
            (data: FilterForm) => {
                onUpdate(data.value);
            },
            [onUpdate]
        ),
        mode: 'onChange',
        defaultValues: {
            value,
        },
    });
    const currencyOptions = useSelectOptions(currencies, 'name', 'abbr', false);
    const formState = useFormState({ control });

    const formValue = watch('value');
    const closeOnChange = filter.type === FilterTypes.DATE;
    useEffect(() => {
        if (closeOnChange && formState.isDirty) onUpdate(formValue);
    }, [formValue, closeOnChange, formState.isDirty]);

    return (
        <Form>
            <Stack sx={{ width: 370 }}>
                <Divider>{filter.label}</Divider>
                <Box sx={{ fontSize: '13px', mt: 1, color: '#949BAC' }}>
                    {filter.selectText}
                </Box>
                {filter.type === FilterTypes.PAX && <PaxPicker name="value" />}
                {filter.type === FilterTypes.DROPDOWN && (
                    <Stack direction="row" sx={{ mt: 2 }} gap="6px">
                        <SearchSelect
                            name="value"
                            label="Product"
                            multiple
                            options={filter.options as SearchSelectOption[]}
                            placeholder="Select Products"
                            MenuItem={SelectItemProduct}
                        />
                    </Stack>
                )}
                {filter.type === FilterTypes.SEARCH && (
                    <Stack direction="row" sx={{ mt: 2 }} gap="6px">
                        <DatePicker
                            name="value.startDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'Start Date',
                            }}
                        />
                        <DatePicker
                            name="value.endDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'End Date',
                            }}
                        />
                    </Stack>
                )}
                {filter.type === FilterTypes.DATES && (
                    <Stack direction="row" sx={{ mt: 2 }} gap="6px">
                        <DatePicker
                            name="value.startDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'Start Date',
                            }}
                        />
                        <DatePicker
                            name="value.endDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'End Date',
                            }}
                        />
                    </Stack>
                )}
                {filter.type === FilterTypes.DATE && (
                    <StaticDatePicker name="value" label="" showErrors />
                )}
                {filter.type === FilterTypes.PRICE && (
                    <Stack direction="column" sx={{ mt: 2 }} gap="16px">
                        <Select
                            width="100%"
                            name="value.currency"
                            label="Currency"
                            placeholder="Select Currency"
                            options={currencyOptions}
                        />
                        <CurrencyField
                            fullWidth
                            label="Min Price"
                            placeholder="Type Min Price"
                            name="value.minPrice"
                            currency={watch('value.currency') as string}
                        />
                        <CurrencyField
                            fullWidth
                            label="Max Price"
                            placeholder="Type Max Price"
                            name="value.maxPrice"
                            currency={watch('value.currency') as string}
                        />
                    </Stack>
                )}
                {filter.type === FilterTypes.TAGS && (
                    <Stack direction="row" sx={{ mt: 2 }} gap="6px">
                        <DatePicker
                            name="value.startDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'Start Date',
                            }}
                        />
                        <DatePicker
                            name="value.endDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'End Date',
                            }}
                        />
                    </Stack>
                )}

                {!closeOnChange && (
                    <Stack direction="row" gap={1} sx={{ mt: 2 }}>
                        <Button
                            variant="contained"
                            color="neutral"
                            sx={{ flexGrow: 1 }}
                            onClick={() => onCancel()}
                        >
                            Back
                        </Button>
                        <SubmitButton
                            variant="contained"
                            color="secondary"
                            sx={{ flexGrow: 1 }}
                            type="submit"
                            disabled={!formState.isDirty}
                        >
                            Add
                        </SubmitButton>
                    </Stack>
                )}
            </Stack>
        </Form>
    );
};
