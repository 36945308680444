import React, { useMemo, useState } from 'react';
import { Box, Chip, Collapse, Divider, Stack, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { AgeBands, BookingStatus, PaymentStatus } from '@travelity/api';
import { Tag } from '@travelity/ui';
import { useNavigate } from 'react-router-dom';
import { EventItem as IEventItem } from '@travelity/api/src/services/event/event.types';
import { format } from 'date-fns';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ExpandIcon from '@mui/icons-material/Expand';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import WarningIcon from '@mui/icons-material/Warning';
import { has } from 'lodash';
import { ConfirmationDialog } from '../confirmation-dialog';
import { EventStatus } from '../event-status';
import { ProductTypeIcon } from '../product-type-icon';
import { ActionIconButton } from '../action-icon-button';
import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';
import { useExpand } from '../../hooks';
import { EventBookingItem } from '../event-booking-item';

export interface EventItemProps {
    event: IEventItem;
    isSelected: boolean;
    bookingId?: string;
    refetch: () => void;
}

const EventItem: React.FC<EventItemProps> = ({
    event,
    isSelected,
    bookingId,
    refetch,
}) => {
    const { t } = useTranslation('product');

    const [deletingEvent, setDeletingEvent] = useState<string>();
    const [expanded, toggleExpand] = useExpand<boolean>();
    // const { mutate: removeEvent } = useDeleteEvent({
    //     onSuccess: () => {
    //         setDeletingEvent(undefined);
    //         refetch();
    //     },
    // });

    const options = event.bookingsSummary?.preferences_summary?.booked_options;

    const navigate = useNavigate();
    const attentionNeeded = useMemo(() => {
        if (event.bookingsSummary?.bookings) {
            return event.bookingsSummary?.bookings.some(
                booking =>
                    booking.status !== BookingStatus.RESERVED ||
                    booking.financials.status !== PaymentStatus.PAID
            );
        }
        return false;
    }, [event]);

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 2,
                    pr: 5,
                }}
            >
                <Stack direction="row" gap={1.5} alignItems="center">
                    <Typography
                        sx={{
                            color: '#6B748C',
                            fontSize: '14px',
                            fontWeight: '600',
                            // lineHeight: '26px',
                        }}
                    >
                        {event.product.name}
                    </Typography>
                    <CircleIcon sx={{ fontSize: '8px', color: '#D9D9D9' }} />
                    <ProductTypeIcon type={event.product.type} />
                    <Typography
                        sx={{
                            ml: '-8px',
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '500',
                        }}
                    >
                        {t(event.product.type)}
                    </Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                    <EventStatus status={event.status} />
                    {event.date.end && (
                        <Chip
                            sx={{
                                color: '#6B748C',
                                bgcolor: '#FFF',
                                borderRadius: '8px',
                                px: 0.5,
                                py: 0.5,
                                boxShadow:
                                    '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                                '& .MuiChip-label': {
                                    pl: 1.5,
                                    pr: 0.5,
                                    lineHeight: '18px',
                                },
                            }}
                            icon={
                                <AccessTimeFilledIcon
                                    sx={{ fontSize: '14px', color: '#6B748C' }}
                                />
                            }
                            label={`Ends at ${format(event.date.end, 'HH:mm')}`}
                        />
                    )}
                </Stack>
            </Stack>
            <Stack
                direction="row"
                gap={1}
                sx={{
                    mt: 1,
                    width: 1,
                    zIndex: 2,
                    alignItems: 'flex-start',
                }}
            >
                <Box>
                    {event.ephemeral && (
                        <Chip
                            label="Virtual"
                            sx={{
                                width: 1,
                                borderRadius: '8px',
                                background: '#EC8031',
                                padding: '6px 8px',
                                color: '#FFF',
                                fontSize: '14px',
                                mb: 0.5,
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            width: '88px',
                            height: '88px',
                            p: 1,
                            borderRadius: '12px',
                            bgcolor: '#FFF',
                            boxShadow:
                                '0px 0px 16px 0px rgba(178, 185, 205, 0.50)',
                        }}
                    >
                        <Stack
                            sx={{
                                height: 1,
                                p: 1,
                                borderRadius: '12px',
                                bgcolor: '#F4F6FA',
                            }}
                            justifyContent="center"
                            alignItems="center"
                            gap={0.5}
                        >
                            {attentionNeeded ? (
                                <>
                                    <WarningIcon
                                        sx={{
                                            color: '#EC8031',
                                            fontSize: '24px',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            color: '#B2B9CD',
                                        }}
                                    >
                                        Attention
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <CheckCircleIcon
                                        sx={{
                                            color: '#2CAC60',
                                            fontSize: '24px',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            color: '#B2B9CD',
                                        }}
                                    >
                                        All Set
                                    </Typography>
                                </>
                            )}
                        </Stack>
                    </Box>
                </Box>
                <Stack
                    flexGrow={2}
                    sx={{
                        zIndex: 3,
                        border: isSelected
                            ? '1px solid #55B5CF'
                            : '1px solid transparent',
                        bgcolor: '#FFFFFF',
                        boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                        borderRadius: '12px',
                    }}
                >
                    <Stack sx={{ p: 2 }} gap={1}>
                        <SingleLineKeyValue
                            label="Traveler"
                            darkValue
                            value={`${event.bookingsSummary?.travelers_summary?.count}`}
                        />
                        <Divider />
                        <Stack
                            direction="row"
                            gap={1}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ mt: 1 }}
                            >
                                <Typography
                                    sx={{ color: '#B7BCC7', fontSize: '12px' }}
                                >
                                    Capacity
                                </Typography>
                                {!!event.availableSeats && (
                                    <Tag
                                        label="Available Seats"
                                        values={[`${event.availableSeats}`]}
                                        valueProps={{
                                            sx: { backgroundColor: '#CDEFDB' },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                                sx={{ mt: 1 }}
                            >
                                {has(
                                    event.capacitySelection.capacity,
                                    'max_count'
                                ) ? (
                                    <>
                                        <Tag
                                            label="Min Count"
                                            values={[
                                                // @ts-ignore
                                                `${event.capacitySelection.capacity.min_count}`,
                                            ]}
                                        />
                                        <Tag
                                            label="Max Count"
                                            values={[
                                                // @ts-ignore
                                                `${event.capacitySelection.capacity.max_count}`,
                                            ]}
                                        />
                                    </>
                                ) : (
                                    Object.values(AgeBands).map(
                                        name =>
                                            // @ts-ignore
                                            !!event.capacitySelection.capacity[
                                                name
                                            ] && (
                                                <Tag
                                                    label={t(name)}
                                                    values={[
                                                        // @ts-ignore
                                                        event.capacitySelection
                                                            .capacity[name],
                                                    ]}
                                                />
                                            )
                                    )
                                )}

                                <Typography
                                    sx={{
                                        borderLeft: '1px solid #C9CEDC',
                                        pl: 1.5,
                                        color: '#B7BCC7',
                                        fontSize: '12px',
                                    }}
                                >
                                    {event.capacitySelection.capacity.name}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    {!!options?.length && (
                        <>
                            <Divider />
                            <Stack
                                direction="row"
                                gap={0.5}
                                sx={{ px: 2, py: 1.5 }}
                            >
                                {options.map(option => (
                                    <Tag
                                        label={option.name}
                                        values={[`${option.count}`]}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                </Stack>
                <Stack
                    flexShrink={0}
                    direction="column"
                    gap={1}
                    sx={{
                        width: '34px',
                    }}
                >
                    <Stack
                        direction="column"
                        sx={{
                            width: '34px',
                            background: '#FFFFFF',
                            boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                            borderRadius: '12px',
                        }}
                    >
                        <ActionIconButton
                            onClick={() => navigate(`/events/${event.id}`)}
                            icon={<VisibilityIcon fontSize="small" />}
                        />
                        <Divider sx={{ mx: 1 }} />
                        <ActionIconButton
                            onClick={() => toggleExpand(true)}
                            icon={<ExpandIcon fontSize="small" />}
                        />
                        <Divider sx={{ mx: 1 }} />
                        <ActionIconButton
                            hoverColor="error.main"
                            onClick={() => setDeletingEvent(event.id)}
                            icon={<DeleteIcon fontSize="small" />}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Collapse in={!!expanded}>
                <Stack gap={1} sx={{ mt: 1 }}>
                    {event.bookingsSummary?.bookings.map(booking => (
                        <EventBookingItem
                            booking={booking}
                            isSelected={booking.id === bookingId}
                            eventId={event.id}
                            refetch={refetch}
                        />
                    ))}
                </Stack>
            </Collapse>
            {!expanded &&
                !!event.bookingsSummary?.bookings.length &&
                event.bookingsSummary.bookings.slice(0, 3).map((booking, i) => {
                    return (
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{
                                width: 1,
                                alignItems: 'flex-start',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '88px',
                                }}
                            />
                            <Stack flexGrow={2}>
                                <Box
                                    sx={{
                                        mx: i * 2 + 2,
                                        boxShadow:
                                            '0px 0px 16px 0px rgba(178, 185, 205, 0.50)',
                                        height: '8px',
                                        borderRadius: '0px 0px 12px 12px',
                                    }}
                                />
                            </Stack>

                            <Stack
                                flexShrink={0}
                                direction="column"
                                gap={1}
                                sx={{
                                    width: '34px',
                                }}
                            />
                        </Stack>
                    );
                })}
            <ConfirmationDialog
                title="Are you sure you want to delete this event?"
                content="This will delete this event permanently. You cannot undo
                    this action."
                confirmText="Delete"
                open={!!deletingEvent}
                handleCancel={() => setDeletingEvent(undefined)}
                handleConfirm={() => {}}
            />
        </Box>
    );
};

export default EventItem;
