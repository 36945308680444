/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductOptionType {
    SERVICE = 'service',
    REFRESHMENT = 'refreshment',
    BREAKFAST = 'breakfast',
    LUNCH = 'lunch',
    DINNER = 'dinner',
    OVERNIGHT_STAY = 'overnight_stay',
}
