import { format } from 'date-fns';
import { has } from 'lodash';
import {
    AgebandCapacityData,
    AgebandPricingData,
    CreateProductReqDto,
    CreateProductReqFinancialsPricingOptionsItemDto,
    CustomScheduleData,
    DayOfWeek,
    TimeOffset,
    FlatCapacityData,
    FlatPricingData,
    GetProductsResItem0CreatedDto,
    GetProductsResItem0Dto,
    GetProductsResItem0OptionsItemFinancialsPricingDto,
    Language,
    MonthlyRecurrenceData,
    PricingType,
    ProductTagType,
    SeasonData,
    UpdateProductReqDto,
    WeeklyRecurrenceData,
    YearlyRecurrenceData,
    TimezoneName,
} from '../../requests';
import {
    AddProductForm,
    Capacity,
    CapacityOptionTypes,
    currencies,
    DurationValue,
    Pricing,
    Product,
    RecurrenceTypes,
    Schedule,
    ScheduleOptionTypes,
    Weekdays,
    AgebandName,
} from './product.types';

export const convertDurationToDurationDto = (d: DurationValue): TimeOffset => ({
    days: d.day || 0,
    hours: d.hour || 0,
    minutes: d.minute || 0,
});

export const convertDurationDtoToDuration = (
    d: Partial<TimeOffset>
): DurationValue => ({
    day: d.days || 0,
    hour: d.hours || 0,
    minute: d.minutes || 0,
});

export const convertDateToTimestamp = (d: Date): number =>
    Math.round(d.getTime() / 1000);

export const convertTimestampToDate = (t: number | undefined): Date =>
    t ? new Date(t * 1000) : new Date();

const weekdayToDayOfWeek: Record<Weekdays, DayOfWeek> = {
    [Weekdays.MONDAY]: DayOfWeek.MONDAY,
    [Weekdays.TUESDAY]: DayOfWeek.TUESDAY,
    [Weekdays.WEDNESDAY]: DayOfWeek.WEDNESDAY,
    [Weekdays.THURSDAY]: DayOfWeek.THURSDAY,
    [Weekdays.FRIDAY]: DayOfWeek.FRIDAY,
    [Weekdays.SATURDAY]: DayOfWeek.SATURDAY,
    [Weekdays.SUNDAY]: DayOfWeek.SUNDAY,
};

const dayOfWeekToWeekday: Record<DayOfWeek, Weekdays> = {
    [DayOfWeek.MONDAY]: Weekdays.MONDAY,
    [DayOfWeek.TUESDAY]: Weekdays.TUESDAY,
    [DayOfWeek.WEDNESDAY]: Weekdays.WEDNESDAY,
    [DayOfWeek.THURSDAY]: Weekdays.THURSDAY,
    [DayOfWeek.FRIDAY]: Weekdays.FRIDAY,
    [DayOfWeek.SATURDAY]: Weekdays.SATURDAY,
    [DayOfWeek.SUNDAY]: Weekdays.SUNDAY,
};

export const capacityToCapacityDto = (
    capacity: Capacity
): FlatCapacityData | AgebandCapacityData => ({
    name: capacity.name,
    ...(capacity.type === CapacityOptionTypes.AGE_BAND
        ? Object.values(AgebandName)
              .map(ageband => ({
                  ageband,
                  count: capacity.counts?.[ageband] || 0,
              }))
              .filter(({ count }) => count)
              .reduce(
                  (obj, { ageband, count }) => ({
                      ...obj,
                      [ageband]: count,
                  }),
                  {}
              )
        : {
              min_count: capacity.min,
              max_count: capacity.max,
          }),
});

export const pricingToPricingDto = (
    pricing: Pricing
): CreateProductReqFinancialsPricingOptionsItemDto => ({
    name: pricing.name,
    inclusions: pricing.inclusions?.map(({ value }) => value),
    capacity_option: pricing.capacity,
    type: pricing.type,
    price:
        pricing.type === PricingType.PER_TICKET
            ? ({
                  base_amount: pricing.price.base || undefined,
                  unit_amount: pricing.price.perPerson || undefined,
              } as FlatPricingData)
            : pricing.type === PricingType.PER_PRODUCT
            ? ({
                  base_amount: pricing.price.base || undefined,
                  unit_amount: pricing.price.perProduct || undefined,
              } as FlatPricingData)
            : ({
                  base_amount: pricing.price.base || undefined,
                  ...(pricing.price.counts || {}),
              } as AgebandPricingData),
    cost: pricing.cost
        ? pricing.type === PricingType.PER_TICKET
            ? ({
                  base_amount: pricing.cost.base || undefined,
                  unit_amount: pricing.cost.perPerson || undefined,
              } as FlatPricingData)
            : pricing.type === PricingType.PER_PRODUCT
            ? ({
                  base_amount: pricing.cost.base || undefined,
                  unit_amount: pricing.cost.perProduct || undefined,
              } as FlatPricingData)
            : ({
                  base_amount: pricing.cost.base || undefined,
                  ...(pricing.cost.counts || {}),
              } as AgebandPricingData)
        : undefined,
});

export const scheduleToScheduleDto = (
    schedule: Schedule
): SeasonData | CustomScheduleData => ({
    name: schedule.name,
    any_time: schedule.allDay,
    start_times: schedule.allDay
        ? undefined
        : schedule.times?.map(minutes => ({
              hours: Math.floor(minutes / 60),
              minutes: minutes % 60,
          })),
    ...(schedule.type === ScheduleOptionTypes.CUSTOM
        ? {
              start_dates: schedule.startDates.map(convertDateToTimestamp),
          }
        : {
              date_period: {
                  tz_name: Intl.DateTimeFormat().resolvedOptions()
                      .timeZone as TimezoneName,
                  start: convertDateToTimestamp(schedule.startDate),
                  end: convertDateToTimestamp(schedule.endDate),
              },
              recurrence:
                  schedule.recurrenceType === RecurrenceTypes.WEEKLY
                      ? {
                            enabled: true,
                            type: WeeklyRecurrenceData.type.WEEKLY,
                            days_of_week: schedule.weekDays?.map(
                                d => weekdayToDayOfWeek[d]
                            ) as DayOfWeek[],
                        }
                      : schedule.recurrenceType === RecurrenceTypes.MONTHLY
                      ? {
                            enabled: true,
                            type: MonthlyRecurrenceData.type.MONTHLY,
                            days_of_month: schedule.monthDays?.map(d =>
                                parseInt(format(d, 'd'), 10)
                            ) as number[],
                        }
                      : {
                            enabled: true,
                            type: YearlyRecurrenceData.type.YEARLY,
                            dates: schedule.dates?.map(
                                convertDateToTimestamp
                            ) as number[],
                        },
          }),
});

export const addProductFormToAddProductDto = (
    product: AddProductForm
): CreateProductReqDto => ({
    name: product.productInfo.name,
    type: product.type,
    description: product.productInfo.description,
    languages: product.productInfo?.languages as Language[],
    tags: [
        ...product.productInfo.interests.map(name => ({
            name,
            type: ProductTagType.INTEREST,
        })),
        ...product.productInfo.productCategories.map(name => ({
            name,
            type: ProductTagType.CATEGORY,
        })),
        ...product.productInfo.accessibility.map(name => ({
            name,
            type: ProductTagType.ACCESSIBILITY,
        })),
    ],
    route: {
        name: product.route.name,
        beginning_of_route: {
            name: product.route.beginningLocation,
        },
        end_of_route: {
            name: product.route.repeatLocation
                ? product.route.beginningLocation
                : (product.route.endingLocation as string),
        },
        round_trip: product.route.repeatLocation,
        stops: product.route.stops.map(stop => ({
            arrival_offset: convertDurationToDurationDto(stop.arrivalOffset),
            stop_duration: convertDurationToDurationDto(stop.duration),
            location: { name: stop.name },
        })),
    },
    vendibility: {
        concurrency: {
            auto: !!product.capacity.concurrencyAuto,
            enabled: !!product.capacity.concurrency,
        },
        upgrade: {
            auto: !!product.capacity.upgradeAuto,
            enabled: !!product.capacity.upgrade,
        },
        capacity: {
            options: product.capacity.capacities.map(capacityToCapacityDto),
        },
        schedule: {
            duration: convertDurationToDurationDto(product.schedule.duration),
            items: product.schedule.items.map(scheduleToScheduleDto),
        },
    },
    financials: {
        currency: {
            name:
                currencies.find(
                    ({ abbr }) => abbr === product.financial.currency
                )?.name || '',
            abbr: product.financial.currency,
        },
        payment_requirements: {
            methods: product.financial.paymentMethods,
            prepayment: {
                required: !!product.financial.prepayment,
                type: product.financial.prepaymentType,
                amount: product.financial.prepaymentAmount,
            },
        },
        pricing: { options: product.financial.items.map(pricingToPricingDto) },
    },
    options: product.options.items.map(o => ({
        name: o.name,
        type: o.optionType,
        description: o.description,
        financials:
            o.price && o.type
                ? {
                      pricing: pricingToPricingDto(o as Pricing),
                  }
                : undefined,
    })),
});

export const patchProductToAddProductDto = (
    product: Partial<AddProductForm>
): UpdateProductReqDto => ({
    name: product.productInfo?.name,
    description: product.productInfo?.description,
    languages: product.productInfo?.languages as Language[],
    tags: product.productInfo
        ? [
              ...product.productInfo.interests.map(name => ({
                  name,
                  type: ProductTagType.INTEREST,
              })),
              ...product.productInfo.productCategories.map(name => ({
                  name,
                  type: ProductTagType.CATEGORY,
              })),
              ...product.productInfo.accessibility.map(name => ({
                  name,
                  type: ProductTagType.ACCESSIBILITY,
              })),
          ]
        : undefined,
    route: product.route
        ? {
              name: product.route.name,
              beginning_of_route: {
                  name: product.route.beginningLocation,
              },
              end_of_route: {
                  name: product.route.repeatLocation
                      ? product.route.beginningLocation
                      : (product.route.endingLocation as string),
              },
              round_trip: product.route.repeatLocation,
              stops: product.route.stops.map(stop => ({
                  arrival_offset: convertDurationToDurationDto(
                      stop.arrivalOffset
                  ),
                  stop_duration: convertDurationToDurationDto(stop.duration),
                  location: { name: stop.name },
              })),
          }
        : undefined,
    vendibility: {
        concurrency: product.capacity
            ? {
                  auto: !!product.capacity.concurrencyAuto,
                  enabled: !!product.capacity.concurrency,
              }
            : undefined,
        upgrade: product.capacity
            ? {
                  auto: !!product.capacity.upgradeAuto,
                  enabled: !!product.capacity.upgrade,
              }
            : undefined,
        capacity: {
            options: product.capacity?.capacities.map(capacityToCapacityDto),
        },
        schedule: product.schedule
            ? {
                  duration: convertDurationToDurationDto(
                      product.schedule.duration
                  ),
                  items: product.schedule.items.map(scheduleToScheduleDto),
              }
            : undefined,
    },
    financials: product.financial
        ? {
              currency: {
                  name:
                      currencies.find(
                          ({ abbr }) => abbr === product.financial?.currency
                      )?.name || '',
                  abbr: product.financial.currency,
              },
              payment_requirements: {
                  methods: product.financial.paymentMethods,
                  prepayment: {
                      required: !!product.financial.prepayment,
                      type: product.financial.prepaymentType,
                      amount: product.financial.prepaymentAmount,
                  },
              },
              pricing: {
                  options: product.financial.items.map(pricingToPricingDto),
              },
          }
        : undefined,
    options: product.options
        ? product.options.items.map(o => ({
              name: o.name,
              type: o.optionType,
              description: o.description,
              financials:
                  o.price && o.type
                      ? {
                            pricing: pricingToPricingDto(o as Pricing),
                        }
                      : undefined,
          }))
        : undefined,
});

export const productToGetProductDto = (
    product: Product
): GetProductsResItem0Dto => {
    const { id, created, active, ...other } = product;

    return {
        id,
        active,
        created: created as GetProductsResItem0CreatedDto,
        ...addProductFormToAddProductDto(other),
    };
};

export const pricingDtoToPricing = ({
    price,
    cost,
    inclusions,
    type,
}: GetProductsResItem0OptionsItemFinancialsPricingDto):
    | Pricing
    | Omit<Pricing, 'name' | 'capacity'> => {
    if (type === PricingType.PER_AGEBAND) {
        const { base_amount: base, ...other } = price as AgebandPricingData;
        const { base_amount: baseCost, ...otherCost } = (cost ||
            {}) as AgebandPricingData;
        return {
            type: PricingType.PER_AGEBAND,
            price: {
                base,
                counts: other,
            },
            cost: cost
                ? {
                      base: baseCost,
                      counts: otherCost,
                  }
                : undefined,
            inclusions: inclusions?.map(value => ({ value })) || [],
        };
    }
    const { base_amount: base, unit_amount: unitAmount } =
        price as FlatPricingData;
    const { base_amount: baseCost, unit_amount: unitAmountCost } = (cost ||
        {}) as FlatPricingData;

    return {
        type,
        price: {
            base,
            perProduct: unitAmount,
            perPerson: unitAmount,
            counts: {},
        },
        cost: cost
            ? {
                  base: baseCost,
                  perProduct: unitAmountCost,
                  perPerson: unitAmountCost,
                  counts: {},
              }
            : undefined,
        inclusions: inclusions?.map(value => ({ value })) || [],
    };
};

export const getProductDtoToProduct = (
    product: GetProductsResItem0Dto
): Product => ({
    // @ts-ignore
    id: product.id,
    type: product.type,
    created: product.created,
    active: product.active,
    productInfo: {
        name: product.name,
        description: product.description || 'Test desc',
        languages: product.languages || [],
        interests:
            product.tags
                ?.filter(({ type }) => type === ProductTagType.INTEREST)
                .map(({ name }) => name) || [],
        productCategories:
            product.tags
                ?.filter(({ type }) => type === ProductTagType.CATEGORY)
                .map(({ name }) => name) || [],
        accessibility:
            product.tags
                ?.filter(({ type }) => type === ProductTagType.ACCESSIBILITY)
                .map(({ name }) => name) || [],
    },
    route: {
        name: product.route.name,
        beginningLocation: product.route.beginning_of_route.name,
        endingLocation: product.route.end_of_route.name,
        repeatLocation: product.route.round_trip,
        stops:
            product.route.stops?.map(stop => ({
                name: stop.location.name,
                arrivalOffset: convertDurationDtoToDuration(
                    stop.arrival_offset || {}
                ),
                duration: convertDurationDtoToDuration(
                    stop.stop_duration || {}
                ),
            })) || [],
    },
    schedule: {
        duration: convertDurationDtoToDuration(
            product.vendibility.schedule.duration
        ),
        items:
            product.vendibility.schedule.items?.map(item => ({
                name: item.name,
                allDay: item.any_time,
                times: (item.start_times || [])?.map(({ hours, minutes }) => {
                    return (minutes || 0) + hours * 60;
                }),
                ...((item as SeasonData)?.recurrence
                    ? {
                          type: ScheduleOptionTypes.SEASON,
                          recurrenceType: (item as SeasonData).recurrence
                              .type as unknown as RecurrenceTypes,
                          startDate: convertTimestampToDate(
                              (item as SeasonData).date_period.start
                          ),
                          endDate: convertTimestampToDate(
                              (item as SeasonData).date_period.end
                          ),
                          weekDays: (
                              (item as SeasonData)
                                  .recurrence as WeeklyRecurrenceData
                          ).days_of_week?.map(
                              (d: DayOfWeek) => dayOfWeekToWeekday[d]
                          ),
                          monthDays: (
                              (item as SeasonData)
                                  .recurrence as MonthlyRecurrenceData
                          ).days_of_month?.map(
                              (d: number) => new Date(`2023-01-${d}`)
                          ),
                          dates: (
                              (item as SeasonData)
                                  .recurrence as YearlyRecurrenceData
                          ).dates?.map(convertTimestampToDate),
                      }
                    : {
                          type: ScheduleOptionTypes.CUSTOM,
                          startDates: (
                              item as CustomScheduleData
                          ).start_dates?.map(convertTimestampToDate),
                      }),
            })) || [],
    },
    capacity: {
        concurrency: product.vendibility.concurrency?.enabled,
        concurrencyAuto: product.vendibility.concurrency?.auto,
        upgrade: product.vendibility.upgrade?.enabled,
        upgradeAuto: product.vendibility.upgrade?.auto,
        capacities: product.vendibility.capacity.options.map(capacity => {
            if (has(capacity, 'max_count')) {
                return {
                    name: capacity.name,
                    type: CapacityOptionTypes.FLAT,
                    min: (capacity as FlatCapacityData).min_count,
                    max: (capacity as FlatCapacityData).max_count,
                };
            }
            const { name, ...counts } = capacity as AgebandCapacityData;

            return {
                name,
                type: CapacityOptionTypes.AGE_BAND,
                counts,
            };
        }),
    },
    financial: {
        currency: product.financials.currency.abbr,
        items: product.financials.pricing.options.map(
            ({ price, cost, name, type, inclusions, capacity_option }) => {
                if (type === PricingType.PER_AGEBAND) {
                    const { base_amount: baseCost, ...costOther } = (cost ||
                        {}) as AgebandPricingData;
                    const { base_amount: base, ...other } =
                        price as AgebandPricingData;

                    return {
                        name: name || '',
                        type,
                        price: {
                            base,
                            counts:
                                type === PricingType.PER_AGEBAND
                                    ? other
                                    : undefined,
                        },
                        cost: cost
                            ? {
                                  base: baseCost,
                                  counts:
                                      type === PricingType.PER_AGEBAND
                                          ? costOther
                                          : undefined,
                              }
                            : undefined,
                        capacity: capacity_option,
                        inclusions: inclusions?.map(value => ({ value })) || [],
                    };
                }

                const { base_amount: baseCost, unit_amount: unitAmountCost } =
                    (cost || {}) as FlatPricingData;
                const { base_amount: base, unit_amount: unitAmount } =
                    price as FlatPricingData;

                return {
                    name: name || '',
                    type,
                    price: {
                        base,
                        perProduct: unitAmount,
                        perPerson: unitAmount,
                    },
                    cost: cost
                        ? {
                              base: baseCost,
                              perProduct: unitAmountCost,
                              perPerson: unitAmountCost,
                          }
                        : undefined,
                    capacity: capacity_option,
                    inclusions: inclusions?.map(value => ({ value })) || [],
                };
            }
        ),
        adaptivePricing: !!product.financials.pricing.options.find(
            p => p.capacity_option
        ),
        paymentMethods: product.financials.payment_requirements.methods,
        prepayment: product.financials.payment_requirements.prepayment.required,
        prepaymentAmount:
            product.financials.payment_requirements.prepayment.amount,
        prepaymentType: product.financials.payment_requirements.prepayment.type,
    },
    options: {
        items:
            product.options?.map(option => ({
                optionType: option.type,
                name: option.name,
                description: option.description,
                ...(option.financials?.pricing
                    ? pricingDtoToPricing(option.financials.pricing)
                    : {}),
            })) || [],
    },
});
