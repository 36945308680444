/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DiscountType {
    ABSOLUTE = 'absolute',
    RELATIVE = 'relative',
}
