import React from 'react';
import AuthLogin from '../views/auth-login';
import AuthRegister from '../views/auth-register';
// import ResetPassword from '../views/reset-password';
import AuthenticationGuard from './authentication-guard';

import Callback from '../views/callback';

export const AuthenticationRoutes = [
    {
        path: '/callback',
        element: <Callback />,
    },
    {
        path: '/login',
        element: <AuthLogin />,
    },
    {
        path: '/register',
        element: <AuthenticationGuard component={AuthRegister} />,
    },
    // {
    //     path: '/reset-password',
    //     element: <ResetPassword />,
    // },
];

export default AuthenticationRoutes;
