import { useAuth0 } from '@auth0/auth0-react';
import {
    useQuery,
    useMutation,
    UseQueryOptions,
    UseMutationOptions,
} from '@tanstack/react-query';
import {
    UpdateUserReqDto,
    UpdateTravelersBookingsReqDto,
    UpdateProductReqDto,
    UpdateOrderReqDto,
    UpdateCustomerReqDto,
    UpdateBookingReqDto,
    SetTravelersParticipationBookingsReqDto,
    RemoveTravelersBookingsReqDto,
    RegisterUsersReqDto,
    ProductType,
    GetEventsResItem1BookingsSummaryBookingsItemProductOptionsItemParticipantsDto,
    DiscardTransactionBookingsReqDto,
    CreateProductReqDto,
    CreateOrderReqDto,
    CreateEventReqDto,
    CreateCustomerReqDto,
    CreateBookingReqDto,
    ComputePriceBookingsReqDto,
    ComputeAvailabilityReqDto,
    AddTravelersBookingsReqDto,
    AddTransactionBookingsReqDto,
    UsersService,
    ProductsService,
    OrdersService,
    EventsService,
    CustomersService,
    BookingsService,
    AvailabilityService,
} from '../requests';

export const useRegisterUsers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.registerUsers>>,
            unknown,
            {
                requestBody: RegisterUsersReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.registerUsers(authorization, requestBody);
    }, options);
};
export const useGetUserKey = 'UsersServiceGetUser';
export const useGetUser = <TQueryKey extends Array<unknown> = unknown[]>(
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof UsersService.getUser>>,
            unknown,
            Awaited<ReturnType<typeof UsersService.getUser>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetUserKey, ...(queryKey ?? [])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.getUser(authorization);
        },
        options
    );
};
export const useUpdateUser = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateUser>>,
            unknown,
            {
                requestBody: UpdateUserReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.updateUser(authorization, requestBody);
    }, options);
};
export const useGetProductsKey = 'ProductsServiceGetProducts';
export const useGetProducts = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        type,
        name,
        startTs,
        endTs,
        currency,
        minPrice,
        maxPrice,
        location,
    }: {
        type?: ProductType;
        name?: string;
        startTs?: number;
        endTs?: number;
        currency?: string;
        minPrice?: number;
        maxPrice?: number;
        location?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetProductsKey,
            ...(queryKey ?? [
                {
                    type,
                    name,
                    startTs,
                    endTs,
                    currency,
                    minPrice,
                    maxPrice,
                    location,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.getProducts(
                authorization,
                type,
                name,
                startTs,
                endTs,
                currency,
                minPrice,
                maxPrice,
                location
            );
        },
        options
    );
};
export const useCreateProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.createProduct>>,
            unknown,
            {
                requestBody: CreateProductReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.createProduct(authorization, requestBody);
    }, options);
};
export const useDeleteProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.deleteProduct>>,
            unknown,
            {
                productId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.deleteProduct(productId, authorization);
    }, options);
};
export const useUpdateProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProductReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useGenerateNameProductsKey = 'ProductsServiceGenerateNameProducts';
export const useGenerateNameProducts = <
    TQueryKey extends Array<unknown> = unknown[]
>(
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.generateNameProducts>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.generateNameProducts>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGenerateNameProductsKey, ...(queryKey ?? [])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.generateNameProducts(authorization);
        },
        options
    );
};
export const useGetOrdersKey = 'OrdersServiceGetOrders';
export const useGetOrders = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        customerId,
        orderStatus,
    }: {
        customerId?: string;
        orderStatus?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetOrdersKey, ...(queryKey ?? [{ customerId, orderStatus }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(
                authorization,
                customerId,
                orderStatus
            );
        },
        options
    );
};
export const useCreateOrder = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.createOrder>>,
            unknown,
            {
                requestBody: CreateOrderReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.createOrder(authorization, requestBody);
    }, options);
};
export const useGetOrderKey = 'OrdersServiceGetOrder';
export const useGetOrder = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        orderId,
    }: {
        orderId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof OrdersService.getOrder>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.getOrder>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetOrderKey, ...(queryKey ?? [{ orderId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrder(orderId, authorization);
        },
        options
    );
};
export const useUpdateOrder = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.updateOrder>>,
            unknown,
            {
                orderId: string;
                requestBody: UpdateOrderReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ orderId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.updateOrder(orderId, authorization, requestBody);
    }, options);
};
export const useCancelOrders = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.cancelOrders>>,
            unknown,
            {
                orderId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ orderId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.cancelOrders(orderId, authorization);
    }, options);
};
export const useGetEventsKey = 'EventsServiceGetEvents';
export const useGetEvents = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        startTs,
        endTs,
        productId,
    }: {
        startTs?: number;
        endTs?: number;
        productId?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            unknown,
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetEventsKey, ...(queryKey ?? [{ startTs, endTs, productId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return EventsService.getEvents(
                authorization,
                startTs,
                endTs,
                productId
            );
        },
        options
    );
};
export const useCreateEvent = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.createEvent>>,
            unknown,
            {
                requestBody: CreateEventReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.createEvent(authorization, requestBody);
    }, options);
};
export const useGetCustomerKey = 'CustomersServiceGetCustomer';
export const useGetCustomer = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        name,
        email,
        phoneNumber,
    }: {
        name?: string;
        email?: string;
        phoneNumber?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            unknown,
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetCustomerKey, ...(queryKey ?? [{ name, email, phoneNumber }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer(
                authorization,
                name,
                email,
                phoneNumber
            );
        },
        options
    );
};
export const useCreateCustomer = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof CustomersService.createCustomer>>,
            unknown,
            {
                requestBody: CreateCustomerReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.createCustomer(authorization, requestBody);
    }, options);
};
export const useGetCustomer1Key = 'CustomersServiceGetCustomer1';
export const useGetCustomer1 = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        customerId,
    }: {
        customerId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CustomersService.getCustomer1>>,
            unknown,
            Awaited<ReturnType<typeof CustomersService.getCustomer1>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetCustomer1Key, ...(queryKey ?? [{ customerId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer1(customerId, authorization);
        },
        options
    );
};
export const useUpdateCustomer = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof CustomersService.updateCustomer>>,
            unknown,
            {
                customerId: string;
                requestBody: UpdateCustomerReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ customerId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.updateCustomer(
            customerId,
            authorization,
            requestBody
        );
    }, options);
};
export const useGetBookingsKey = 'BookingsServiceGetBookings';
export const useGetBookings = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        orderNumber,
        eventId,
        customerName,
        customerEmail,
        customerPhoneNumber,
        productType,
        productName,
        startTs,
        endTs,
        location,
    }: {
        orderNumber?: string;
        eventId?: string;
        customerName?: string;
        customerEmail?: string;
        customerPhoneNumber?: string;
        productType?: ProductType;
        productName?: string;
        startTs?: number;
        endTs?: number;
        location?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetBookingsKey,
            ...(queryKey ?? [
                {
                    orderNumber,
                    eventId,
                    customerName,
                    customerEmail,
                    customerPhoneNumber,
                    productType,
                    productName,
                    startTs,
                    endTs,
                    location,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBookings(
                authorization,
                orderNumber,
                eventId,
                customerName,
                customerEmail,
                customerPhoneNumber,
                productType,
                productName,
                startTs,
                endTs,
                location
            );
        },
        options
    );
};
export const useCreateBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.createBooking>>,
            unknown,
            {
                requestBody: CreateBookingReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.createBooking(authorization, requestBody);
    }, options);
};
export const useGetBookingKey = 'BookingsServiceGetBooking';
export const useGetBooking = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        bookingId,
    }: {
        bookingId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof BookingsService.getBooking>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.getBooking>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetBookingKey, ...(queryKey ?? [{ bookingId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBooking(bookingId, authorization);
        },
        options
    );
};
export const useUpdateBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking>>,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateBookingReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateBooking(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useComputePriceBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.computePriceBookings>>,
            unknown,
            {
                requestBody: ComputePriceBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.computePriceBookings(authorization, requestBody);
    }, options);
};
export const useCancelBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.cancelBookings>>,
            unknown,
            {
                bookingId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.cancelBookings(bookingId, authorization);
    }, options);
};
export const useAddTransactionBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.addTransactionBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: AddTransactionBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.addTransactionBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useDiscardTransactionBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.discardTransactionBookings>
            >,
            unknown,
            {
                bookingId: string;
                transactionId: string;
                requestBody: DiscardTransactionBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, transactionId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.discardTransactionBookings(
            bookingId,
            transactionId,
            authorization,
            requestBody
        );
    }, options);
};
export const useHoldAvailabilityBookingsKey =
    'BookingsServiceHoldAvailabilityBookings';
export const useHoldAvailabilityBookings = <
    TQueryKey extends Array<unknown> = unknown[]
>(
    {
        bookingId,
    }: {
        bookingId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<
                ReturnType<typeof BookingsService.holdAvailabilityBookings>
            >,
            unknown,
            Awaited<
                ReturnType<typeof BookingsService.holdAvailabilityBookings>
            >,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useHoldAvailabilityBookingsKey, ...(queryKey ?? [{ bookingId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.holdAvailabilityBookings(
                bookingId,
                authorization
            );
        },
        options
    );
};
export const useAddTravelersBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.addTravelersBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: AddTravelersBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.addTravelersBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateTravelersBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateTravelersBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateTravelersBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateTravelersBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useRemoveTravelersBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.removeTravelersBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: RemoveTravelersBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.removeTravelersBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useSetTravelersParticipationBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof BookingsService.setTravelersParticipationBookings
                >
            >,
            unknown,
            {
                bookingId: string;
                requestBody: SetTravelersParticipationBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.setTravelersParticipationBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateBooking1 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateBooking1>>,
            unknown,
            {
                bookingId: string;
                eventId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, eventId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateBooking1(
            bookingId,
            eventId,
            authorization
        );
    }, options);
};
export const useComputeAvailability = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof AvailabilityService.computeAvailability>>,
            unknown,
            {
                requestBody: ComputeAvailabilityReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return AvailabilityService.computeAvailability(
            authorization,
            requestBody
        );
    }, options);
};
