/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentStatus {
    PAID = 'paid',
    PARTIALLY_PAID = 'partially_paid',
    PENDING_PAYMENT = 'pending_payment',
    REFUNDED = 'refunded',
    PARTIALLY_REFUNDED = 'partially_refunded',
}
