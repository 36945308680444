import {
    ProductOptionType,
    Currency,
    PricingType,
    DiscountType,
    PaymentMethodData,
    Language,
    ProductType,
} from '../../requests';

export type {
    Currency,
    // AgebandName,
    // PricingType,
    PaymentMethodData,
};

export { PricingType, ProductOptionType, Language, ProductType, DiscountType };

export type DurationValue = Record<DurationUnits, number>;

enum DurationUnits {
    DAY = 'day',
    HOUR = 'hour',
    MINUTE = 'minute',
}

export enum AgebandName {
    INFANTS = 'infants',
    CHILDREN = 'children',
    YOUTH = 'youth',
    ADULTS = 'adults',
    SENIORS = 'seniors',
}

export interface ProductOption {
    name: string;
    type: ProductOptionType;
    description: string;
    capacity?: Partial<ProductCapacity>;
    schedule?: ProductSchedule[];
    financials?: {
        currency: Currency;
        cost?: {
            type: PricingType;
            amount: number;
            byAge: Record<AgebandName, number>;
        };
        price: {
            type: PricingType;
            amount: number;
            byAge: Record<AgebandName, number>;
        };
        paymentRequirements: {
            methods: string[];
            required: boolean;
            amount: number;
        };
    };
}

export interface ProductSchedule {
    dateStart: number;
    dateEnd?: number;
    timeRanges: {
        start: number;
        end?: number;
    }[];
}

export interface ProductLocation {
    name: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
}

export interface ProductCapacity {
    count: number;
    byAge?: Record<AgebandName, number>;
}

export interface ProductCreate {
    name: string;
    type: any;
    description: string;
    capacity: ProductCapacity;
    options: ProductOption[];
    schedule: ProductSchedule[];
    locations: ProductLocation[];
    financials: {
        currency: Currency;
        cost?: {
            type: PricingType;
            amount: number;
            byAge: Record<AgebandName, number>;
        };
        price: {
            type: PricingType;
            amount?: number;
            byAge?: Record<AgebandName, number>;
        };
        paymentRequirements: {
            methods: string[];
            required?: boolean;
            amount: number;
        };
    };
}

// export interface Product extends ProductCreate {
//     id: string;
// }

export enum AgeBands {
    INFANTS = 'infants',
    CHILDREN = 'children',
    YOUTH = 'youth',
    ADULTS = 'adults',
    SENIORS = 'seniors',
}

export enum CapacityOptionTypes {
    FLAT = 'flat',
    AGE_BAND = 'ageBand',
}

export enum FlatFields {
    MIN = 'min',
    MAX = 'max',
}

export interface Stop {
    name: string;
    arrivalOffset: DurationValue;
    duration: DurationValue;
    id?: string;
    isEdit?: boolean;
    isNew?: boolean;
}

export interface FlatCapacity {
    name?: string;
    type: CapacityOptionTypes.FLAT;
    min: number;
    max: number;
}

export interface AgebandCapacity {
    name?: string;
    type: CapacityOptionTypes.AGE_BAND;
    counts: Partial<Record<AgebandName, number>>;
}

export type Capacity = FlatCapacity | AgebandCapacity;

export enum RecurrenceTypes {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
}

export enum Weekdays {
    MONDAY = 'mon',
    TUESDAY = 'tue',
    WEDNESDAY = 'wed',
    THURSDAY = 'thu',
    FRIDAY = 'fri',
    SATURDAY = 'sat',
    SUNDAY = 'sun',
}

export interface SeasonSchedule {
    type: ScheduleOptionTypes.SEASON;
    name: string;
    startDate: Date;
    endDate: Date;
    recurrenceType: RecurrenceTypes;
    weekDays?: Weekdays[];
    monthDays?: Date[];
    dates?: Date[];
    allDay?: boolean;
    times?: number[];
}

export interface CustomSchedule {
    type: ScheduleOptionTypes.CUSTOM;
    name: string;
    startDates: Date[];
    allDay?: boolean;
    times: number[];
}

export enum ScheduleOptionTypes {
    SEASON = 'season',
    CUSTOM = 'custom',
}

export type Schedule = SeasonSchedule | CustomSchedule;

export interface Pricing {
    name: string;
    type: PricingType;
    capacity?: string;
    price: {
        base?: number;
        perProduct?: number;
        perPerson?: number;
        counts?: Partial<Record<AgebandName, number>>;
    };
    cost?: {
        base?: number;
        perProduct?: number;
        perPerson?: number;
        counts?: Partial<Record<AgebandName, number>>;
    };
    inclusions: { value: string }[];
}

export interface Option extends Partial<Omit<Pricing, 'name' | 'capacity'>> {
    name: string;
    optionType: ProductOptionType;
    description: string;
}

export interface ProductInfo {
    name: string;
    description: string;
    languages: string[];
    productCategories: string[];
    interests: string[];
    accessibility: string[];
}

export const currencies = [
    { name: 'United States dollar', abbr: 'USD' },
    { name: 'Euro', abbr: 'EUR' },
    { name: 'Russian ruble', abbr: 'RUB' },
    { name: 'Armenian dram', abbr: 'AMD' },
    { name: 'Afghan afghani', abbr: 'AFN' },
    { name: 'Albanian lek', abbr: 'ALL' },
    { name: 'Algerian dinar', abbr: 'DZD' },
    { name: 'Angolan kwanza', abbr: 'AOA' },
    { name: 'Argentine peso', abbr: 'ARS' },
    { name: 'Australian dollar', abbr: 'AUD' },
    { name: 'Azerbaijani manat', abbr: 'AZN' },
    { name: 'Bahamian dollar', abbr: 'BSD' },
    { name: 'Bahraini dinar', abbr: 'BHD' },
    { name: 'Bangladeshi taka', abbr: 'BDT' },
    { name: 'Barbadian dollar', abbr: 'BBD' },
    { name: 'Belarusian ruble', abbr: 'BYN' },
    { name: 'Belize dollar', abbr: 'BZD' },
    { name: 'Bhutanese ngultrum', abbr: 'BTN' },
    { name: 'Bolivian boliviano', abbr: 'BOB' },
    { name: 'Bosnia and Herzegovina convertible mark', abbr: 'BAM' },
    { name: 'Botswana pula', abbr: 'BWP' },
    { name: 'Brazilian real', abbr: 'BRL' },
    { name: 'British pound', abbr: 'GBP' },
    { name: 'Brunei dollar', abbr: 'BND' },
    { name: 'Bulgarian lev', abbr: 'BGN' },
    { name: 'Burmese kyat', abbr: 'MMK' },
    { name: 'Burundian franc', abbr: 'BIF' },
    { name: 'Cambodian riel', abbr: 'KHR' },
    { name: 'Canadian dollar', abbr: 'CAD' },
    { name: 'Cape Verdean escudo', abbr: 'CVE' },
    { name: 'Central African CFA franc', abbr: 'XAF' },
    { name: 'Chilean peso', abbr: 'CLP' },
    { name: 'Chinese yuan', abbr: 'CNY' },
    { name: 'Colombian peso', abbr: 'COP' },
    { name: 'Comorian franc', abbr: 'KMF' },
    { name: 'Congolese franc', abbr: 'CDF' },
    { name: 'Costa Rican colón', abbr: 'CRC' },
    { name: 'Cuban peso', abbr: 'CUP' },
    { name: 'Czech koruna', abbr: 'CZK' },
    { name: 'Danish krone', abbr: 'DKK' },
    { name: 'Djiboutian franc', abbr: 'DJF' },
    { name: 'Dominican peso', abbr: 'DOP' },
    { name: 'East Caribbean dollar', abbr: 'XCD' },
    { name: 'Egyptian pound', abbr: 'EGP' },
    { name: 'Eritrean nakfa', abbr: 'ERN' },
    { name: 'Ethiopian birr', abbr: 'ETB' },
    { name: 'Fijian dollar', abbr: 'FJD' },
    { name: 'Gambian dalasi', abbr: 'GMD' },
    { name: 'Georgian lari', abbr: 'GEL' },
    { name: 'Ghanaian cedi', abbr: 'GHS' },
    { name: 'Guatemalan quetzal', abbr: 'GTQ' },
    { name: 'Guinean franc', abbr: 'GNF' },
    { name: 'Guyanese dollar', abbr: 'GYD' },
    { name: 'Haitian gourde', abbr: 'HTG' },
    { name: 'Honduran lempira', abbr: 'HNL' },
    { name: 'Hungarian forint', abbr: 'HUF' },
    { name: 'Icelandic króna', abbr: 'ISK' },
    { name: 'Indian rupee', abbr: 'INR' },
    { name: 'Indonesian rupiah', abbr: 'IDR' },
    { name: 'Iranian rial', abbr: 'IRR' },
    { name: 'Iraqi dinar', abbr: 'IQD' },
    { name: 'Israeli new shekel', abbr: 'ILS' },
    { name: 'Jamaican dollar', abbr: 'JMD' },
    { name: 'Japanese yen', abbr: 'JPY' },
    { name: 'Jordanian dinar', abbr: 'JOD' },
    { name: 'Kazakhstani tenge', abbr: 'KZT' },
    { name: 'Kenyan shilling', abbr: 'KES' },
    { name: 'Kuwaiti dinar', abbr: 'KWD' },
    { name: 'Kyrgyzstani som', abbr: 'KGS' },
    { name: 'Lao kip', abbr: 'LAK' },
    { name: 'Lebanese pound', abbr: 'LBP' },
    { name: 'Lesotho loti', abbr: 'LSL' },
    { name: 'Liberian dollar', abbr: 'LRD' },
    { name: 'Libyan dinar', abbr: 'LYD' },
    { name: 'Macedonian denar', abbr: 'MKD' },
    { name: 'Malagasy ariary', abbr: 'MGA' },
    { name: 'Malawian kwacha', abbr: 'MWK' },
    { name: 'Malaysian ringgit', abbr: 'MYR' },
    { name: 'Maldivian rufiyaa', abbr: 'MVR' },
    { name: 'Mauritanian ouguiya', abbr: 'MRO' },
    { name: 'Mauritian rupee', abbr: 'MUR' },
    { name: 'Mexican peso', abbr: 'MXN' },
    { name: 'Moldovan leu', abbr: 'MDL' },
    { name: 'Mongolian tögrög', abbr: 'MNT' },
    { name: 'Moroccan dirham', abbr: 'MAD' },
    { name: 'Mozambican metical', abbr: 'MZN' },
    { name: 'Namibian dollar', abbr: 'NAD' },
    { name: 'Nepalese rupee', abbr: 'NPR' },
    { name: 'New Taiwan dollar', abbr: 'TWD' },
    { name: 'New Zealand dollar', abbr: 'NZD' },
    { name: 'Nicaraguan córdoba', abbr: 'NIO' },
    { name: 'Nigerian naira', abbr: 'NGN' },
    { name: 'North Korean won', abbr: 'KPW' },
    { name: 'Norwegian krone', abbr: 'NOK' },
    { name: 'Omani rial', abbr: 'OMR' },
    { name: 'Pakistani rupee', abbr: 'PKR' },
    { name: 'Panamanian balboa', abbr: 'PAB' },
    { name: 'Papua New Guinean kina', abbr: 'PGK' },
    { name: 'Paraguayan guaraní', abbr: 'PYG' },
    { name: 'Peruvian sol', abbr: 'PEN' },
    { name: 'Philippine peso', abbr: 'PHP' },
    { name: 'Polish zloty', abbr: 'PLN' },
    { name: 'Qatari riyal', abbr: 'QAR' },
    { name: 'Romanian leu', abbr: 'RON' },
    { name: 'Rwandan franc', abbr: 'RWF' },
    { name: 'Samoan tala', abbr: 'WST' },
    { name: 'Saudi riyal', abbr: 'SAR' },
    { name: 'Serbian dinar', abbr: 'RSD' },
    { name: 'Seychellois rupee', abbr: 'SCR' },
    { name: 'Sierra Leonean leone', abbr: 'SLL' },
    { name: 'Singapore dollar', abbr: 'SGD' },
    { name: 'Solomon Islands dollar', abbr: 'SBD' },
    { name: 'Somali shilling', abbr: 'SOS' },
    { name: 'South African rand', abbr: 'ZAR' },
    { name: 'South Korean won', abbr: 'KRW' },
    { name: 'South Sudanese pound', abbr: 'SSP' },
    { name: 'Sri Lankan rupee', abbr: 'LKR' },
    { name: 'Sudanese pound', abbr: 'SDG' },
    { name: 'Surinamese dollar', abbr: 'SRD' },
    { name: 'Swazi lilangeni', abbr: 'SZL' },
    { name: 'Swedish krona', abbr: 'SEK' },
    { name: 'Swiss franc', abbr: 'CHF' },
    { name: 'Syrian pound', abbr: 'SYP' },
    { name: 'São Tomé and Príncipe dobra', abbr: 'STD' },
    { name: 'Tajikistani somoni', abbr: 'TJS' },
    { name: 'Tanzanian shilling', abbr: 'TZS' },
    { name: 'Thai baht', abbr: 'THB' },
    { name: "Tongan pa'anga", abbr: 'TOP' },
    { name: 'Trinidad and Tobago dollar', abbr: 'TTD' },
    { name: 'Tunisian dinar', abbr: 'TND' },
    { name: 'Turkish lira', abbr: 'TRY' },
    { name: 'Turkmenistan manat', abbr: 'TMT' },
    { name: 'Ugandan shilling', abbr: 'UGX' },
    { name: 'Ukrainian hryvnia', abbr: 'UAH' },
    { name: 'United Arab Emirates dirham', abbr: 'AED' },
    { name: 'Uruguayan peso', abbr: 'UYU' },
    { name: 'Uzbekistani som', abbr: 'UZS' },
    { name: 'Vanuatu vatu', abbr: 'VUV' },
    { name: 'Venezuelan bolívar', abbr: 'VEF' },
    { name: 'Vietnamese dong', abbr: 'VND' },
    { name: 'West African CFA franc', abbr: 'XOF' },
    { name: 'Yemeni rial', abbr: 'YER' },
    { name: 'Zambian kwacha', abbr: 'ZMW' },
];

export interface ProductRoute {
    name: string;
    beginningLocation: string;
    endingLocation?: string;
    repeatLocation?: boolean;
    stops: Stop[];
}

export interface AddProductForm {
    type: ProductType;
    productInfo: ProductInfo;
    route: ProductRoute;
    schedule: {
        duration: DurationValue;
        items: Schedule[];
    };
    capacity: {
        concurrency?: boolean;
        concurrencyAuto?: boolean;
        upgrade?: boolean;
        upgradeAuto?: boolean;
        capacities: Capacity[];
    };
    financial: {
        currency: string;
        items: Pricing[];
        adaptivePricing: boolean;
        paymentMethods: PaymentMethodData[];
        prepayment?: boolean;
        prepaymentAmount?: number;
        prepaymentType?: DiscountType;
    };
    options: {
        items: Option[];
    };
}

export interface Product extends AddProductForm {
    id: string;
    created: any;
    active: boolean;
}
