import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useMemo, useState } from 'react';
import {
    BookingsService,
    BookingStatus,
    ComputePriceBookingsReqDto,
    CreateBookingReqFinancialsDto,
    CreateEventReqOperationsDto,
    EventsService,
    GetOrdersResDto,
    OrdersService,
    OrderStatus,
    ProductOptionType,
    ProductType,
} from '../../requests';
import { AvailableEvent } from '../availability/availability.types';
import {
    useComputePriceBookings,
    useGetOrder,
    useGetOrders,
} from '../../queries';
import {
    getOrderDtoToOrder,
    getOrderItemDtoToOrder,
    orderToGetOrderItemDto,
    PriceDtoToPriceSummary,
} from './order.converters';
import { OrderItem, PriceSummary } from './order.types';
import { Customer } from '../customer/customer.types';
import { customerToCustomerDto } from '../customer/customer.converters';

export const useCreateBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.createBooking> | undefined
            >,
            unknown,
            {
                customer: Customer;
                productOptions: { name: string; type: ProductOptionType }[];
                event: AvailableEvent;
                isDraft?: boolean;
                notes: string[];
            }
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    return useMutation(
        async ({ customer, event, isDraft, productOptions, notes }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const openOrders = await OrdersService.getOrders(
                authorization,
                customer.id
            );
            let openOrder;
            if (openOrders.items?.length) {
                [openOrder] = openOrders.items;
            } else {
                const order = {
                    participants: {
                        customer: customerToCustomerDto(customer),
                    },
                };
                openOrder = await OrdersService.createOrder(
                    authorization,
                    // @ts-ignore
                    order
                );
            }

            const booking = event.rawDto.bookings_summary?.bookings[0];
            if (!booking) throw new Error();
            let eventId = event.id;
            if (booking?.product.type !== ProductType.GROUP_TOUR || !event.id) {
                if (booking) {
                    const newEvent = await EventsService.createEvent(
                        authorization,
                        {
                            date: event.rawDto.date,
                            operations: event.rawDto
                                .operations as CreateEventReqOperationsDto,
                            product: booking.product,
                            status: event.rawDto.status,
                            // @ts-ignore
                            ephemeral: event.rawDto.ephemeral,
                        }
                    );
                    eventId = newEvent.id;
                }
            }

            const newBooking = await BookingsService.createBooking(
                authorization,
                {
                    event: {
                        id: eventId,
                    },
                    product: {
                        id: booking.product.id,
                        options: productOptions,
                    },
                    order: {
                        id: openOrder.id,
                    },
                    participants: {
                        pax: booking?.participants.pax,
                    },
                    // date: booking.date,
                    financials:
                        booking.financials as CreateBookingReqFinancialsDto,
                    notes,
                    status: isDraft ? BookingStatus.DRAFT : BookingStatus.HOLD,
                }
            );

            queryClient.invalidateQueries({
                queryKey: ['OrdersServiceGetOrders', {}],
                exact: true,
                refetchType: 'active',
            });

            return newBooking;
        },
        options
    );
};

export const useDebouncedPrice = (
    params: Partial<ComputePriceBookingsReqDto>,
    debounce: number = 1000
) => {
    const [state, setState] = useState<{
        data?: PriceSummary;
        isLoading: boolean;
    }>({ isLoading: true });
    const { mutate: getPrices } = useComputePriceBookings({
        onSuccess: data => {
            setState({
                data: PriceDtoToPriceSummary(data.financials.price),
                isLoading: false,
            });
        },
    });

    React.useEffect(() => {
        setState({ isLoading: true });
        const handler = setTimeout(() => {
            if (params.participants?.pax && params.product) {
                getPrices({
                    requestBody: params as ComputePriceBookingsReqDto,
                });
            }
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [params, debounce]);

    return {
        data: state.data,
        isLoading: state.isLoading,
    };
};

type UseGetOrdersParamTypes = Parameters<typeof useGetOrders>;
export const useOrders = (params: UseGetOrdersParamTypes[0] = {}) => {
    const queryKey = ['OrdersServiceGetOrders', params];
    const { data, ...other } = useGetOrders(params, [params]);

    const queryClient = useQueryClient();
    const update = (orderId: string, order: Partial<OrderItem>) => {
        // @ts-ignore
        queryClient.setQueryData<GetOrdersResDto>(queryKey, orders => {
            if (!orders?.items) return undefined;
            const items = orders.items.map(o =>
                o.id === orderId
                    ? orderToGetOrderItemDto({ ...o, ...order } as OrderItem)
                    : o
            );
            return { ...orders, items };
        });
    };

    const parsedData = useMemo(
        () => data?.items?.map(getOrderItemDtoToOrder),
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

export const useOrder = (orderId?: string) => {
    const queryKey = ['OrdersServiceGetOrder', orderId];
    const { data, ...other } = useGetOrder(
        useMemo(() => ({ orderId: orderId as string }), [orderId]),
        queryKey,
        { enabled: !!orderId }
    );

    const parsedData = useMemo(
        () => (data ? getOrderDtoToOrder(data) : data),
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useDeleteOrder = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.updateOrder>>,
            unknown,
            { orderId: string },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ orderId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.updateOrder(orderId, authorization, {
            status: OrderStatus.CANCELLED,
        });
    }, options);
};
