import React from 'react';
import { Alert, Box, IconButton, Stack, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CloseIcon from '@mui/icons-material/Close';
import { PageTitle } from '../../../components/page-title';
import { useStepperContext } from '../../../components/stepper/stepper.provider';
import { useHelperContext } from '../../../contexts/helper-context';

export interface StepContentProps {
    children: React.ReactElement;
    title: string;
    onClick?: (e: React.MouseEvent) => void;
    errors: string[];
}

const StepContent: React.FC<StepContentProps> = ({
    children,
    title,
    onClick,
    errors,
}) => {
    const { prev, activeIndex } = useStepperContext();
    const navigate = useNavigate();

    const { info, close } = useHelperContext();

    return (
        <>
            <PageTitle
                buttons={
                    <Stack direction="row" spacing={1}>
                        <Button
                            color="neutral"
                            variant="contained"
                            onClick={() =>
                                activeIndex ? prev() : navigate(-1)
                            }
                        >
                            {activeIndex ? 'Back' : 'Cancel'}
                        </Button>

                        <Button
                            color="secondary"
                            variant="contained"
                            type="submit"
                            form="step"
                            disabled={!!errors.length}
                            sx={{ '&&&': { pointerEvents: 'initial' } }}
                            onClick={onClick}
                            endIcon={
                                errors.length ? (
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    borderRadius: '8px',
                                                },
                                            },
                                        }}
                                        title={
                                            <>
                                                <Box>
                                                    The following info is
                                                    required:
                                                </Box>
                                                {errors.map(error => (
                                                    <li>{error}</li>
                                                ))}
                                            </>
                                        }
                                    >
                                        <InfoIcon sx={{ color: '#55B5CF' }} />
                                    </Tooltip>
                                ) : null
                            }
                        >
                            {activeIndex < 6 ? 'Next' : 'Save'}
                        </Button>
                    </Stack>
                }
            >
                {title}
            </PageTitle>
            <Box sx={{ height: 'calc(100% - 60px)' }}>
                <PerfectScrollbar>
                    <Box sx={{ pt: 1, pr: 1 }}>{children}</Box>
                </PerfectScrollbar>
            </Box>

            {info && (
                <Alert
                    variant="outlined"
                    severity="info"
                    icon={<TipsAndUpdatesIcon sx={{ color: '#EECC52' }} />}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={close}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{
                        width: '416px',
                        position: 'absolute',
                        right: '30px',
                        top: '74px',
                        bgcolor: '#FFF',
                    }}
                >
                    {info}
                </Alert>
            )}
        </>
    );
};

export default React.memo(StepContent);
