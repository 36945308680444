import React from 'react';
import { Chip, Stack } from '@mui/material';
import { CreatableTags } from '@travelity/form';
import { useFormContext } from 'react-hook-form';

export interface BookingNotesFormProps {
    preview?: boolean;
}

const BookingNotesForm: React.FC<BookingNotesFormProps> = ({ preview }) => {
    const { watch } = useFormContext();

    return !preview ? (
        <Stack spacing={2}>
            <CreatableTags
                name="notes"
                inputProps={{
                    label: 'Notes',
                    placeholder: 'Type and press enter to add note',
                }}
                chipDirection="column"
                chipSx={{
                    borderRadius: '12px',
                    padding: '8px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '10px',
                    bgcolor: '#F4F6FA',
                    '& > .MuiChip-label': {
                        color: '#2B395B',
                        fontSize: '12px',
                        textWrap: 'wrap',
                    },
                }}
            />
        </Stack>
    ) : (
        <Stack sx={{ py: '3px', ml: '-2px' }} gap={1}>
            {watch('notes')?.map((field: any) => (
                <Chip
                    key={field.id}
                    label={field.value}
                    size="small"
                    sx={{
                        borderRadius: '12px',
                        padding: '8px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '10px',
                        bgcolor: '#F4F6FA',
                        '& > .MuiChip-label': {
                            color: '#2B395B',
                            fontSize: '12px',
                            textWrap: 'wrap',
                        },
                    }}
                    color="primary"
                    variant="filled"
                />
            ))}
        </Stack>
    );
};

export default React.memo(BookingNotesForm);
