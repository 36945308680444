import {
    Country,
    GetBookingsResItemParticipantsDto,
    GetBookingsResItemParticipantsTravelersItemDto,
    Nationality,
} from '@travelity/api/src/requests';
import { format } from 'date-fns';
import {
    IBookingParticipantsForm,
    TravelerForm,
} from './booking-participants.types';

export const stringToDate = (str: string): Date => {
    const [d, m, y] = str.split('.');
    if (!d || !m || !y) return new Date();

    return new Date(parseInt(y, 10), parseInt(m, 10), parseInt(d, 10));
};

export const travelerFormToTraveler = (
    traveler: TravelerForm
): Omit<GetBookingsResItemParticipantsTravelersItemDto, 'booking_id' | 'id'> &
    Partial<Pick<GetBookingsResItemParticipantsTravelersItemDto, 'id'>> => ({
    id: traveler.travelerId,
    name: {
        first: traveler.name.split(' ')[0],
        last: traveler.name.split(' ')[1],
    },
    contact_details: {
        emails: traveler.emails?.map(({ value }) => value),
        phone_numbers: traveler.numbers
            ?.map(({ value }) => value)
            .map(number => ({
                calling_code: parseInt(number.split(' ')[0], 10),
                number: parseInt(number.split(' ')[1], 10),
            })),
    },
    birth_details: traveler.birthDate
        ? {
              nationality:
                  (traveler.nationality?.toLowerCase() as Nationality) ||
                  undefined,
              date: Math.round(
                  stringToDate(traveler.birthDate).getTime() / 1000
              ),
              place: traveler.birthPlace
                  ? {
                        name: traveler.birthPlace,
                    }
                  : undefined,
          }
        : undefined,
    passport: traveler.passportN
        ? {
              number: traveler.passportN,
              citizenship:
                  (traveler.citizenship?.toLowerCase() as Country) || undefined,
              authority: traveler.issuedBy,
              issued_at: traveler.issuedAt
                  ? Math.round(stringToDate(traveler.issuedAt).getTime() / 1000)
                  : undefined,
              expires_at: traveler.expiresAt
                  ? Math.round(
                        stringToDate(traveler.expiresAt).getTime() / 1000
                    )
                  : undefined,
          }
        : undefined,
});

export const participantsFormToParticipants = (
    participants: IBookingParticipantsForm
): GetBookingsResItemParticipantsDto => ({
    // pax: participants.pax,
    // customer: participants.customer,
    // @ts-ignore
    travelers: participants.travelers.map(travelerFormToTraveler),
});

export const travelerToTravelerForm = (
    traveler: Omit<GetBookingsResItemParticipantsTravelersItemDto, 'booking_id'>
): TravelerForm => ({
    travelerId: traveler.id,
    name: `${traveler.name.first} ${traveler.name.last}`,
    emails: traveler.contact_details?.emails?.map(value => ({ value })) || [],
    numbers:
        traveler.contact_details?.phone_numbers?.map(
            ({ number, calling_code }) => ({
                value: `${calling_code} ${number}`,
            })
        ) || [],
    birthDate: traveler.birth_details?.date
        ? format(new Date(traveler.birth_details.date * 1000), 'dd.MM.yyyy')
        : undefined,
    birthPlace: traveler.birth_details?.place?.name,
    nationality: traveler.birth_details?.nationality,
    passportN: traveler.passport?.number,
    issuedBy: traveler.passport?.authority,
    issuedAt: traveler.passport?.issued_at
        ? format(new Date(traveler.passport.issued_at * 1000), 'dd.MM.yyyy')
        : undefined,
    expiresAt: traveler.passport?.expires_at
        ? format(new Date(traveler.passport.expires_at * 1000), 'dd.MM.yyyy')
        : undefined,
    citizenship: traveler.passport?.citizenship,
    type: 'traveler',
});

export const participantsToParticipantsForm = (
    participants: GetBookingsResItemParticipantsDto
): IBookingParticipantsForm => ({
    pax: participants.pax,
    customer: participants.customer,
    travelers: participants.travelers?.map(travelerToTravelerForm) || [],
});
