import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ReactComponent as Logo } from '../../../assets/travelity-logo.svg';

function LogoHeader() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                ml: '8px',
                display: 'flex',
                height: '60px',
                width: 1,
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                    width: 'auto',
                },
            }}
        >
            <Box
                sx={{
                    width: 180,
                }}
            >
                <Logo
                    style={{
                        height: '60px',
                        width: '140px',
                    }}
                />
            </Box>
        </Box>
    );
}

export default LogoHeader;
