import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { UserOccupation } from '@travelity/api';

import { ReactComponent as GuideIcon } from '../assets/guide.svg';
import { ReactComponent as DriverIcon } from '../assets/driver.svg';
import { ReactComponent as TourAgentIcon } from '../assets/tour-agent.svg';

export interface OccupationSelectProps {
    name: string;
}

const occupations = [
    {
        label: 'Guide',
        value: UserOccupation.GUIDE,
        description: 'Guide description',
        icon: GuideIcon,
    },
    {
        label: 'Driver',
        value: UserOccupation.DRIVER,
        description: 'Driver description',
        icon: DriverIcon,
    },
    {
        label: 'Tour Agent',
        value: UserOccupation.TOUR_AGENT,
        description: 'Tour Agent description',
        icon: TourAgentIcon,
    },
];

const OccupationSelect: React.FC<OccupationSelectProps> = ({ name }) => {
    const { control } = useFormContext();
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
    });

    return (
        <Stack
            direction="row"
            justifyContent="center"
            gap={1}
            sx={{ py: '3px', px: 1 }}
        >
            {occupations.map(option => (
                <Stack
                    key={option.value}
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    sx={{
                        width: '240px',
                        height: '216px',
                        borderRadius: '8px',
                        bgcolor: '#F4F6FA',
                        cursor: 'pointer',
                        border:
                            value === option.value
                                ? '2px solid #55B5CF'
                                : '2px solid #F4F6FA',
                    }}
                    onClick={() => onChange(option.value)}
                >
                    <Box sx={{ m: 2 }} component={option.icon} />
                    <Box
                        sx={{
                            color: '#2B395B',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        {option.label}
                    </Box>
                    <Box
                        sx={{
                            color: '#2B395B',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '100%',
                            opacity: '0.5',
                        }}
                    >
                        {option.description}
                    </Box>
                </Stack>
            ))}
        </Stack>
    );
};

export default OccupationSelect;
