import React from 'react';
import { Divider, Paper, Stack } from '@mui/material';
import { Switch, TextArea } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { FinancingCardContent } from '../../../product-financing-form/components/financing-card';
import { ReadonlyTextarea } from '../../../readonly-textarea';

export interface OptionCardContentProps {
    item: Record<string, any>;
    editing: boolean;
    currency: string;
}

const OptionCardContent: React.FC<OptionCardContentProps> = props => {
    const { item, editing, currency } = props;
    const { watch } = useFormContext();

    return (
        <Stack sx={{ p: 2 }} gap={1}>
            {editing ? (
                <TextArea
                    name="description"
                    fullWidth
                    label="Description"
                    placeholder="Type Description"
                    showErrors
                    helperText=""
                />
            ) : (
                <ReadonlyTextarea content={item.description} />
            )}

            {editing && (
                <>
                    <Paper sx={{ py: 1, px: 2, maxWidth: '388px' }}>
                        <Switch
                            name="hasPricing"
                            label="Has Pricing?"
                            LabelProps={{ sx: { color: '#2B395B' } }}
                        />
                    </Paper>
                    {watch('hasPricing') && (
                        <FinancingCardContent
                            item={item}
                            editing
                            currency={currency}
                            sx={{ p: 0 }}
                        />
                    )}
                </>
            )}

            {!editing && item.hasPricing && (
                <>
                    <Divider sx={{ mt: 2 }}>Pricing</Divider>
                    <FinancingCardContent
                        item={item}
                        editing={false}
                        currency={currency}
                        sx={{ p: 0 }}
                    />
                </>
            )}
        </Stack>
    );
};

export default OptionCardContent;
