import React, { useMemo } from 'react';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    useOrder,
    useOrders,
} from '@travelity/api/src/services/order/order.hooks';
import { OrderItem } from '../../components/order-item';

import { ReactComponent as PreviewSvg } from '../products/assets/preview.svg';
// import { ReactComponent as LoopSvg } from '../../assets/loop.svg';
import OrderEditForm from './components/order-preview';
import { OrderItemSkeleton } from '../../components/order-item-skeleton';

export interface OrdersProps {}

function Orders() {
    // const { t } = useTranslation('order');
    const { orderId } = useParams();

    const {
        data: orders,
        isLoading,
        update: updateOrder,
        refetch,
    } = useOrders(useMemo(() => ({}), []));
    const { data: order, isLoading: isOrderLoading } = useOrder(orderId);

    return (
        <Stack
            direction="row"
            sx={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }}
        >
            <Box
                sx={{
                    bgcolor: '#FFF',
                    flexGrow: 2,
                    ml: 2,
                    my: 2,
                    px: 2,
                    height: 'calc(100% - 32px)',
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        py: 1.5,
                        height: '72px',
                    }}
                >
                    <Typography variant="h5" sx={{ color: '#C0C4CE' }}>
                        All Orders
                    </Typography>
                </Stack>
                {/* <Box> */}
                {/*    <Divider> */}
                {/*        <Filters /> */}
                {/*    </Divider> */}
                {/* </Box> */}
                {orders && (
                    <>
                        {orders.length > 1 && (
                            <Divider>{orders.length} orders found</Divider>
                        )}
                        {orders.length === 1 && (
                            <Divider>1 order found</Divider>
                        )}
                        {orders.length === 0 && (
                            <Divider>No orders found</Divider>
                        )}
                        <Box
                            sx={{
                                py: 1,
                                bgcolor: orders.length ? '#F8F9FA' : undefined,
                                height: 'calc(100% - 72px - 21px)',
                                '& > div': {
                                    py: 1,
                                },
                            }}
                        >
                            <PerfectScrollbar>
                                {orders.length ? (
                                    <Stack
                                        sx={{
                                            px: 2,
                                        }}
                                        gap={1}
                                    >
                                        {orders.map(o => (
                                            <OrderItem
                                                key={o.id}
                                                order={o}
                                                refetch={refetch}
                                            />
                                        ))}
                                    </Stack>
                                ) : (
                                    <Stack
                                        sx={{
                                            display: 'flex',
                                            height: 1,
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography sx={{ color: '#949BAC' }}>
                                            Filter results will be here
                                        </Typography>
                                    </Stack>
                                )}
                            </PerfectScrollbar>
                        </Box>
                    </>
                )}
                {isLoading && (
                    <>
                        <Divider>
                            <Skeleton width={100} />
                        </Divider>

                        <Box
                            sx={{
                                py: 2,
                                bgcolor: '#F8F9FA',
                                height: 'calc(100% - 72px - 21px)',
                            }}
                        >
                            <Stack
                                sx={{
                                    px: 2,
                                }}
                                gap={1}
                            >
                                {[0, 1, 2].map(v => (
                                    <OrderItemSkeleton key={v} />
                                ))}
                            </Stack>
                        </Box>
                    </>
                )}
            </Box>
            <Box
                sx={{
                    bgcolor: '#FFF',
                    my: 2,
                    ml: 2,
                    width: '483px',
                    height: 'calc(100% - 32px)',
                    ...(!orderId
                        ? {
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 2,
                          }
                        : {}),
                }}
            >
                {!orderId && (
                    <>
                        <PreviewSvg />
                        <Typography sx={{ color: '#949BAC' }}>
                            No order is selected for preview
                        </Typography>
                    </>
                )}
                {order && (
                    <OrderEditForm
                        order={order}
                        updateOrder={updateOrder}
                        isLoading={isOrderLoading}
                    />
                )}
            </Box>
        </Stack>
    );
}

export default Orders;
