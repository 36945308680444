/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PhoneNumberType {
    HOME = 'home',
    WORK = 'work',
    MOBILE = 'mobile',
}
