import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetBookings } from '../../queries';
import { Booking } from './booking.types';
import { BookingsService, GetBookingsResDto } from '../../requests';
import {
    getBookingDtoToBooking,
    bookingToGetBookingDto,
} from './booking.converters';

type UseGetBookingsParamTypes = Parameters<typeof useGetBookings>;
export const useBookings = (params: UseGetBookingsParamTypes[0] = {}) => {
    const queryKey = ['BookingsServiceGetBookings', params];
    const { data, ...other } = useGetBookings(params, [params]);

    const queryClient = useQueryClient();
    const update = (bookingId: string, booking: Booking) => {
        queryClient.setQueryData<GetBookingsResDto>(queryKey, bookings => {
            if (!bookings?.items) return undefined;
            const items = bookings.items.map(b =>
                b.id === bookingId
                    ? { ...b, ...bookingToGetBookingDto(booking) }
                    : b
            );
            return { ...bookings, items };
        });
    };

    const parsedData = useMemo(
        () => data?.items?.map(getBookingDtoToBooking),
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

export const useHoldBookingAvailability = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.holdAvailabilityBookings>
            >,
            unknown,
            { bookingId: string },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.holdAvailabilityBookings(
            bookingId,
            authorization
        );
    }, options);
};
