/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type YearlyRecurrenceData = {
    /**
     * Type of the recurrence. In case it is enabled
     */
    type: YearlyRecurrenceData.type;
    /**
     * indicates whether the schedule is recurring or not
     */
    enabled: boolean;
    /**
     * A list of timestamps of dates when the event happens every month
     */
    dates: Array<number>;
};

export namespace YearlyRecurrenceData {

    /**
     * Type of the recurrence. In case it is enabled
     */
    export enum type {
        YEARLY = 'yearly',
    }


}
