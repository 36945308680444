/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Country {
    ALBANIA = 'albania',
    ALGERIA = 'algeria',
    ANDORRA = 'andorra',
    ANGOLA = 'angola',
    ANTIGUA_AND_BARBUDA = 'antigua and barbuda',
    ARGENTINA = 'argentina',
    ARMENIA = 'armenia',
    AUSTRALIA = 'australia',
    AUSTRIA = 'austria',
    AZERBAIJAN = 'azerbaijan',
    BAHAMAS = 'bahamas',
    BAHRAIN = 'bahrain',
    BANGLADESH = 'bangladesh',
    BARBADOS = 'barbados',
    BELARUS = 'belarus',
    BELGIUM = 'belgium',
    BELIZE = 'belize',
    BENIN = 'benin',
    BHUTAN = 'bhutan',
    BOLIVIA = 'bolivia',
    BOSNIA_AND_HERZEGOVINA = 'bosnia and herzegovina',
    BOTSWANA = 'botswana',
    BRAZIL = 'brazil',
    BRUNEI = 'brunei',
    BULGARIA = 'bulgaria',
    BURKINA_FASO = 'burkina faso',
    BURUNDI = 'burundi',
    C_TE_D_IVOIRE = 'côte d_ivoire',
    CABO_VERDE = 'cabo verde',
    CAMBODIA = 'cambodia',
    CAMEROON = 'cameroon',
    CANADA = 'canada',
    CENTRAL_AFRICAN_REPUBLIC = 'central african republic',
    CHAD = 'chad',
    CHILE = 'chile',
    CHINA = 'china',
    COLOMBIA = 'colombia',
    COMOROS = 'comoros',
    CONGO_CONGO_BRAZZAVILLE_ = 'congo (congo-brazzaville)',
    COSTA_RICA = 'costa rica',
    CROATIA = 'croatia',
    CUBA = 'cuba',
    CYPRUS = 'cyprus',
    CZECH_REPUBLIC = 'czech republic',
    DEMOCRATIC_REPUBLIC_OF_THE_CONGO = 'democratic republic of the congo',
    DENMARK = 'denmark',
    DJIBOUTI = 'djibouti',
    DOMINICA = 'dominica',
    DOMINICAN_REPUBLIC = 'dominican republic',
    ECUADOR = 'ecuador',
    EGYPT = 'egypt',
    EL_SALVADOR = 'el salvador',
    EQUATORIAL_GUINEA = 'equatorial guinea',
    ERITREA = 'eritrea',
    ESTONIA = 'estonia',
    ESWATINI = 'eswatini',
    ETHIOPIA = 'ethiopia',
    FIJI = 'fiji',
    FINLAND = 'finland',
    FRANCE = 'france',
    GABON = 'gabon',
    GAMBIA = 'gambia',
    GEORGIA = 'georgia',
    GERMANY = 'germany',
    GHANA = 'ghana',
    GREECE = 'greece',
    GRENADA = 'grenada',
    GUATEMALA = 'guatemala',
    GUINEA = 'guinea',
    GUINEA_BISSAU = 'guinea-bissau',
    GUYANA = 'guyana',
    HAITI = 'haiti',
    HOLY_SEE = 'holy see',
    HONDURAS = 'honduras',
    HUNGARY = 'hungary',
    ICELAND = 'iceland',
    INDIA = 'india',
    INDONESIA = 'indonesia',
    IRAN = 'iran',
    IRAQ = 'iraq',
    IRELAND = 'ireland',
    ISRAEL = 'israel',
    ITALY = 'italy',
    JAMAICA = 'jamaica',
    JAPAN = 'japan',
    JORDAN = 'jordan',
    KAZAKHSTAN = 'kazakhstan',
    KENYA = 'kenya',
    KIRIBATI = 'kiribati',
    KUWAIT = 'kuwait',
    KYRGYZSTAN = 'kyrgyzstan',
    LAOS = 'laos',
    LATVIA = 'latvia',
    LEBANON = 'lebanon',
    LESOTHO = 'lesotho',
    LIBERIA = 'liberia',
    LIBYA = 'libya',
    LIECHTENSTEIN = 'liechtenstein',
    LITHUANIA = 'lithuania',
    LUXEMBOURG = 'luxembourg',
    MADAGASCAR = 'madagascar',
    MALAWI = 'malawi',
    MALAYSIA = 'malaysia',
    MALDIVES = 'maldives',
    MALI = 'mali',
    MALTA = 'malta',
    MARSHALL_ISLANDS = 'marshall islands',
    MAURITANIA = 'mauritania',
    MAURITIUS = 'mauritius',
    MEXICO = 'mexico',
    MICRONESIA = 'micronesia',
    MOLDOVA = 'moldova',
    MONACO = 'monaco',
    MONGOLIA = 'mongolia',
    MONTENEGRO = 'montenegro',
    MOROCCO = 'morocco',
    MOZAMBIQUE = 'mozambique',
    MYANMAR = 'myanmar',
    NAMIBIA = 'namibia',
    NAURU = 'nauru',
    NEPAL = 'nepal',
    NETHERLANDS = 'netherlands',
    NEW_ZEALAND = 'new zealand',
    NICARAGUA = 'nicaragua',
    NIGER = 'niger',
    NIGERIA = 'nigeria',
    NORTH_KOREA = 'north korea',
    NORTH_MACEDONIA = 'north macedonia',
    NORWAY = 'norway',
    OMAN = 'oman',
    PAKISTAN = 'pakistan',
    PALAU = 'palau',
    PALESTINE_STATE = 'palestine state',
    PANAMA = 'panama',
    PAPUA_NEW_GUINEA = 'papua new guinea',
    PARAGUAY = 'paraguay',
    PERU = 'peru',
    PHILIPPINES = 'philippines',
    POLAND = 'poland',
    PORTUGAL = 'portugal',
    QATAR = 'qatar',
    ROMANIA = 'romania',
    RUSSIA = 'russia',
    RWANDA = 'rwanda',
    SAINT_KITTS_AND_NEVIS = 'saint kitts and nevis',
    SAINT_LUCIA = 'saint lucia',
    SAINT_VINCENT_AND_THE_GRENADINES = 'saint vincent and the grenadines',
    SAMOA = 'samoa',
    SAN_MARINO = 'san marino',
    SAO_TOME_AND_PRINCIPE = 'sao tome and principe',
    SAUDI_ARABIA = 'saudi arabia',
    SENEGAL = 'senegal',
    SERBIA = 'serbia',
    SEYCHELLES = 'seychelles',
    SIERRA_LEONE = 'sierra leone',
    SINGAPORE = 'singapore',
    SLOVAKIA = 'slovakia',
    SLOVENIA = 'slovenia',
    SOLOMON_ISLANDS = 'solomon islands',
    SOMALIA = 'somalia',
    SOUTH_AFRICA = 'south africa',
    SOUTH_KOREA = 'south korea',
    SOUTH_SUDAN = 'south sudan',
    SPAIN = 'spain',
    SRI_LANKA = 'sri lanka',
    SUDAN = 'sudan',
    SURINAME = 'suriname',
    SWEDEN = 'sweden',
    SWITZERLAND = 'switzerland',
    SYRIA = 'syria',
    TAJIKISTAN = 'tajikistan',
    TANZANIA = 'tanzania',
    THAILAND = 'thailand',
    TIMOR_LESTE = 'timor-leste',
    TOGO = 'togo',
    TONGA = 'tonga',
    TRINIDAD_AND_TOBAGO = 'trinidad and tobago',
    TUNISIA = 'tunisia',
    TURKEY = 'turkey',
    TURKMENISTAN = 'turkmenistan',
    TUVALU = 'tuvalu',
    UGANDA = 'uganda',
    UKRAINE = 'ukraine',
    UNITED_ARAB_EMIRATES = 'united arab emirates',
    UNITED_KINGDOM = 'united kingdom',
    UNITED_STATES_OF_AMERICA = 'united states of america',
    URUGUAY = 'uruguay',
    UZBEKISTAN = 'uzbekistan',
    VANUATU = 'vanuatu',
    VENEZUELA = 'venezuela',
    VIETNAM = 'vietnam',
    YEMEN = 'yemen',
    ZAMBIA = 'zambia',
    ZIMBABWE = 'zimbabwe',
}
