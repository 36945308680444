import { useMemo } from 'react';
import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import {
    useGetEvents,
    useSetTravelersParticipationBookings,
} from '../../queries';
import { getEventItemDtoToEvent } from './event.converters';
import { EventItem } from './event.types';
import {
    ActivityType,
    GetEventsResDto,
    GetEventsResItem0Dto,
    GetEventsResItem1BookingsSummaryBookingsItemDto,
    GetEventsResItem1Dto,
    GetEventsResItem2Dto,
    SetTravelersParticipationBookingsResParticipantsTravelersItemDto,
} from '../../requests';

type UseGetEventsParamTypes = Parameters<typeof useGetEvents>;
export const useGetEventsKey = 'EventsServiceGetEvents';
export const useEvents = (
    params: UseGetEventsParamTypes[0] = {},
    enabled = true
) => {
    // const queryKey = ['EventsServiceGetEvents', params];
    const { data, ...other } = useGetEvents(params, [params], {
        cacheTime: 0,
        enabled,
    });

    // const queryClient = useQueryClient();
    const update = (eventId: string, event: Partial<EventItem>) => {
        // @ts-ignore
        // queryClient.setQueryData<GetEventsResDto>(queryKey, events => {
        //     if (!events?.items) return undefined;
        //     const items = events.items.map(o =>
        //         o.id === eventId
        //             ? eventToGetEventItemDto({ ...o, ...event } as EventBookingItem)
        //             : o
        //     );
        //     return { ...events, items };
        // });
    };

    const parsedData: EventItem[] | undefined = useMemo(
        () =>
            data?.items
                ? data?.items
                      ?.sort((a, b) => a.date.start - b.date.start)
                      .map(getEventItemDtoToEvent)
                : undefined /* [
                      {
                          id: '1',
                          product: {},
                          status: EventStatus.ONGOING,
                          capacitySelection: {},
                          date: {
                              start: new Date(2023, 8, 17, 10),
                          },
                      },
                      {
                          id: '2',
                          product: {},
                          status: EventStatus.ONGOING,
                          capacitySelection: {},
                          date: {
                              start: new Date(2023, 8, 17, 12),
                          },
                      },
                      {
                          id: '3',
                          product: {},
                          status: EventStatus.ONGOING,
                          capacitySelection: {},
                          date: {
                              start: new Date(2023, 8, 17, 18),
                          },
                      },
                      {
                          id: '4',
                          product: {},
                          status: EventStatus.ONGOING,
                          capacitySelection: {},
                          date: {
                              start: new Date(2023, 8, 17, 20),
                          },
                      },
                      {
                          id: '5',
                          product: {},
                          status: EventStatus.ONGOING,
                          capacitySelection: {},
                          date: {
                              start: new Date(2023, 8, 17, 20),
                          },
                      },
                      {
                          id: '6',
                          product: {},
                          status: EventStatus.ONGOING,
                          capacitySelection: {},
                          date: {
                              start: new Date(2023, 8, 18, 10),
                          },
                      },
                  ] */,
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

const updateBookingTravelerParticipation = (
    b: GetEventsResItem1BookingsSummaryBookingsItemDto,
    traveler: SetTravelersParticipationBookingsResParticipantsTravelersItemDto
): GetEventsResItem1BookingsSummaryBookingsItemDto => ({
    ...b,
    participants: {
        ...b.participants,
        travelers: b.participants.travelers?.map(t =>
            t.id === traveler.id
                ? {
                      ...t,
                      participation: traveler.participation,
                  }
                : t
        ),
    },
});

type GetEventsResItemDto =
    | GetEventsResItem0Dto
    | GetEventsResItem1Dto
    | GetEventsResItem2Dto;
const updateTravelerParticipation = (
    event: GetEventsResItemDto,
    bookingId: string,
    traveler: SetTravelersParticipationBookingsResParticipantsTravelersItemDto
): GetEventsResItemDto => ({
    ...event,
    bookings_summary: event.bookings_summary
        ? {
              ...event.bookings_summary,
              bookings: event.bookings_summary.bookings.map(b =>
                  b.id === bookingId
                      ? updateBookingTravelerParticipation(b, traveler)
                      : b
              ),
          }
        : undefined,
});

export const useSetTravelerParticipation = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<any>>,
            unknown,
            {
                eventId: string;
                bookingId: string;
                travelerId: string;
                activityType: ActivityType;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { mutate: setParticipation } = useSetTravelersParticipationBookings();

    const queryClient = useQueryClient();
    return useMutation(
        ({ eventId, bookingId, travelerId, activityType }) =>
            new Promise((resolve, reject) => {
                setParticipation(
                    {
                        bookingId,
                        requestBody: {
                            travelers: [
                                {
                                    id: travelerId,
                                    participation: {
                                        type: activityType,
                                    },
                                },
                            ],
                        },
                    },
                    {
                        onSuccess: data => {
                            if (data.participants.travelers[0]) {
                                const traveler = data.participants.travelers[0];
                                const queriesData = queryClient.getQueriesData({
                                    queryKey: [useGetEventsKey],
                                    exact: false,
                                });
                                queriesData.forEach(([queryKey]) => {
                                    queryClient.setQueryData<GetEventsResDto>(
                                        queryKey,
                                        events => {
                                            if (!events?.items)
                                                return undefined;
                                            const items = events.items.map(e =>
                                                e.id === eventId
                                                    ? updateTravelerParticipation(
                                                          e,
                                                          bookingId,
                                                          traveler
                                                      )
                                                    : e
                                            );
                                            return { ...events, items };
                                        }
                                    );
                                });
                            }
                            resolve(data);
                        },
                        onError: reject,
                    }
                );
            }),
        options
    );
};
