import { array, object } from 'yup';

const validNumber = v => v && v !== '0' && v !== 0;

const schema = object({
    duration: object().test(
        'duration validation fn',
        'Duration validation error',
        function duration(value) {
            if (
                !validNumber(value?.hour) &&
                !validNumber(value?.day) &&
                !validNumber(value?.minute)
            ) {
                return this.createError({
                    message: 'Duration is a required field',
                });
            }
            return true;
        }
    ),
    items: array()
        .min(1, 'Schedule items are required')
        .of(object())
        .notInEdit('Schedule item(s) are in edit mode'),
});

export default schema;
