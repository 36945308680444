import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useGetUser } from '../../hooks/api';

function Callback() {
    const { error } = useAuth0();
    const navigate = useNavigate();
    const { data: user, isLoading } = useGetUser();

    if (error) {
        return <div>{error?.message}</div>;
    }

    if (!isLoading) {
        navigate(!user ? '/products' : '/products');
    }

    return null;
}

export default Callback;
