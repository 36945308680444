/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PricingType {
    PER_TICKET = 'per-ticket',
    PER_PRODUCT = 'per-product',
    PER_AGEBAND = 'per-ageband',
}
