import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import {
    computeAvailabilityDtoToAvailability,
    filterToRequestBodyConverter,
} from './availability.converters';
import {
    AvailabilityService,
    ComputeAvailabilityResMatrixObjItem0Dto,
} from '../../requests';
import { AvailabilityFilterValues } from './availability.types';

export const useAvailability = (params: {
    requestBody: AvailabilityFilterValues;
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data, ...other } = useQuery({
        queryKey: [params?.requestBody],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return AvailabilityService.computeAvailability(
                authorization,
                // @ts-ignore
                filterToRequestBodyConverter(params?.requestBody)
            );
        },
    });

    const parsedData = useMemo(
        () =>
            data?.matrix
                ? Object.keys(data.matrix).map(key =>
                      computeAvailabilityDtoToAvailability(
                          key,
                          data.matrix?.[
                              key
                          ] as ComputeAvailabilityResMatrixObjItem0Dto[]
                      )
                  )
                : undefined,
        [data]
    );
    // const parsedData: Availability[] = useMemo(
    //     () => generateAvailability(),
    //     []
    // );

    return {
        data: parsedData,
        ...other,
    };
};
