import { useController, useFormContext } from 'react-hook-form';
import React, { useMemo } from 'react';
import { Chip, FormHelperText, Stack } from '@mui/material';
import _ from 'lodash';

export interface TagsListProps {
    tagSx?: Record<string, any>;
    selectAll?: boolean;
    multiple?: boolean;
    name: string;
    showErrors?: boolean;
    options: { value: string | number; label: string }[];
    disabled?: boolean;
    hideUnselected?: boolean;
    gap?: number;
}

const TagsList: React.FC<TagsListProps> = ({
    tagSx,
    selectAll,
    multiple,
    options,
    name,
    showErrors,
    disabled,
    hideUnselected,
    gap = 0.5,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { field } = useController({
        control,
        name,
        defaultValue: multiple ? [] : undefined,
    });

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);
    const visibleOptions = useMemo(() => {
        return hideUnselected
            ? options.filter(o =>
                  multiple
                      ? field.value.includes(o.value)
                      : field.value === o.value
              )
            : options;
    }, [field.value, hideUnselected, multiple, options]);

    const arrayValue = multiple ? field.value : [field.value];

    return (
        <>
            <Stack
                direction="row"
                flexWrap="wrap"
                sx={{ py: '3px', mx: -(gap / 2) }}
            >
                {!hideUnselected && selectAll && (
                    <Chip
                        label="Select All"
                        clickable
                        sx={{
                            '&&&': tagSx || {},
                            my: '5px',
                            mx: gap / 2,
                        }}
                        color={
                            field.value?.length === options.length
                                ? 'primary'
                                : 'light'
                        }
                        variant="filled"
                        onClick={() =>
                            field.value?.length === options.length
                                ? field.onChange([])
                                : field.onChange(
                                      options.map(({ value }) => value)
                                  )
                        }
                    />
                )}
                {visibleOptions.map(option => (
                    <Chip
                        key={option.value}
                        label={option.label}
                        size="small"
                        clickable
                        disabled={disabled}
                        sx={{
                            '&&&': tagSx || {},
                            my: '5px',
                            mx: gap / 2,
                            height: 'auto',
                            border: 0,
                            p: '10px',
                            '& > .MuiChip-label': {
                                fontSize: '12px',
                                lineHeight: '12px',
                            },
                        }}
                        color={
                            arrayValue?.includes(option.value)
                                ? 'primary'
                                : 'light'
                        }
                        variant="filled"
                        onClick={() =>
                            multiple
                                ? field.onChange(
                                      _.xor(field.value, [option.value])
                                  )
                                : field.onChange(option.value)
                        }
                    />
                ))}
            </Stack>
            {error && <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default TagsList;
