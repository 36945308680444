/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderStatus {
    OPEN = 'open',
    IN_PROGRESS = 'in_progress',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed',
}
