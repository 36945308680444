import React from 'react';
import { TextField, TextFieldProps } from '@travelity/form';
import { Stack, Typography } from '@mui/material';

export interface SingleLineTextFieldProps {
    label: string;
    name: string;
    width?: string;
    readOnly?: boolean;
    textFieldProps?: Partial<TextFieldProps>;
}

export const SingleLineTextField: React.FC<
    SingleLineTextFieldProps
> = props => {
    const { label, name, width, readOnly, textFieldProps = {} } = props;

    return (
        <Stack direction="row" gap={0.5}>
            <Typography
                sx={{
                    pt: 1,
                    color: '#9198AA',
                    fontSize: '14px',
                    width: width || '50%',
                }}
            >
                {label}
            </Typography>
            <TextField
                name={name}
                variant="standard"
                showErrors
                helperText=""
                {...textFieldProps}
                sx={{
                    mx: '4px',
                    flexGrow: 2,
                    width: '50%',
                    ...(readOnly ? { pointerEvents: 'none' } : {}),
                }}
                InputProps={{
                    sx: {
                        '& input': {
                            textAlign: 'right',
                            ...(readOnly
                                ? {
                                      paddingBottom: '0',
                                      paddingTop: '8px',
                                  }
                                : {}),
                        },
                        fontSize: '14px',
                        pl: 0,
                        '&:before': {
                            borderColor: readOnly ? 'transparent' : '#D5D7DE',
                        },
                        '&:after': {
                            borderColor: '#2B395B',
                            borderWidth: '1px',
                        },
                        '&:hover': {
                            '&&&:not(.Mui-error):before': {
                                borderColor: '#2B395B',
                                borderWidth: '1px',
                            },
                        },
                    },
                }}
            />
        </Stack>
    );
};
