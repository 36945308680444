/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductTagType {
    CATEGORY = 'category',
    INTEREST = 'interest',
    ACCESSIBILITY = 'accessibility',
}
