import { Booking } from './booking.types';
import { GetBookingsResItemDto, UpdateBookingReqDto } from '../../requests';

export const getBookingDtoToBooking = (
    booking: GetBookingsResItemDto
): Booking => {
    return {
        id: booking.id,
        status: booking.status,
        notes: booking.notes,
        product: booking.product,
        date: booking.date,
        financials: booking.financials,
        participants: booking.participants,
        orderId: booking.order.id,
    };
};
export const bookingToGetBookingDto = (
    booking: Booking
): Partial<GetBookingsResItemDto> => {
    return {
        id: booking.id,
        // @ts-ignore
        product: booking.product,
    };
};

export const patchBookingToBookingDto = (
    booking: Partial<Booking>
): UpdateBookingReqDto => ({
    notes: booking.notes,
});
