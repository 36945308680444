import React, { useCallback, useState } from 'react';
import { Box, Divider, Skeleton, Stack } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PriceSummary as IPriceSummary } from '@travelity/api/src/services/order/order.types';
import { useTheme } from '@mui/material/styles';
import { PriceItem } from './components/price-item';
import { Summary } from './components/summary';

export interface PriceSummaryProps {
    price?: Partial<IPriceSummary>;
    isLoading: boolean;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export const PriceSummary: React.FC<PriceSummaryProps> = props => {
    const { price, isLoading } = props;
    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen(prevState => !prevState);
    }, []);

    const finalPrice = price?.final
        ? `${numberWithSpaces(price.final)} ${price.currency}`
        : '';

    const theme = useTheme();

    return (
        <Box
            sx={{
                overflow: 'hidden',
                position: 'relative',
                bgcolor: '#F4F6FA',
                borderRadius: '8px',
                minHeight: '60px',
                px: 2,
                pt: 3,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '-24px',
                    left: 'calc(50% - 24px)',
                    border: '4px solid #FFF',
                    width: '48px',
                    height: '48px',
                    borderRadius: '50%',
                    textAlign: 'center',
                    cursor: 'pointer',
                }}
                onClick={toggleOpen}
            >
                {open ? (
                    <KeyboardArrowDownIcon
                        sx={{ mt: '17px', color: '#959CAD', fontSize: '24px' }}
                    />
                ) : (
                    <KeyboardArrowUpIcon
                        sx={{ mt: '17px', color: '#959CAD', fontSize: '24px' }}
                    />
                )}
            </Box>
            <Stack
                sx={{
                    transition: theme.transitions.create('height', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                }}
            >
                {open && (
                    <>
                        {price?.main && (
                            <>
                                <Divider sx={{ mb: 1 }}>
                                    Main Product Price
                                </Divider>
                                <PriceItem
                                    currency={price.currency || ''}
                                    isLoading={isLoading}
                                    price={price.main}
                                />
                            </>
                        )}
                        {!!price?.options && (
                            <Box>
                                <Divider sx={{ mb: 1 }}>
                                    Product Options’ Price
                                </Divider>
                                {price.options.map(option =>
                                    option.price ? (
                                        <>
                                            <Box>{option.name}</Box>
                                            <PriceItem
                                                currency={price.currency || ''}
                                                isLoading={isLoading}
                                                price={option.price}
                                            />
                                        </>
                                    ) : undefined
                                )}
                            </Box>
                        )}
                        {price?.discount &&
                            price?.final &&
                            price.beforeDiscount && (
                                <Box>
                                    <Divider sx={{ mb: 1 }}>Summary</Divider>
                                    <Summary
                                        currency={price?.currency || ''}
                                        before={price?.beforeDiscount}
                                        after={price.final}
                                        discountAmount={price?.discount?.amount}
                                        discountType={price?.discount?.type}
                                        isLoading={isLoading}
                                    />
                                </Box>
                            )}
                    </>
                )}

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mt: 1, mb: 1.5 }}
                >
                    <Box
                        sx={{
                            color: '#6B748C',
                            fontSize: '15px',
                            fontWeight: '500',
                        }}
                    >
                        {price?.beforeDiscount !== price?.final
                            ? 'Total after discount'
                            : 'Total'}
                    </Box>
                    <Box
                        sx={{
                            color: '#2B395B',
                            textAlign: 'right',
                            fontSize: '15px',
                            fontWeight: '600',
                        }}
                    >
                        {isLoading ? <Skeleton width={60} /> : finalPrice}
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};
