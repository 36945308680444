/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEventReqDto } from '../models/CreateEventReqDto';
import type { CreateEventResDto } from '../models/CreateEventResDto';
import type { GetEventsResDto } from '../models/GetEventsResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * List/Filter Events
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param startTs Timestamp of start datetime
     * @param endTs Timestamp of end datetime
     * @param productId ID of the product
     * @returns GetEventsResDto Successful Response
     * @throws ApiError
     */
    public static getEvents(
authorization: string,
startTs?: number,
endTs?: number,
productId?: string,
): CancelablePromise<GetEventsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events',
            headers: {
                'authorization': authorization,
            },
            query: {
                'start_ts': startTs,
                'end_ts': endTs,
                'product_id': productId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createEvent(
authorization: string,
requestBody: CreateEventReqDto,
): CancelablePromise<CreateEventResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
