import { GetEventsResItem0BookingsSummaryTravelersSummaryTravelersItemDto } from '@travelity/api/src/requests';
import { EventTraveler } from '@travelity/api';
import { travelerToTravelerForm } from '../booking-participants/booking-participants.converters';

export const travelersToTravelersForm = (
    travelers: GetEventsResItem0BookingsSummaryTravelersSummaryTravelersItemDto[]
): { travelers: EventTraveler[] } => ({
    travelers:
        travelers?.map(traveler => {
            const participant = travelerToTravelerForm(traveler);
            return {
                ...participant,
                id: traveler.id,
                bookingId: traveler.booking_id,
                status: traveler.participation?.type,
            };
        }) || [],
});
