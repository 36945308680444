import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
    Box,
    Divider,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem as MuiMenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
} from '@mui/material';
import { xor, get } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';

export interface SearchSelectOption {
    value: string;
    label: string;

    [key: string]: any;
}

export interface SearchSelectProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    options: SearchSelectOption[];
    showErrors?: boolean;
    MenuItem?: React.FC<any>;
}

const DefaultMenuItem: React.FC<{ label: string }> = props => {
    return (
        <Box
            sx={{
                lineHeight: '12px',
                py: '8px',
                px: '8px',
            }}
        >
            {props.label}
        </Box>
    );
};

const SearchSelect: React.FC<SearchSelectProps> = ({
    width,
    size,
    options,
    name,
    required,
    label,
    placeholder,
    showErrors,
    disabled,
    multiple,
    MenuItem = DefaultMenuItem,
    ...selectProps
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const { field } = useController({
        name,
        control,
        defaultValue: multiple ? [] : undefined,
    });

    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const filteredOptions = useMemo(() => {
        return searchText
            ? options.filter(({ label }) => {
                  return label.toLowerCase().includes(searchText.toLowerCase());
              })
            : options;
    }, [options, searchText]);

    const onClose = useCallback((e: SyntheticEvent<Element, Event>) => {
        if ((e.target as Element).id === 'search') return;
        setOpen(false);
        setSearchText('');
    }, []);

    const onChange = useCallback(
        (value: string) => {
            if (multiple) {
                field.onChange(xor(field.value, [value]));
            } else {
                field.onChange(value);
                setOpen(false);
                setSearchText('');
            }
        },
        [field, multiple]
    );

    const error =
        showErrors && (get(errors, name)?.message as string | undefined);

    return (
        <FormControl
            sx={{ width: width || '388px' }}
            error={!!error}
            size={size}
            disabled={disabled}
        >
            <MuiSelect
                open={open}
                onOpen={() => setOpen(true)}
                onClose={onClose}
                required={!!required}
                inputProps={{ required: false }}
                label={label}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                    ...(selectProps.sx ? selectProps.sx : {}),
                    '&&& legend': placeholder
                        ? {
                              maxWidth: '100%',
                          }
                        : {},
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #DFE1ED',
                    },
                    '&& .MuiOutlinedInput-notchedOutline': {
                        ...(open && {
                            borderBottom: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }),
                    },
                }}
                renderValue={selectedValue => {
                    const selected = multiple
                        ? selectedValue.length
                        : selectedValue;
                    if (!selected && placeholder) {
                        return (
                            <Box
                                component="span"
                                sx={{
                                    color: '#2B395B',
                                    opacity: 0.5,
                                }}
                            >
                                {placeholder}
                            </Box>
                        );
                    }

                    return multiple
                        ? options
                              .filter(({ value }) =>
                                  selectedValue.includes(value)
                              )
                              .map(({ label }) => label)
                              .join(', ')
                        : options.find(({ value }) => value === selectedValue)
                              ?.label;
                }}
                disabled={disabled}
                multiple={multiple}
                {...selectProps}
                value={field.value || ''}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 180,
                            boxShadow: 0,
                            border: '1px solid #DFE1ED',
                            borderTop: 0,
                            borderRadius: '32px',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginTop: 0,
                            p: 2,
                            pt: 0,
                        },
                    },
                    MenuListProps: {
                        sx: {
                            borderTop: '#DFE1ED 1px solid',
                            py: 1,
                        },
                    },
                }}
            >
                <MuiOutlinedInput
                    size="small"
                    autoFocus
                    fullWidth
                    id="search"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    sx={{
                        mb: 1,
                    }}
                    inputProps={{
                        sx: {
                            '&&&': { pl: 0 },
                        },
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: '#B2B9CD' }} />
                        </InputAdornment>
                    }
                />
                {filteredOptions.map((option, i) => (
                    <>
                        {i !== 0 && <Divider />}
                        <MuiMenuItem
                            sx={{
                                fontSize: '12px',
                                color: '#2B395B',
                                borderRadius: '8px',
                                py: '4px',
                                px: '4px',
                                '&:hover': {
                                    backgroundColor: '#EAF2F4',
                                },
                            }}
                            key={option.value}
                            onClick={() => onChange(option.value)}
                        >
                            <MenuItem {...option} />
                        </MuiMenuItem>
                    </>
                ))}
            </MuiSelect>
            <InputLabel
                required={!!required}
                shrink
                sx={{
                    '&.Mui-focused': {
                        color: '#949BAC',
                    },
                }}
            >
                {label}
            </InputLabel>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SearchSelect;
