/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTransactionBookingsReqDto } from '../models/AddTransactionBookingsReqDto';
import type { AddTransactionBookingsResDto } from '../models/AddTransactionBookingsResDto';
import type { AddTravelersBookingsReqDto } from '../models/AddTravelersBookingsReqDto';
import type { AddTravelersBookingsResDto } from '../models/AddTravelersBookingsResDto';
import type { CancelBookingsResDto } from '../models/CancelBookingsResDto';
import type { ComputePriceBookingsReqDto } from '../models/ComputePriceBookingsReqDto';
import type { ComputePriceBookingsResDto } from '../models/ComputePriceBookingsResDto';
import type { CreateBookingReqDto } from '../models/CreateBookingReqDto';
import type { CreateBookingResDto } from '../models/CreateBookingResDto';
import type { DiscardTransactionBookingsReqDto } from '../models/DiscardTransactionBookingsReqDto';
import type { DiscardTransactionBookingsResDto } from '../models/DiscardTransactionBookingsResDto';
import type { GetBookingResDto } from '../models/GetBookingResDto';
import type { GetBookingsResDto } from '../models/GetBookingsResDto';
import type { HoldAvailabilityBookingsResDto } from '../models/HoldAvailabilityBookingsResDto';
import type { ProductType } from '../models/ProductType';
import type { RemoveTravelersBookingsReqDto } from '../models/RemoveTravelersBookingsReqDto';
import type { RemoveTravelersBookingsResDto } from '../models/RemoveTravelersBookingsResDto';
import type { SetTravelersParticipationBookingsReqDto } from '../models/SetTravelersParticipationBookingsReqDto';
import type { SetTravelersParticipationBookingsResDto } from '../models/SetTravelersParticipationBookingsResDto';
import type { UpdateBooking1ResDto } from '../models/UpdateBooking1ResDto';
import type { UpdateBookingReqDto } from '../models/UpdateBookingReqDto';
import type { UpdateBookingResDto } from '../models/UpdateBookingResDto';
import type { UpdateTravelersBookingsReqDto } from '../models/UpdateTravelersBookingsReqDto';
import type { UpdateTravelersBookingsResDto } from '../models/UpdateTravelersBookingsResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BookingsService {

    /**
     * List/Filter Bookings
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param orderNumber Number of the order to which the bookings belong
     * @param eventId ID of the event to which the booking belongs
     * @param customerName Name of the customer
     * @param customerEmail Email of the customer
     * @param customerPhoneNumber Phone number of the customer
     * @param productType Type of the product(s) being searched
     * @param productName name of the product(s) being searched
     * @param startTs Timestamp of start datetime
     * @param endTs Timestamp of end datetime
     * @param location location that is in the route of the search product(s)
     * @returns GetBookingsResDto Successful Response
     * @throws ApiError
     */
    public static getBookings(
authorization: string,
orderNumber?: string,
eventId?: string,
customerName?: string,
customerEmail?: string,
customerPhoneNumber?: string,
productType?: ProductType,
productName?: string,
startTs?: number,
endTs?: number,
location?: string,
): CancelablePromise<GetBookingsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings',
            headers: {
                'authorization': authorization,
            },
            query: {
                'order_number': orderNumber,
                'event_id': eventId,
                'customer_name': customerName,
                'customer_email': customerEmail,
                'customer_phone_number': customerPhoneNumber,
                'product_type': productType,
                'product_name': productName,
                'start_ts': startTs,
                'end_ts': endTs,
                'location': location,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Booking
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateBookingResDto Successful Response
     * @throws ApiError
     */
    public static createBooking(
authorization: string,
requestBody: CreateBookingReqDto,
): CancelablePromise<CreateBookingResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bookings',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch Booking
     * @param bookingId ID of the booking being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns GetBookingResDto Successful Response
     * @throws ApiError
     */
    public static getBooking(
bookingId: string,
authorization: string,
): CancelablePromise<GetBookingResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings/{booking_id}',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns UpdateBookingResDto Successful Response
     * @throws ApiError
     */
    public static updateBooking(
bookingId: string,
authorization: string,
requestBody: UpdateBookingReqDto,
): CancelablePromise<UpdateBookingResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Compute price of a booking
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns ComputePriceBookingsResDto Successful Response
     * @throws ApiError
     */
    public static computePriceBookings(
authorization: string,
requestBody: ComputePriceBookingsReqDto,
): CancelablePromise<ComputePriceBookingsResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bookings/compute_price',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel the Booking
     * @param bookingId ID of the booking being canceled
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns CancelBookingsResDto Successful Response
     * @throws ApiError
     */
    public static cancelBookings(
bookingId: string,
authorization: string,
): CancelablePromise<CancelBookingsResDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/bookings/{booking_id}/cancel',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add transaction for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns AddTransactionBookingsResDto Successful Response
     * @throws ApiError
     */
    public static addTransactionBookings(
bookingId: string,
authorization: string,
requestBody: AddTransactionBookingsReqDto,
): CancelablePromise<AddTransactionBookingsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/add_transaction',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Discard transaction for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param transactionId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns DiscardTransactionBookingsResDto Successful Response
     * @throws ApiError
     */
    public static discardTransactionBookings(
bookingId: string,
transactionId: string,
authorization: string,
requestBody: DiscardTransactionBookingsReqDto,
): CancelablePromise<DiscardTransactionBookingsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/discard_transaction/{transaction_id}',
            path: {
                'booking_id': bookingId,
                'transaction_id': transactionId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Hold availability Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns HoldAvailabilityBookingsResDto Successful Response
     * @throws ApiError
     */
    public static holdAvailabilityBookings(
bookingId: string,
authorization: string,
): CancelablePromise<HoldAvailabilityBookingsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings/{booking_id}/hold_availability',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add a list of transaction for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns AddTravelersBookingsResDto Successful Response
     * @throws ApiError
     */
    public static addTravelersBookings(
bookingId: string,
authorization: string,
requestBody: AddTravelersBookingsReqDto,
): CancelablePromise<AddTravelersBookingsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/add_travelers',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update a list of transaction for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns UpdateTravelersBookingsResDto Successful Response
     * @throws ApiError
     */
    public static updateTravelersBookings(
bookingId: string,
authorization: string,
requestBody: UpdateTravelersBookingsReqDto,
): CancelablePromise<UpdateTravelersBookingsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/update_travelers',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove a list of transaction for Booking by ID
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns RemoveTravelersBookingsResDto Successful Response
     * @throws ApiError
     */
    public static removeTravelersBookings(
bookingId: string,
authorization: string,
requestBody: RemoveTravelersBookingsReqDto,
): CancelablePromise<RemoveTravelersBookingsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/remove_travelers',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set participation type for travelers
     * @param bookingId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns SetTravelersParticipationBookingsResDto Successful Response
     * @throws ApiError
     */
    public static setTravelersParticipationBookings(
bookingId: string,
authorization: string,
requestBody: SetTravelersParticipationBookingsReqDto,
): CancelablePromise<SetTravelersParticipationBookingsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/set_travelers_participation',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move Booking from one event to another
     * @param bookingId ID of the booking being moved
     * @param eventId ID of the event the booking is moved to
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns UpdateBooking1ResDto Successful Response
     * @throws ApiError
     */
    public static updateBooking1(
bookingId: string,
eventId: string,
authorization: string,
): CancelablePromise<UpdateBooking1ResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/move/{event_id}',
            path: {
                'booking_id': bookingId,
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
