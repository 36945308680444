import React, { useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Tooltip } from '@mui/material';

export interface AccordionButtonsProps {
    editing?: boolean;
    onEdit?: () => void;
    saving?: boolean;
    onEditCancel?: () => void;
    formId?: string;
    errors: Record<string, any>;
}

const AccordionButtons: React.FC<AccordionButtonsProps> = ({
    editing,
    onEdit,
    saving,
    onEditCancel,
    formId,
    errors,
}) => {
    const onEditClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onEdit?.();
    };
    const onEditCancelClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onEditCancel?.();
    };
    const onSaveClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const stringErrors = useMemo(() => {
        return Object.values(errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [errors]);

    return editing ? (
        <>
            {stringErrors.length ? (
                <Tooltip
                    componentsProps={{
                        tooltip: {
                            sx: {
                                borderRadius: '8px',
                            },
                        },
                    }}
                    title={
                        <>
                            {stringErrors.map(error => (
                                <li>{error}</li>
                            ))}
                        </>
                    }
                >
                    <Button
                        type="submit"
                        onClick={onSaveClick}
                        form={formId}
                        sx={{
                            ml: 2,
                            padding: '4px',
                            background: '#FFF',
                            cursor: 'default',
                            minWidth: 'auto',
                            boxShadow:
                                '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                            borderRadius: '12px',
                            '&&&&:hover': {
                                boxShadow:
                                    '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                            },
                        }}
                    >
                        <CheckIcon
                            sx={{
                                fontSize: '20px',
                                lineHeight: '20px',
                                color: 'grey.400',
                            }}
                        />
                    </Button>
                </Tooltip>
            ) : (
                <Button
                    type="submit"
                    onClick={onSaveClick}
                    form={formId}
                    sx={{
                        ml: 2,
                        padding: '4px',
                        background: '#FFF',
                        minWidth: 'auto',
                        boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                        borderRadius: '12px',
                        '&&&&:hover': {
                            boxShadow:
                                '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                        },
                        '&:hover > svg': {
                            color: 'success.dark',
                        },
                        ...(saving
                            ? {
                                  pointerEvents: 'none',
                                  opacity: 0.5,
                              }
                            : {}),
                    }}
                >
                    <CheckIcon
                        sx={{
                            fontSize: '20px',
                            lineHeight: '20px',
                            color: 'success.main',
                        }}
                    />
                </Button>
            )}
            <Button
                onClick={onEditCancelClick}
                sx={{
                    ml: 1,
                    padding: '4px',
                    background: '#FFF',
                    minWidth: 'auto',
                    boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                    borderRadius: '12px',
                    '&&&&&:hover': {
                        boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                    },
                    '&:hover > svg': {
                        color: 'error.dark',
                    },
                    ...(saving
                        ? {
                              pointerEvents: 'none',
                              opacity: 0.5,
                          }
                        : {}),
                }}
            >
                <CloseIcon
                    sx={{
                        fontSize: '20px',
                        lineHeight: '20px',
                        color: 'error.main',
                    }}
                />
            </Button>
        </>
    ) : (
        <Box
            component="span"
            onClick={onEditClick}
            sx={{
                ml: 2,
                padding: '4px',
                background: '#FFF',
                boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                borderRadius: '12px',
                '&:hover > svg': {
                    color: 'secondary.main',
                },
            }}
        >
            <EditIcon
                sx={{
                    fontSize: '20px',
                    lineHeight: '20px',
                    color: '#C9CEDC',
                }}
            />
        </Box>
    );
};

export default AccordionButtons;
