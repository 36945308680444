import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Chip, MenuItem, Select as MuiSelect, Stack } from '@mui/material';
import { Weekdays } from '@travelity/form';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { minutesToTime } from '@travelity/utils';

export interface WeekdayRowProps {
    name: string;
    weekday: Weekdays;
}

const WeekdayRow: React.FC<WeekdayRowProps> = ({ name, weekday }) => {
    const { control, setValue } = useFormContext();
    const { t } = useTranslation('common');
    const { fields, append, update, remove } = useFieldArray<
        Record<string, any>,
        string,
        string
    >({
        name,
        control,
    });

    const options = Array.from(Array(4 * 24 + 1)).map((v, i) => {
        const minutes = i * 15;
        return {
            label: minutesToTime(minutes === 1440 ? 1439 : minutes),
            value: minutes === 1440 ? 1439 : minutes,
        };
    });

    const allDay =
        fields.length === 1 && fields[0].start === 0 && fields[0].end === 1439;

    return (
        <Stack sx={{ width: 1 }}>
            {fields.map((value, index) => (
                <Stack
                    direction="row"
                    justifyContent="center"
                    gap={2}
                    sx={{ py: '3px', px: 1 }}
                >
                    <Stack
                        sx={{ width: '96px' }}
                        direction="row"
                        justifyContent="end"
                    >
                        <Chip
                            label={t(weekday)}
                            sx={{
                                visibility: index === 0 ? 'visible' : 'hidden',
                                bgcolor: '#DDF0F5',
                                width: 1,
                                borderRadius: '26px',
                                '& .MuiChip-label': {
                                    px: 1,
                                },
                            }}
                        />
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent="center"
                        gap={1}
                        sx={{
                            color: '#949BAC',
                            alignItems: 'center',
                        }}
                    >
                        <MuiSelect
                            inputProps={{ required: false }}
                            displayEmpty
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{ minWidth: '116px' }}
                            renderValue={selected =>
                                options.find(({ value: v }) => v === selected)
                                    ?.label
                            }
                            size="small"
                            value={value.start}
                            onChange={e => {
                                update(index, {
                                    ...value,
                                    start: e.target.value,
                                });
                            }}
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 180 } },
                            }}
                        >
                            {options.map(option => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </MuiSelect>
                        -
                        <MuiSelect
                            inputProps={{ required: false }}
                            displayEmpty
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{ minWidth: '116px' }}
                            size="small"
                            renderValue={selected =>
                                options.find(({ value: v }) => v === selected)
                                    ?.label
                            }
                            value={value.end}
                            onChange={e => {
                                update(index, {
                                    ...value,
                                    end: e.target.value,
                                });
                            }}
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 180 } },
                            }}
                        >
                            {options.map(option => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </MuiSelect>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{ flexGrow: 1 }}
                        gap={3}
                        alignItems="center"
                    >
                        {index === 0 && (
                            <Stack direction="row" gap={2} alignItems="center">
                                <Box
                                    sx={{
                                        borderLeft: '1px solid #DFE1ED',
                                        height: '16px',
                                    }}
                                />
                                <Chip
                                    label="All day"
                                    clickable={!allDay}
                                    onClick={() => {
                                        if (!allDay) {
                                            setValue(name, [
                                                { start: 0, end: 1439 },
                                            ]);
                                        }
                                    }}
                                    sx={{
                                        bgcolor: allDay ? '#55B5CF' : '#DDF0F5',
                                        color: allDay ? '#FFFFFF' : '#9198AA',
                                        borderRadius: '26px',
                                        '& .MuiChip-label': {
                                            px: 1,
                                        },
                                        '&:hover': {
                                            bgcolor: '#55B5CF',
                                            color: '#FFFFFF',
                                        },
                                    }}
                                />
                            </Stack>
                        )}

                        {index === 0 ? (
                            <AddCircleIcon
                                onClick={() => {
                                    append({ start: 0, end: 60 });
                                }}
                                color="disabled"
                                sx={{ fontSize: '18px', cursor: 'pointer' }}
                            />
                        ) : (
                            <RemoveCircleIcon
                                onClick={() => {
                                    remove(index);
                                }}
                                color="disabled"
                                sx={{ fontSize: '18px', cursor: 'pointer' }}
                            />
                        )}
                    </Stack>
                </Stack>
            ))}
        </Stack>
    );
};

export default WeekdayRow;
