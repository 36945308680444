import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { TextField, useForm } from '@travelity/form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backgroundCollage from '../../assets/background-collage.svg';
import { ReactComponent as Logo } from '../../assets/travelity-logo.svg';

export default function SignInSide() {
    const { t } = useTranslation('auth');

    // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log({
    //         email: data.get('email'),
    //         password: data.get('password'),
    //     });
    // };
    const { Form } = useForm({
        onSubmit: () => {},
        mode: 'onChange',
        validateInitially: true,
    });

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword(show => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (
        <Stack
            component="main"
            alignItems="center"
            sx={{
                justifyContent: 'center',
                height: '100vh',
                backgroundImage: `url(${backgroundCollage})`,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
            }}
        >
            <CssBaseline />
            <Stack
                alignItems="center"
                gap={1}
                sx={{
                    my: 8,
                    mt: 4,
                    mx: 4,
                }}
            >
                <Stack direction="row" gap={1}>
                    <Typography sx={{ fontSize: '30px', color: '#3B4D7D' }}>
                        {t('Welcome to ')}
                    </Typography>
                    <Logo style={{ height: '50px' }} />
                </Stack>
                <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                        fontSize: '16px',
                        color: '#2B395B',
                    }}
                >
                    {t('signInTitle')}
                </Typography>
                <Form style={{ width: '451px' }}>
                    <Stack
                        alignItems="center"
                        gap={1}
                        sx={{
                            mt: 3,
                        }}
                    >
                        <TextField
                            fullWidth
                            name="email"
                            placeholder={t('emailAddress')}
                            autoComplete="email"
                        />
                        <TextField
                            fullWidth
                            name="password"
                            placeholder={t('password')}
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Stack
                            direction="row"
                            sx={{ width: 1 }}
                            justifyContent="end"
                        >
                            <Link
                                component={RouterLink}
                                to="/reset-password"
                                sx={{ fontSize: '14px' }}
                            >
                                {t('forgotPassword')}
                            </Link>
                        </Stack>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2, py: 1.5 }}
                        >
                            {t('signIn')}
                        </Button>
                        <Stack
                            direction="row"
                            sx={{
                                width: 1,
                                lineHeight: '16px',
                            }}
                            justifyContent="center"
                        >
                            <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    color: '#2B395B',
                                    mr: 0.5,
                                }}
                            >
                                {t('createAccountText')}
                            </Typography>
                            <Link component={RouterLink} to="/register">
                                {t('registerNow')}
                            </Link>
                        </Stack>
                    </Stack>
                </Form>
            </Stack>
        </Stack>
    );
}
