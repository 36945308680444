import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MainCard from '../../../../components/main-card/main-card';
import Transitions from '../../../../components/transitions/transitions';
import NotificationList from './notification-list';

function NotificationSection() {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<HTMLButtonElement | null>(null);

    const handleToggle = () => {
        // setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as Node)
        ) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Box
                sx={{
                    mx: 1,
                    [theme.breakpoints.down('md')]: {
                        mr: 1,
                    },
                }}
            >
                <IconButton
                    size="large"
                    sx={{
                        borderRadius: '12px',
                        bgcolor: '#F4F6FA',
                        '&:hover': {
                            bgcolor: '#EFF8FB',
                        },
                    }}
                    onClick={handleToggle}
                    ref={anchorRef}
                >
                    <NotificationsNoneIcon
                        sx={{ color: '#B2B9CD' }}
                        fontSize="medium"
                    />
                </IconButton>
                {/* <ButtonBase sx={{ borderRadius: '12px' }}> */}
                {/*    <Avatar */}
                {/*        variant="rounded" */}
                {/*        sx={{ */}
                {/*            transition: 'all .2s ease-in-out', */}
                {/*            background: theme.palette.secondary.light, */}
                {/*            color: theme.palette.secondary.dark, */}
                {/*            '&[aria-controls="nav-item-grow"],&:hover': { */}
                {/*                background: theme.palette.secondary.dark, */}
                {/*                color: theme.palette.secondary.light, */}
                {/*            }, */}
                {/*        }} */}
                {/*        ref={anchorRef} */}
                {/*        aria-controls={open ? 'nav-item-grow' : undefined} */}
                {/*        aria-haspopup="true" */}
                {/*        onClick={handleToggle} */}
                {/*        color="inherit" */}
                {/*    /> */}
                {/* </ButtonBase> */}
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions
                        position={matchesXs ? 'top' : 'top-right'}
                        in={open}
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{ pt: 2, px: 2 }}
                                            >
                                                <Grid item>
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                    >
                                                        <Typography variant="subtitle1">
                                                            All Notification
                                                        </Typography>
                                                        <Chip
                                                            size="small"
                                                            label="01"
                                                            sx={{
                                                                color: 'secondary.dark',
                                                                bgcolor:
                                                                    'secondary.light',
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        component={Link}
                                                        to="#"
                                                        variant="subtitle2"
                                                        color="primary"
                                                    >
                                                        Mark as all read
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction="column"
                                                spacing={2}
                                            >
                                                <Grid item xs={12} p={0}>
                                                    <Divider sx={{ my: 0 }} />
                                                </Grid>
                                            </Grid>
                                            <NotificationList />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <CardActions
                                        sx={{
                                            p: 1.25,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Button size="small" disableElevation>
                                            View All
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
}

export default NotificationSection;
