import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { MenuItem } from './types';

const items: MenuItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'item',
        url: '/',
        icon: HomeOutlinedIcon,
        breadcrumbs: false,
    },
    {
        id: 'products',
        title: 'Products',
        type: 'item',
        url: '/products',
        icon: FormatListBulletedOutlinedIcon,
        breadcrumbs: false,
    },
    {
        id: 'history',
        title: 'History',
        type: 'item',
        url: '/',
        icon: HistoryOutlinedIcon,
        breadcrumbs: false,
    },
    {
        id: 'report',
        title: 'Report',
        type: 'item',
        url: '/',
        icon: DescriptionOutlinedIcon,
        breadcrumbs: false,
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'item',
        url: '/',
        icon: PeopleOutlineOutlinedIcon,
        breadcrumbs: false,
    },
];
export default items;
