import React, { useCallback, useEffect } from 'react';
import { useForm } from '@travelity/form';
import { Box, Typography } from '@mui/material';
import { useCreateProduct } from '@travelity/api/src/services/product/product.hooks';
import {
    AddProductForm,
    addProductFormToAddProductDto,
    ProductType,
} from '@travelity/api';
import { useNavigate, useParams } from 'react-router-dom';
import StepContent from './step-content';
import { ProductDetailsForm } from '../../../components/product-details';
import { ProductCapacityForm } from '../../../components/product-capacity-form';
import { ProductRouteForm } from '../../../components/product-route-form';
import { ProductScheduleForm } from '../../../components/product-schedule-form';
import { ProductFinancingForm } from '../../../components/product-financing-form';
import { ProductOptionForm } from '../../../components/product-option-form';
import { useStepperContext } from '../../../components/stepper';
import { useHelperContext } from '../../../contexts/helper-context';
import { FullLoadingOverlay } from '../../../components/full-loading-overlay';

export interface PreviewStepProps {}

const PreviewStep: React.FC<PreviewStepProps> = () => {
    const { state } = useStepperContext<AddProductForm>();
    const navigate = useNavigate();
    const { mutate: create, isLoading } = useCreateProduct({
        onSuccess: () => {
            navigate('/products');
        },
    });

    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo();
    }, []);

    const { productType } = useParams();

    const onSave = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            create({
                requestBody: addProductFormToAddProductDto({
                    ...state,
                    type: productType as ProductType,
                }),
            });
        },
        [state]
    );
    const { Form: DetailsForm } = useForm({
        defaultValues: state.productInfo,
    });
    const { Form: RouteForm } = useForm({
        defaultValues: state.route,
    });
    const { Form: CapacityForm } = useForm({
        defaultValues: state.capacity,
    });
    const { Form: ScheduleForm } = useForm({
        defaultValues: state.schedule,
    });
    const { Form: FinancialInformationForm } = useForm({
        defaultValues: state.financial,
    });
    const { Form: OptionsForm } = useForm({
        defaultValues: state.options,
    });

    return (
        <>
            <StepContent title="Preview" errors={[]} onClick={onSave}>
                <Box sx={{ width: '451px' }}>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mb: 2 }}
                        variant="subtitle1"
                    >
                        Product Details
                    </Typography>
                    <DetailsForm>
                        <ProductDetailsForm preview />
                    </DetailsForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Route
                    </Typography>
                    <RouteForm>
                        <ProductRouteForm preview />
                    </RouteForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Capacity
                    </Typography>
                    <CapacityForm>
                        <ProductCapacityForm preview />
                    </CapacityForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Schedule
                    </Typography>
                    <ScheduleForm>
                        <ProductScheduleForm preview />
                    </ScheduleForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Financial Information
                    </Typography>
                    <FinancialInformationForm>
                        <ProductFinancingForm
                            preview
                            capacity={state.capacity as Record<string, any>}
                        />
                    </FinancialInformationForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Product Options
                    </Typography>
                    <OptionsForm>
                        <ProductOptionForm
                            preview
                            currency={state.financial?.currency as string}
                        />
                    </OptionsForm>
                </Box>
            </StepContent>
            {isLoading && <FullLoadingOverlay />}
        </>
    );
};

export default React.memo(PreviewStep);
