import React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { TextField, DurationField, CardList } from '@travelity/form';
import { array, date, number, object, string } from 'yup';
import { RecurrenceTypes, Schedule, ScheduleOptionTypes } from '@travelity/api';
import { ScheduleCardContent } from './components/schedule-card';

export interface ProductScheduleFormProps {
    preview?: boolean;
}

const schema = object<Schedule>({
    name: string().required('Name is a required field').uniqName(),
    times: array().when('allDay', {
        is: true,
        otherwise: sch =>
            sch.of(number()).required().min(1, 'No start time is selected'),
    }),
}).when(['.type'], ([type], sch) => {
    if (type === ScheduleOptionTypes.SEASON) {
        return sch.shape({
            startDate: date().required('Start date is a required field'),
            endDate: date().required('End date is a required field'),
            weekDays: array().when('recurrenceType', {
                is: RecurrenceTypes.WEEKLY,
                then: s => s.of(string()).min(1, 'No days selected'),
            }),
            monthDays: array().when('recurrenceType', {
                is: RecurrenceTypes.MONTHLY,
                then: s => s.of(date()).min(1, 'No days selected'),
            }),
            dates: array().when('recurrenceType', {
                is: RecurrenceTypes.YEARLY,
                then: s => s.of(date()).min(1, 'No dates selected'),
            }),
        });
    }
    return sch.shape({
        startDates: array()
            .of(date())
            .required('Dates field is required')
            .min(1, 'No dates selected'),
    });
});

const ProductScheduleForm: React.FC<ProductScheduleFormProps> = ({
    preview,
}) => {
    return (
        <Stack spacing={2} sx={{ width: '453px' }}>
            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Schedule Settings
            </Divider>
            <DurationField
                name="duration"
                label="Select event duration"
                InputProps={{
                    disabled: preview,
                    sx: {
                        '&& input': { width: '84px' },
                    },
                }}
            />

            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Schedule Items
            </Divider>
            <CardList
                disabled={preview}
                renderHeader={({ editing, item }) =>
                    editing ? (
                        <TextField
                            sx={{ mx: '10px' }}
                            autoFocus
                            InputProps={{
                                sx: { fontSize: '14px', pl: 1.5 },
                            }}
                            placeholder="Type name"
                            name="name"
                            variant="standard"
                            showErrors
                            helperText=""
                            fullWidth
                        />
                    ) : (
                        <Box component="span">{item.name}</Box>
                    )
                }
                renderContent={({ item, editing }) => (
                    <ScheduleCardContent item={item} editing={editing} />
                )}
                addButtonText="Add Schedule Item"
                itemOptions={[
                    { label: 'Season', type: ScheduleOptionTypes.SEASON },
                    {
                        label: 'Custom dates',
                        type: ScheduleOptionTypes.CUSTOM,
                    },
                ]}
                formParams={{ schema }}
                name="items"
            />
        </Stack>
    );
};

export default React.memo(ProductScheduleForm);
