/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TransactionType {
    PAYMENT = 'payment',
    REFUND = 'refund',
}
