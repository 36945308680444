import { useCallback, useState } from 'react';

export interface RequestStatus {
    isLoading: boolean;
    success: boolean;
    error: string;
}

export const useRequestStatus = () => {
    const [requestStatus, setRequestStatus] = useState<RequestStatus>({
        isLoading: false,
        success: false,
        error: '',
    });

    const startRequest = useCallback(() => {
        setRequestStatus({
            isLoading: true,
            success: false,
            error: '',
        });
    }, []);

    const endRequest = useCallback((err?: string) => {
        setRequestStatus({
            isLoading: false,
            success: !err,
            error: err || '',
        });
    }, []);

    return {
        requestStatus,
        startRequest,
        endRequest,
    };
};
