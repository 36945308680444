import { EventItem, GetEventsResItemDto } from './event.types';
import {
    GetEventsResItem0BookingsSummaryBookingsItemParticipantsTravelersItemDto,
    GetEventsResItem0Dto,
    TimezoneName,
} from '../../requests';

export const eventToGetEventItemDto = (
    event: EventItem
): Omit<GetEventsResItemDto, 'created'> => ({
    id: event.id,
    product: event.product,
    operations: {
        capacity_selection: event.capacitySelection,
    },
    date: {
        tz_name: Intl.DateTimeFormat().resolvedOptions()
            .timeZone as TimezoneName,
        start: Math.round(event.date.start.getTime() / 1000),
        end: event.date.end
            ? Math.round(event.date.end.getTime() / 1000)
            : undefined,
    },
    bookings_summary: event.bookingsSummary,
    notes: event.notes,
    status: event.status,
    // available_seats: event.availableSeats,
    // ephemeral: event.ephemeral,
});

export const getEventItemDtoToEvent = (
    event: GetEventsResItemDto
): EventItem => ({
    id: event.id,
    product: event.product,
    capacitySelection: event.operations.capacity_selection,
    date: {
        start: new Date(event.date.start * 1000),
        end: event.date.end ? new Date(event.date.end * 1000) : undefined,
    },
    bookingsSummary: event.bookings_summary
        ? {
              ...event.bookings_summary,
              travelers_summary: {
                  count:
                      event.bookings_summary?.bookings?.reduce(
                          (sum, cur) =>
                              sum + (cur.participants.travelers?.length || 0),
                          0
                      ) || 0,
                  travelers:
                      event.bookings_summary?.bookings?.reduce(
                          (
                              arr: GetEventsResItem0BookingsSummaryBookingsItemParticipantsTravelersItemDto[],
                              cur
                          ) => [...arr, ...(cur.participants.travelers || [])],
                          []
                      ) || [],
              },
          }
        : undefined,
    notes: event.notes,
    status: event.status,
    availableSeats: (event as GetEventsResItem0Dto).available_seats,
    ephemeral: (event as GetEventsResItem0Dto).ephemeral,
});

export const eventsFilterToRequestBodyConverter = (
    filters: Record<string, any>
) => ({
    startTs: filters?.dates?.startDate
        ? Math.round(filters.dates.startDate.getTime() / 1000)
        : undefined,
    endTs: filters?.dates?.endDate
        ? Math.round(filters.dates.endDate.getTime() / 1000)
        : undefined,
    productId: filters.products?.[0],
});
