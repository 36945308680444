import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

function Copyright(props: any) {
    const { t } = useTranslation();

    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {t('copyright')}
            {' © '}
            <Link color="inherit" href="/packages/travelity-web/src/routes">
                Travelity
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
}

export default Copyright;
