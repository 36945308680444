import { date, object } from 'yup';

const schema = object({
    dates: object()
        .shape({
            startDate: date().required('Start Date'),
            endDate: date().required('End Date'),
        })
        .required('Dates'),
    pax: object().test('Required', function paxRequired(value) {
        if (value && Object.values(value).find(v => v)) {
            return true;
        }

        return this.createError({ message: 'PAX' });
    }),
});

export default schema;
