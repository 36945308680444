import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { Tab, Tabs } from '@travelity/ui';

export interface TabFormsOption {
    label: string;
    value: number | string;
    children: ReactNode;
}

export interface TabFormsProps {
    name: string;
    defaultValue?: number | string;
    options: TabFormsOption[];
}

const TabForms: React.FC<TabFormsProps> = props => {
    const { options, name, defaultValue } = props;
    const { control } = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue,
    });

    const { children } = options.find(o => o.value === value) || {};

    return (
        <Box
            sx={{
                borderRadius: '8px',
                border: '1px solid #DFE1ED',
                background: '#F4F6FA',
                p: 0.5,
            }}
        >
            <Tabs
                sx={{ mb: 0.5 }}
                value={value}
                onChange={(e, v) => onChange(v)}
            >
                {options.map(option => (
                    <Tab
                        key={option.value}
                        value={option.value}
                        label={option.label}
                    />
                ))}
            </Tabs>
            {children}
        </Box>
    );
};

export default TabForms;
