/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Nationality {
    AFGHAN = 'afghan',
    ALBANIAN = 'albanian',
    ALGERIAN = 'algerian',
    AMERICAN = 'american',
    ANDORRAN = 'andorran',
    ANGOLAN = 'angolan',
    ANGUILLAN = 'anguillan',
    CITIZEN_OF_ANTIGUA_AND_BARBUDA = 'citizen of antigua and barbuda',
    ARGENTINE = 'argentine',
    ARMENIAN = 'armenian',
    AUSTRALIAN = 'australian',
    AUSTRIAN = 'austrian',
    AZERBAIJANI = 'azerbaijani',
    BAHAMIAN = 'bahamian',
    BAHRAINI = 'bahraini',
    BANGLADESHI = 'bangladeshi',
    BARBADIAN = 'barbadian',
    BELARUSIAN = 'belarusian',
    BELGIAN = 'belgian',
    BELIZEAN = 'belizean',
    BENINESE = 'beninese',
    BERMUDIAN = 'bermudian',
    BHUTANESE = 'bhutanese',
    BOLIVIAN = 'bolivian',
    CITIZEN_OF_BOSNIA_AND_HERZEGOVINA = 'citizen of bosnia and herzegovina',
    BOTSWANAN = 'botswanan',
    BRAZILIAN = 'brazilian',
    BRITISH = 'british',
    BRITISH_VIRGIN_ISLANDER = 'british_virgin_islander',
    BRUNEIAN = 'bruneian',
    BULGARIAN = 'bulgarian',
    BURKINAN = 'burkinan',
    BURMESE = 'burmese',
    BURUNDIAN = 'burundian',
    CAMBODIAN = 'cambodian',
    CAMEROONIAN = 'cameroonian',
    CANADIAN = 'canadian',
    CAPE = 'cape',
    VERDEAN = 'verdean',
    CAYMAN_ISLANDER = 'cayman islander',
    CENTRAL = 'central',
    AFRICAN = 'african',
    CHADIAN = 'chadian',
    CHILEAN = 'chilean',
    CHINESE = 'chinese',
    COLOMBIAN = 'colombian',
    COMORAN = 'comoran',
    CONGOLESE_CONGO_ = 'congolese(congo)',
    CONGOLESE_DRC_ = 'congolese(drc)',
    COOK = 'cook',
    ISLANDER = 'islander',
    COSTA = 'costa',
    RICAN = 'rican',
    CROATIAN = 'croatian',
    CUBAN = 'cuban',
    CYMRAES = 'cymraes',
    CYMRO = 'cymro',
    CYPRIOT = 'cypriot',
    CZECH = 'czech',
    DANISH = 'danish',
    DJIBOUTIAN = 'djiboutian',
    DOMINICAN = 'dominican',
    CITIZEN_OF_THE_DOMINICAN_REPUBLIC = 'citizen of the dominican republic',
    DUTCH = 'dutch',
    EAST = 'east',
    TIMORESE = 'timorese',
    ECUADOREAN = 'ecuadorean',
    EGYPTIAN = 'egyptian',
    EMIRATI = 'emirati',
    ENGLISH = 'english',
    EQUATORIAL_GUINEAN = 'equatorial guinean',
    ERITREAN = 'eritrean',
    ESTONIAN = 'estonian',
    ETHIOPIAN = 'ethiopian',
    FAROESE = 'faroese',
    FIJIAN = 'fijian',
    FILIPINO = 'filipino',
    FINNISH = 'finnish',
    FRENCH = 'french',
    GABONESE = 'gabonese',
    GAMBIAN = 'gambian',
    GEORGIAN = 'georgian',
    GERMAN = 'german',
    GHANAIAN = 'ghanaian',
    GIBRALTARIAN = 'gibraltarian',
    GREEK = 'greek',
    GREENLANDIC = 'greenlandic',
    GRENADIAN = 'grenadian',
    GUAMANIAN = 'guamanian',
    GUATEMALAN = 'guatemalan',
    CITIZEN_OF_GUINEA_BISSAU = 'citizen of guinea - bissau',
    GUINEAN = 'guinean',
    GUYANESE = 'guyanese',
    HAITIAN = 'haitian',
    HONDURAN = 'honduran',
    HONG_KONGER = 'hong konger',
    HUNGARIAN = 'hungarian',
    ICELANDIC = 'icelandic',
    INDIAN = 'indian',
    INDONESIAN = 'indonesian',
    IRANIAN = 'iranian',
    IRAQI = 'iraqi',
    IRISH = 'irish',
    ISRAELI = 'israeli',
    ITALIAN = 'italian',
    IVORIAN = 'ivorian',
    JAMAICAN = 'jamaican',
    JAPANESE = 'japanese',
    JORDANIAN = 'jordanian',
    KAZAKH = 'kazakh',
    KENYAN = 'kenyan',
    KITTITIAN = 'kittitian',
    CITIZEN_OF_KIRIBATI = 'citizen of kiribati',
    KOSOVAN = 'kosovan',
    KUWAITI = 'kuwaiti',
    KYRGYZ = 'kyrgyz',
    LAO = 'lao',
    LATVIAN = 'latvian',
    LEBANESE = 'lebanese',
    LIBERIAN = 'liberian',
    LIBYAN = 'libyan',
    LIECHTENSTEIN_CITIZEN = 'liechtenstein citizen',
    LITHUANIAN = 'lithuanian',
    LUXEMBOURGER = 'luxembourger',
    MACANESE = 'macanese',
    MACEDONIAN = 'macedonian',
    MALAGASY = 'malagasy',
    MALAWIAN = 'malawian',
    MALAYSIAN = 'malaysian',
    MALDIVIAN = 'maldivian',
    MALIAN = 'malian',
    MALTESE = 'maltese',
    MARSHALLESE = 'marshallese',
    MARTINIQUAIS = 'martiniquais',
    MAURITANIAN = 'mauritanian',
    MAURITIAN = 'mauritian',
    MEXICAN = 'mexican',
    MICRONESIAN = 'micronesian',
    MOLDOVAN = 'moldovan',
    MONEGASQUE = 'monegasque',
    MONGOLIAN = 'mongolian',
    MONTENEGRIN = 'montenegrin',
    MONTSERRATIAN = 'montserratian',
    MOROCCAN = 'moroccan',
    MOSOTHO = 'mosotho',
    MOZAMBICAN = 'mozambican',
    NAMIBIAN = 'namibian',
    NAURUAN = 'nauruan',
    NEPALESE = 'nepalese',
    NEW_ZEALANDER = 'new zealander',
    NICARAGUAN = 'nicaraguan',
    NIGERIAN = 'nigerian',
    NIGERIEN = 'nigerien',
    NIUEAN = 'niuean',
    NORTH = 'north',
    KOREAN = 'korean',
    NORTHERN_IRISH = 'northern irish',
    NORWEGIAN = 'norwegian',
    OMANI = 'omani',
    PAKISTANI = 'pakistani',
    PALAUAN = 'palauan',
    PALESTINIAN = 'palestinian',
    PANAMANIAN = 'panamanian',
    PAPUA_NEW_GUINEAN = 'papua new guinean',
    PARAGUAYAN = 'paraguayan',
    PERUVIAN = 'peruvian',
    PITCAIRN_ISLANDER = 'pitcairn islander',
    POLISH = 'polish',
    PORTUGUESE = 'portuguese',
    PRYDEINIG = 'prydeinig',
    PUERTO_RICAN = 'puerto rican',
    QATARI = 'qatari',
    ROMANIAN = 'romanian',
    RUSSIAN = 'russian',
    RWANDAN = 'rwandan',
    SALVADOREAN = 'salvadorean',
    SAMMARINESE = 'sammarinese',
    SAMOAN = 'samoan',
    SAO_TOMEAN = 'sao tomean',
    SAUDI_ARABIAN = 'saudi arabian',
    SCOTTISH = 'scottish',
    SENEGALESE = 'senegalese',
    SERBIAN = 'serbian',
    CITIZEN_OF_SEYCHELLES = 'citizen of seychelles',
    SIERRA_LEONEAN = 'sierra leonean',
    SINGAPOREAN = 'singaporean',
    SLOVAK = 'slovak',
    SLOVENIAN = 'slovenian',
    SOLOMON_ISLANDER = 'solomon islander',
    SOMALI = 'somali',
    SOUTH_AFRICAN = 'south african',
    SOUTH_KOREAN = 'south korean',
    SOUTH_SUDANESE = 'south sudanese',
    SPANISH = 'spanish',
    SRI_LANKAN = 'sri lankan',
    ST_HELENIAN = 'st helenian',
    ST_LUCIAN = 'st lucian',
    SUDANESE = 'sudanese',
    SURINAMESE = 'surinamese',
    SWAZI = 'swazi',
    SWEDISH = 'swedish',
    SWISS = 'swiss',
    SYRIAN = 'syrian',
    TAIWANESE = 'taiwanese',
    TAJIK = 'tajik',
    TANZANIAN = 'tanzanian',
    THAI = 'thai',
    TOGOLESE = 'togolese',
    TONGAN = 'tongan',
    TRINIDADIAN = 'trinidadian',
    TRISTANIAN = 'tristanian',
    TUNISIAN = 'tunisian',
    TURKISH = 'turkish',
    TURKMEN = 'turkmen',
    TURKS_AND_CAICOS_ISLANDER = 'turks and caicos islander',
    TUVALUAN = 'tuvaluan',
    UGANDAN = 'ugandan',
    UKRAINIAN = 'ukrainian',
    URUGUAYAN = 'uruguayan',
    UZBEK = 'uzbek',
    VATICAN_CITIZEN = 'vatican citizen',
    CITIZEN_OF_VANUATU = 'citizen of vanuatu',
    VENEZUELAN = 'venezuelan',
    VIETNAMESE = 'vietnamese',
    VINCENTIAN = 'vincentian',
    WALLISIAN = 'wallisian',
    WELSH = 'welsh',
    YEMENI = 'yemeni',
    ZAMBIAN = 'zambian',
    ZIMBABWEAN = 'zimbabwean',
}
