import React, { useState } from 'react';
import { useTheme, Theme, CSSObject } from '@mui/material/styles';
import { Box, Divider, Drawer, useMediaQuery } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import TodayIcon from '@mui/icons-material/Today';
// import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded';

import { ReactComponent as OrderIcon } from '../../../assets/order-icon.svg';
import { ReactComponent as ProductIcon } from '../../../assets/product-icon.svg';
import LogoSection from '../logo-section';
import NavItem from './nav-item/nav-item';
import { MultiButton } from '../../../components/multi-button';
import { BookingModal } from '../../../components/booking-modal';

type SidebarProps = {
    drawerOpen: boolean;
    drawerToggle: (state: boolean) => void;
};

const openedMixin = (theme: Theme): CSSObject => ({
    width: 224,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

function Sidebar({ drawerOpen, drawerToggle }: SidebarProps) {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [bookingOpen, setBookingOpen] = useState(false);

    // const drawer = (
    //     <>
    //         <Box sx={{ display: { xs: 'block', md: 'none' } }}>
    //             <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
    //                 <LogoSection />
    //             </Box>
    //         </Box>
    //         <MenuList drawerToggle={drawerToggle} />
    //         {/* <BrowserView> */}
    //         {/* </BrowserView> */}
    //         {/* <MobileView> */}
    //         {/*    <Box sx={{ px: 2 }}> */}
    //         {/*        <MenuList /> */}
    //         {/*    </Box> */}
    //         {/* </MobileView> */}
    //     </>
    // );

    // const container =
    //     window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                width: matchUpMd ? 224 : 'auto',
            }}
            aria-label="mailbox folders"
        >
            <Drawer
                // container={container}
                variant={matchUpMd ? 'persistent' : 'persistent'}
                // anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    ...(drawerOpen ? openedMixin(theme) : closedMixin(theme)),
                    '& .MuiDrawer-paper': {
                        ...(drawerOpen
                            ? openedMixin(theme)
                            : closedMixin(theme)),
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRadius: 0,
                        // borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '64px',
                        },
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                        <LogoSection />
                    </Box>
                </Box>

                <Box>
                    <Box
                        sx={{
                            mx: 2,
                            my: 3,
                        }}
                    >
                        <MultiButton
                            listItems={[
                                {
                                    label: 'New Booking',
                                    Icon: CalendarMonthIcon,
                                    // path: '/booking/add',
                                    onClick: () => setBookingOpen(true),
                                },
                                {
                                    label: 'New Product',
                                    nativeIcon: true,
                                    Icon: ProductIcon,
                                    path: '/products/add',
                                },
                                {
                                    label: 'New Order',
                                    nativeIcon: true,
                                    Icon: OrderIcon,
                                    path: '/order/add',
                                },
                                {
                                    label: 'New Route',
                                    Icon: DirectionsRoundedIcon,
                                    path: '/route/add',
                                },
                                {
                                    label: 'New Customer',
                                    Icon: PersonPinRoundedIcon,
                                    path: '/customer/add',
                                },
                            ]}
                        />
                    </Box>
                    <Divider
                        sx={{
                            mx: 2,
                            '& .MuiDivider-wrapper': {
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#CACEDC',
                            },
                        }}
                    >
                        Scheduling
                    </Divider>
                    <NavItem
                        url="/availability"
                        title="Availability Calendar"
                        icon={CalendarMonthIcon}
                        pattern="/availability/*"
                    />
                    <NavItem
                        url="/events"
                        title="Daily View"
                        icon={TodayIcon}
                        pattern="/events/*"
                    />
                    <Divider
                        sx={{
                            mx: 2,
                            '& .MuiDivider-wrapper': {
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#CACEDC',
                            },
                        }}
                    >
                        Resources
                    </Divider>
                    <NavItem
                        url="/products"
                        title="Products"
                        nativeIcon
                        icon={ProductIcon}
                        pattern="/products/*"
                    />
                    <NavItem
                        url="/orders"
                        title="Orders"
                        nativeIcon
                        icon={OrderIcon}
                        pattern="/orders/*"
                    />
                    <NavItem
                        url="/routes"
                        title="Routes"
                        icon={DirectionsRoundedIcon}
                        pattern="/routes/*"
                    />
                    <NavItem
                        url="/customers"
                        title="Customers"
                        icon={PersonPinRoundedIcon}
                        pattern="/customers/*"
                    />
                </Box>
            </Drawer>
            {bookingOpen && (
                <BookingModal
                    handleCancel={() => setBookingOpen(false)}
                    // handleConfirm={() => {}}
                    open={bookingOpen}
                />
            )}
        </Box>
    );
}

export default Sidebar;
