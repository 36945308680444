import {
    Box,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';
import EastIcon from '@mui/icons-material/East';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export interface FilterListItemProps {
    label: string;
    selected?: boolean;
    onClick?: () => void;
    onRemove?: () => void;
}

export const FilterListItem: React.FC<FilterListItemProps> = props => {
    const { label, selected, onClick, onRemove } = props;

    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                width: '370px',
            }}
            gap={2}
        >
            <ListItemButton
                sx={{
                    borderRadius: '26px',
                    border: '1px solid #DFE1ED',
                    py: '11px',
                    pl: 2,
                    pr: 0.5,
                    my: 1,
                    alignItems: 'flex-start',
                    ...(selected && {
                        border: '1px solid #55B5CF',
                        background: 'rgba(54, 134, 156, 0.07)',
                    }),
                }}
                onClick={e => {
                    e.preventDefault();
                    if (onClick) onClick();
                }}
            >
                <ListItemText
                    sx={{
                        my: 0,
                        '& .MuiTypography-root': {
                            color: '#2B395B',
                            fontSize: '14px',
                            lineHeight: '18px',
                        },
                        borderRight: '#DFE1ED solid 1px',
                    }}
                    primary={<Typography variant="body2">{label}</Typography>}
                />
                <ListItemIcon
                    sx={{
                        ml: 2,
                        my: 0,
                        minWidth: '32px',
                        color: '#7682A4',
                        opacity: 0.5,
                    }}
                >
                    <EastIcon sx={{ fontSize: '18px' }} />
                </ListItemIcon>
            </ListItemButton>
            {selected && (
                <Box sx={{ borderLeft: '#DFE1ED solid 1px', paddingLeft: 1.5 }}>
                    <IconButton size="small" onClick={onRemove}>
                        <RemoveCircleOutlineIcon fontSize="small" />
                    </IconButton>
                </Box>
            )}
        </Stack>
    );
};
