import { object, string } from 'yup';

const schema = object({
    email: string()
        .email('Email must be a valid email')
        .required('Email is a required field'),
    firstName: string().required(),
    lastName: string().required(),
});

export default schema;
