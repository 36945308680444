import { array, object, string } from 'yup';

const schema = object({
    name: string().required('Product Name'),
    description: string().required('Description'),
    languages: array()
        .of(string())
        .min(1, 'At least one selected language')
        .required('At least one selected language'),
});

export default schema;
