import React, { useMemo, useState } from 'react';
import { CapacityOptionTypes, PricingType, Product } from '@travelity/api';
import { Box, Divider, Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { Tag } from '@travelity/ui';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import { durationToString } from '@travelity/utils';
import { useNavigate } from 'react-router-dom';
import { useDeleteProduct } from '@travelity/api/src/queries';
import { SquareCard } from '../square-card';
import { Route } from '../route';
import { ConfirmationDialog } from '../confirmation-dialog';

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export interface ProductItemProps {
    product: Product;
    refetch: () => void;
}

const ProductItem: React.FC<ProductItemProps> = ({ product, refetch }) => {
    const { t } = useTranslation('product');

    const [deletingProduct, setDeletingProduct] = useState<string>();

    const { mutate: removeProduct } = useDeleteProduct({
        onSuccess: () => {
            setDeletingProduct(undefined);
            refetch();
        },
    });

    const duration = durationToString(product.schedule.duration);
    const navigate = useNavigate();

    const minPrice = useMemo(() => {
        const prices = product.financial.items.map(item => {
            const base = item.price.base || 0;
            if (item.type === PricingType.PER_TICKET) {
                return (item.price.perPerson || 0) + base;
            }
            if (item.type === PricingType.PER_PRODUCT) {
                return (item.price.perProduct || 0) + base;
            }
            return (
                Object.values(item.price.counts || {}).reduce(
                    (sum, c) => sum + (c || 0),
                    0
                ) + base
            );
        });

        return Math.min(...prices);
    }, [product]);

    const minPriceFormatted = `${numberWithSpaces(minPrice)} ${(
        product.financial.currency || ''
    )
        .toUpperCase()
        .substring(0, 3)}`;

    const minCapacity = useMemo(() => {
        const capacities = product.capacity.capacities.map(capacity => {
            if (capacity.type === CapacityOptionTypes.FLAT)
                return capacity.max - capacity.min;
            return Object.values(capacity.counts || {}).reduce(
                (sum, c) => sum + c,
                0
            );
        });

        return Math.min(...capacities);
    }, [product]);

    const routs = useMemo(() => {
        const list = [product.route.beginningLocation];

        product.route.stops.forEach(stop => {
            list.push(stop.name);
        });

        list.push(
            product.route.endingLocation || product.route.beginningLocation
        );
        return list;
    }, [product]);

    return (
        <>
            <Stack
                direction="row"
                gap={1}
                sx={{
                    width: 1,
                    height: '185px',
                    alignItems: 'flex-start',
                }}
            >
                <Stack
                    flexGrow={2}
                    sx={{
                        bgcolor: '#FFFFFF',
                        boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                        borderRadius: '12px',
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                >
                    <Stack sx={{ width: '50%' }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                px: 2,
                                height: '46px',
                                py: '10px',
                                bgcolor: '#F4F6FA',
                                borderRadius: '12px 0px 0px 0px',
                            }}
                        >
                            <Box component="span" sx={{ color: '#8F97AA' }}>
                                {product.productInfo.name}
                            </Box>
                            <Tag label="Type" values={[t(product.type)]} />
                        </Stack>
                        <Stack
                            sx={{ grow: 2, p: 2, pt: '10px', height: '139px' }}
                            direction="row"
                            justifyContent="space-evenly"
                            gap={1}
                        >
                            <SquareCard
                                title="Duration"
                                value={duration}
                                Icon={AccessTimeIcon}
                            />
                            <SquareCard
                                title="Min Price"
                                value={minPriceFormatted}
                                Icon={PaymentsOutlinedIcon}
                            />
                            <SquareCard
                                title="Min Capacity"
                                value={`${minCapacity}`}
                                Icon={Diversity3OutlinedIcon}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            width: '50%',
                            borderLeft: '2px solid #C9CEDC',
                            position: 'relative',
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={2}
                            sx={{
                                px: 2,
                                height: '46px',
                                py: '10px',
                                bgcolor: '#F4F6FA',
                                borderRadius: '0px 12px 0px 0px',
                            }}
                        >
                            <DirectionsRoundedIcon sx={{ color: '#8F97AA' }} />
                            <Box component="span" sx={{ color: '#8F97AA' }}>
                                Route
                            </Box>
                        </Stack>
                        <Stack sx={{ height: '139px', overflow: 'hidden' }}>
                            <Route stops={routs} />
                        </Stack>
                        {routs.length > 3 && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: 1,
                                    height: '24px',
                                    background: 'rgba(244, 246, 250, 0.9)',
                                    borderTop: '#C9CEDC solid 1px',
                                    textAlign: 'center',
                                    borderRadius: '0 0 12px 0',
                                    lineHeight: '18px',
                                }}
                            >
                                <Box
                                    onClick={() =>
                                        navigate(`/products/${product.id}`, {
                                            state: 1,
                                        })
                                    }
                                    component="span"
                                    sx={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        color: '#2B395B',
                                    }}
                                >
                                    See All Stops ({routs.length})
                                </Box>
                            </Box>
                        )}
                    </Stack>
                </Stack>
                <Stack
                    flexShrink={0}
                    direction="column"
                    sx={{
                        width: '34px',
                        background: '#FFFFFF',
                        boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                        borderRadius: '12px',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            px: 0.5,
                            py: 1,
                            lineHeight: '12px',
                            color: '#C9CEDC',
                            '&:hover': {
                                color: 'secondary.main',
                            },
                        }}
                        href="#"
                        component="a"
                        onClick={() => navigate(`/products/${product.id}`)}
                    >
                        <VisibilityIcon fontSize="small" />
                    </Box>
                    <Divider sx={{ mx: 1 }} />
                    <Box
                        sx={{
                            textAlign: 'center',
                            px: 0.5,
                            py: 1,
                            lineHeight: '12px',
                            color: '#C9CEDC',
                            '&:hover': {
                                color: 'secondary.main',
                            },
                        }}
                        href="#"
                        component="a"
                        onClick={e => {
                            e.preventDefault();
                            navigate(`/products/add/${product.type}`, {
                                state: {
                                    ...product,
                                    productInfo: {
                                        ...product.productInfo,
                                        name: '',
                                    },
                                },
                            });
                        }}
                    >
                        <ContentCopyRoundedIcon fontSize="small" />
                    </Box>
                    <Divider sx={{ mx: 1 }} />
                    <Box
                        sx={{
                            textAlign: 'center',
                            px: 0.5,
                            py: 1,
                            lineHeight: '12px',
                            color: '#C9CEDC',
                            '&:hover': {
                                color: 'error.main',
                            },
                        }}
                        href="#"
                        component="a"
                        onClick={() => setDeletingProduct(product.id)}
                    >
                        <DeleteIcon fontSize="small" />
                    </Box>
                </Stack>
            </Stack>
            <ConfirmationDialog
                title="Are you sure you want to delete this product?"
                content="This will delete this product permanently. You cannot undo
                    this action."
                confirmText="Delete"
                open={!!deletingProduct}
                handleCancel={() => setDeletingProduct(undefined)}
                handleConfirm={() =>
                    removeProduct({ productId: deletingProduct as string })
                }
            />
        </>
    );
};

export default ProductItem;
