import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Avatar,
    Badge,
    Box,
    Button,
    CardActions,
    ClickAwayListener,
    Divider,
    IconButton,
    Paper,
    Popper,
    Stack,
    useMediaQuery,
} from '@mui/material';

import PostAddIcon from '@mui/icons-material/PostAdd';
import { useOrders } from '@travelity/api';
import MainCard from '../../../../components/main-card/main-card';
import Transitions from '../../../../components/transitions/transitions';

function NotificationSection() {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const { data: orders } = useOrders();
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<HTMLButtonElement | null>(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as Node)
        ) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Box
                sx={{
                    mx: 1,
                    [theme.breakpoints.down('md')]: {
                        mr: 1,
                    },
                }}
            >
                {!!orders?.length && (
                    <IconButton
                        sx={{
                            borderRadius: '12px',
                            bgcolor: '#F4F6FA',
                            '&:hover': {
                                bgcolor: '#EFF8FB',
                            },
                        }}
                        size="large"
                        ref={anchorRef}
                        onClick={handleToggle}
                    >
                        <Badge color="secondary" badgeContent={orders.length}>
                            <PostAddIcon sx={{ color: '#B2B9CD' }} />
                        </Badge>
                    </IconButton>
                )}
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-start'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position="top" in={open} {...TransitionProps}>
                        <Paper sx={{ width: '367px' }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                    sx={{
                                        bgcolor: '#FFF',
                                        '& > div': {
                                            maxHeight: '472px',
                                        },
                                    }}
                                >
                                    <PerfectScrollbar
                                        options={{ suppressScrollX: true }}
                                    >
                                        <Stack
                                            gap={1.5}
                                            sx={{ width: '367px', p: 2 }}
                                        >
                                            {orders?.map((order, index) => (
                                                <React.Fragment key={order.id}>
                                                    {index !== 0 && <Divider />}
                                                    <Stack
                                                        direction="row"
                                                        gap={1.5}
                                                        sx={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                `/order/${order.id}`
                                                            );
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        <Box>
                                                            <Avatar
                                                                sx={{
                                                                    width: '55px',
                                                                    height: '55px',
                                                                    color: '#B2B9CD',
                                                                    fontSize:
                                                                        '20px',
                                                                    fontWeight: 700,
                                                                    bgcolor:
                                                                        '#F4F6FA',
                                                                }}
                                                            >
                                                                {
                                                                    order
                                                                        .participants
                                                                        .customer
                                                                        .firstName[0]
                                                                }
                                                                {
                                                                    order
                                                                        .participants
                                                                        .customer
                                                                        .lastName[0]
                                                                }
                                                            </Avatar>
                                                        </Box>
                                                        <Box>
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    color: '#2B395B',
                                                                }}
                                                            >
                                                                {`${order.participants.customer.firstName} ${order.participants.customer.lastName}`}
                                                            </Box>
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    color: '#959CAD',
                                                                }}
                                                            >
                                                                {' '}
                                                                has a pending
                                                                order with{' '}
                                                            </Box>
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    color: '#2B395B',
                                                                }}
                                                            >
                                                                {
                                                                    order.bookingsCount
                                                                }{' '}
                                                                bookings
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                </React.Fragment>
                                            ))}
                                        </Stack>
                                    </PerfectScrollbar>
                                    <CardActions
                                        sx={{
                                            p: 0.5,
                                            bgcolor: '#F4F6FA',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            sx={{
                                                color: '#2B395B',
                                                textDecorationLine: 'underline',
                                            }}
                                            onClick={() => {
                                                navigate('/orders');
                                                setOpen(false);
                                            }}
                                            disableElevation
                                        >
                                            View All
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
}

export default NotificationSection;
