import React, {
    ReactElement,
    createContext,
    useContext,
    useMemo,
    useState,
    useCallback,
} from 'react';

export interface StepItem {
    value: string;
    label: string;
}

export interface IStepperContext<T> {
    steps: StepItem[];
    activeIndex: number;
    setActiveIndex: (v: number) => void;
    prev: (v?: T) => void;
    next: (v?: T) => void;
    state: T;
    setState: (v: T | ((v: T) => T)) => void;
}

export interface StepperProviderProps<T> {
    children: ReactElement;
    steps: StepItem[];
    initialIndex?: number;
    defaultState: T;
}

const StepperContext = createContext<IStepperContext<any>>({
    steps: [] as StepItem[],
    activeIndex: 0,
    setActiveIndex: () => {},
    prev: () => {},
    next: () => {},
    state: null,
    setState: () => {},
});

export function useStepperContext<T>() {
    return useContext(StepperContext) as IStepperContext<T>;
}

function StepperProvider<T>({
    children,
    steps,
    initialIndex = 0,
    defaultState,
}: StepperProviderProps<T>) {
    const [activeIndex, setActiveIndex] = useState(initialIndex);
    const [state, setState] = useState<T>(defaultState);

    const prev = useCallback(
        (newState?: T) => {
            if (newState) setState(newState);
            setActiveIndex(i => (i === 0 ? 0 : i - 1));
        },
        [steps]
    );

    const next = useCallback(
        (newState?: T) => {
            if (newState) setState(newState);
            setActiveIndex(i => (i + 1 === steps.length ? i : i + 1));
        },
        [steps]
    );

    const context: IStepperContext<T> = useMemo(
        () => ({
            steps,
            activeIndex,
            setActiveIndex,
            prev,
            next,
            state,
            setState,
        }),
        [state, prev, next, steps, activeIndex, setActiveIndex, setState]
    );

    return (
        <StepperContext.Provider value={context}>
            {children}
        </StepperContext.Provider>
    );
}

export default StepperProvider;
