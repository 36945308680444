import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Card,
    Divider,
    Grid,
    Typography,
    Breadcrumbs as MuiBreadcrumbs,
} from '@mui/material';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { MenuItem } from '../../layouts/main-layout/menu-items';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center',
};

interface BreadcrumbsProps {
    card?: boolean;
    divider?: boolean;
    icon?: boolean;
    icons?: boolean;
    maxItems?: number;
    separator?: React.ReactNode;
    navigation?: Record<string, any>;
    rightAlign?: boolean;
    title?: boolean;
    titleBottom?: boolean;
}

function Breadcrumbs({
    card,
    divider,
    icon,
    icons,
    maxItems,
    separator,
    navigation,
    rightAlign,
    title,
    titleBottom,
    ...others
}: BreadcrumbsProps) {
    const theme = useTheme();

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '1rem',
        height: '1rem',
        color: theme.palette.secondary.main,
    };

    const [main, setMain] = useState<MenuItem | undefined>();
    const [item, setItem] = useState<MenuItem | undefined>();

    const getCollapse = (menu: MenuItem) => {
        if (menu.children) {
            menu.children.filter((collapse: MenuItem) => {
                if (collapse.type && collapse.type === 'collapse') {
                    getCollapse(collapse);
                } else if (collapse.type && collapse.type === 'item') {
                    if (document.location.pathname === `/${collapse.url}`) {
                        setMain(menu);
                        setItem(collapse);
                    }
                }
                return false;
            });
        }
    };

    useEffect(() => {
        navigation?.items?.map((menu: MenuItem) => {
            if (menu.type && menu.type === 'group') {
                getCollapse(menu);
            }
            return false;
        });
    });

    let mainContent;
    let itemContent;
    let breadcrumbContent = <Typography />;
    let itemTitle = '';
    let ItemIcon;

    // collapse item
    if (main && main.type === 'collapse') {
        mainContent = (
            <Typography component={Link} to="#" variant="subtitle1" sx={linkSX}>
                {icons && (
                    <Box
                        component={main.icon || AccountTreeTwoToneIcon}
                        style={iconStyle}
                    />
                )}
                {main.title}
            </Typography>
        );
    }

    // items
    if (item && item.type === 'item') {
        itemTitle = item.title || '';

        ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;
        itemContent = (
            <Typography
                variant="subtitle1"
                sx={{
                    display: 'flex',
                    textDecoration: 'none',
                    alignContent: 'center',
                    alignItems: 'center',
                    color: 'grey.500',
                }}
            >
                {icons && <ItemIcon style={iconStyle} />}
                {itemTitle}
            </Typography>
        );

        // main
        if (item.breadcrumbs !== false) {
            breadcrumbContent = (
                <Card
                    sx={{
                        marginBottom: card === false ? 0 : theme.spacing(3),
                        border: card === false ? 'none' : '1px solid',
                        borderColor: theme.palette.primary.main + 75,
                        background:
                            card === false
                                ? 'transparent'
                                : theme.palette.background.default,
                    }}
                    {...others}
                >
                    <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
                        <Grid
                            container
                            direction={rightAlign ? 'row' : 'column'}
                            justifyContent={
                                rightAlign ? 'space-between' : 'flex-start'
                            }
                            alignItems={rightAlign ? 'center' : 'flex-start'}
                            spacing={1}
                        >
                            {title && !titleBottom && (
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        sx={{ fontWeight: 500 }}
                                    >
                                        {item.title}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <MuiBreadcrumbs
                                    sx={{
                                        '& .MuiBreadcrumbs-separator': {
                                            width: 16,
                                            ml: 1.25,
                                            mr: 1.25,
                                        },
                                    }}
                                    separator={separator || '/'}
                                    aria-label="breadcrumb"
                                    maxItems={maxItems || 8}
                                >
                                    <Typography
                                        component={Link}
                                        to="/"
                                        color="inherit"
                                        variant="subtitle1"
                                        sx={linkSX}
                                    >
                                        {icons && (
                                            <HomeTwoToneIcon sx={iconStyle} />
                                        )}
                                        {icon && (
                                            <HomeIcon
                                                sx={{ ...iconStyle, mr: 0 }}
                                            />
                                        )}
                                        {!icon && 'Dashboard'}
                                    </Typography>
                                    {mainContent}
                                    {itemContent}
                                </MuiBreadcrumbs>
                            </Grid>
                            {title && titleBottom && (
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        sx={{ fontWeight: 500 }}
                                    >
                                        {item.title}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    {card === false && divider !== false && (
                        <Divider
                            sx={{
                                borderColor: theme.palette.primary.main,
                                mb: 3,
                            }}
                        />
                    )}
                </Card>
            );
        }
    }

    return breadcrumbContent;
}

Breadcrumbs.defaultProps = {
    card: false,
    divider: false,
    icon: false,
    icons: false,
    maxItems: 8,
    navigation: {},
    rightAlign: false,
    separator: null,
    title: false,
    titleBottom: false,
};

export default Breadcrumbs;
