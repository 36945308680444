import React from 'react';
import { Box, Divider, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NumberField, TextField, CardList, Switch } from '@travelity/form';
import { number, object, string } from 'yup';
import {
    AgeBands,
    CapacityOptionTypes,
    FlatFields,
} from '@travelity/api/src/services/product/product.types';
import { useFormContext } from 'react-hook-form';
import { Tag } from '@travelity/ui';
import InfoIcon from '@mui/icons-material/Info';

const schema = object({
    name: string().required('Name is a required field').uniqName(),
}).when('.type', ([type], sch) => {
    if (type === CapacityOptionTypes.FLAT) {
        return sch.shape({
            min: number().min(1).required('Min is required'),
            max: number().when(['min'], ([min], s) => {
                return min
                    ? s
                          .min(min + 1, 'Maximum should be more then minimum')
                          .required()
                    : s;
            }),
        });
    }
    return sch.shape({
        counts: object().when('.', ([counts], s) => {
            return Object.values(counts).find(v => v)
                ? s
                : s.shape({
                      infants: number()
                          .required('At least one ageband count required')
                          .min(1, 'At least one ageband count required'),
                  });
        }),
    });
});

export interface ProductCapacityFormProps {
    preview?: boolean;
    showInfo?: boolean;
    onInfoClick?: () => void;
}

const ProductCapacityForm: React.FC<ProductCapacityFormProps> = ({
    preview,
    showInfo,
    onInfoClick,
}) => {
    const { t } = useTranslation('common');
    const { watch } = useFormContext();

    return (
        <Stack spacing={2} sx={{ width: '453px' }}>
            <Stack
                direction="row"
                gap={1}
                mr={showInfo ? 0 : '64px'}
                sx={{
                    ...(preview
                        ? {
                              opacity: 0.6,
                              pointerEvents: 'none',
                          }
                        : {}),
                }}
            >
                <Paper sx={{ p: 1 }}>
                    <Switch name="concurrency" label="Concurrency" />
                    <Switch
                        name="concurrencyAuto"
                        label="Auto"
                        disabled={!watch('concurrency')}
                    />
                </Paper>
                <Paper sx={{ p: 1 }}>
                    <Switch name="upgrade" label="Upgrade" />
                    <Switch
                        name="upgradeAuto"
                        label="Auto"
                        disabled={!watch('upgrade')}
                    />
                </Paper>

                {showInfo && (
                    <InfoIcon
                        onClick={onInfoClick}
                        sx={{
                            fontSize: '18px',
                            color: '#DFE1ED',
                            cursor: 'pointer',
                            '&:hover': { color: '#B2B9CD' },
                        }}
                    />
                )}
            </Stack>

            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Options
            </Divider>

            <CardList
                disabled={preview}
                formParams={{ schema }}
                renderHeader={({ editing, item }) =>
                    editing ? (
                        <TextField
                            sx={{ mx: '10px' }}
                            autoFocus
                            InputProps={{
                                sx: { fontSize: '14px', pl: 1.5 },
                            }}
                            placeholder="Type Name"
                            name="name"
                            variant="standard"
                            showErrors
                            helperText=""
                            fullWidth
                        />
                    ) : (
                        <Box component="span">{item.name}</Box>
                    )
                }
                renderContent={({ item, editing }) =>
                    editing ? (
                        <Stack gap={0.5} p={2}>
                            {Object.values(
                                item.type === CapacityOptionTypes.FLAT
                                    ? FlatFields
                                    : AgeBands
                            ).map(name => (
                                <Stack direction="row" alignItems="center">
                                    <Box
                                        sx={{
                                            width: '80px',
                                            color: '#949BAC',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {t(name)}
                                    </Box>
                                    <Box sx={{ flexGrow: 2 }}>
                                        <NumberField
                                            name={
                                                item.type ===
                                                CapacityOptionTypes.FLAT
                                                    ? name
                                                    : `counts.${name}`
                                            }
                                            showErrors
                                            label=""
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>
                                </Stack>
                            ))}
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            gap={1}
                            px={2}
                            py={1}
                            flexWrap="wrap"
                        >
                            {item.type === CapacityOptionTypes.FLAT
                                ? Object.values(FlatFields).map(
                                      name =>
                                          !!item[name] && (
                                              <Tag
                                                  label={t(name)}
                                                  values={[item[name]]}
                                              />
                                          )
                                  )
                                : Object.values(AgeBands).map(
                                      name =>
                                          !!item.counts[name] && (
                                              <Tag
                                                  label={t(name)}
                                                  values={[item.counts[name]]}
                                              />
                                          )
                                  )}
                        </Stack>
                    )
                }
                addButtonText="Add Capacity Option"
                itemOptions={[
                    {
                        label: 'Flat Count',
                        type: CapacityOptionTypes.FLAT,
                        min: 0,
                        max: 0,
                    },
                    {
                        label: 'Ageband Count',
                        type: CapacityOptionTypes.AGE_BAND,
                    },
                ]}
                name="capacities"
            />
        </Stack>
    );
};

export default React.memo(ProductCapacityForm);
