import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './en-US';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        returnNull: false,
        resources: {
            'en-US': enUS,
        },
        defaultNS: 'common',
        lng: 'en-US',
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false,
        },
    });
