import { useController, useFormContext } from 'react-hook-form';
import React, { useMemo } from 'react';
import _ from 'lodash';
import {
    Box,
    FormControl,
    FormLabel,
    InputAdornment,
    OutlinedInput,
    OutlinedInputProps,
    Stack,
} from '@mui/material';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

export type DurationValue = Record<DurationUnits, number>;

enum DurationUnits {
    DAY = 'day',
    HOUR = 'hour',
    MINUTE = 'minute',
}

export type DurationFieldProps = {
    name: string;
    label?: string;
    required?: boolean;
    showErrors?: boolean;
    units?: DurationUnits[];
    InputProps?: OutlinedInputProps;
};
const defaultUnits = [
    DurationUnits.DAY,
    DurationUnits.HOUR,
    DurationUnits.MINUTE,
];

const DurationField: React.FC<DurationFieldProps> = ({
    name,
    required,
    label,
    showErrors,
    units = defaultUnits,
    InputProps = {},
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const defaultValue = useMemo(
        () =>
            units.reduce(
                (obj, cur) => ({
                    ...obj,
                    [cur]: 0,
                }),
                {} as Record<DurationUnits, number>
            ),
        [units]
    );

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue,
    });

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);
    const onUpClick = (s: DurationUnits) => {
        onChange({
            ...value,
            [s]: value[s] + 1,
        });
    };
    const onDownClick = (s: DurationUnits) => {
        onChange({
            ...value,
            [s]: Math.max(value[s] - 1, 0),
        });
    };

    return (
        <>
            {label && (
                <FormLabel
                    sx={{
                        '&&&': { mb: 1 },
                    }}
                    required={required}
                >
                    {label}
                </FormLabel>
            )}
            <Stack direction="row" gap="4px" sx={{ '&&&': { mt: 0 } }}>
                {units.map(unit => (
                    <OutlinedInput
                        key={unit}
                        value={`${value[unit]}${unit[0]}`}
                        {...InputProps}
                        error={!!error}
                        sx={{
                            ...(InputProps.sx || {}),
                            color: '#2B395B',
                            '&&&': { pr: '10px' },
                            '& input': { p: 1.5, pr: 0, width: '26px' },
                        }}
                        endAdornment={
                            <InputAdornment position="end" sx={{ ml: 0 }}>
                                <Stack>
                                    <Box
                                        sx={{
                                            height: '14px',
                                            mt: '4px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => onUpClick(unit)}
                                    >
                                        <ArrowDropUp
                                            sx={{
                                                fontSize: '20px',
                                                color: '#B2B9CD',
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            height: '14px',
                                            mb: '4px',
                                            cursor: value[unit]
                                                ? 'pointer'
                                                : 'default',
                                        }}
                                        onClick={() => onDownClick(unit)}
                                    >
                                        <ArrowDropDown
                                            sx={{
                                                fontSize: '20px',
                                                color: '#B2B9CD',
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </InputAdornment>
                        }
                    />
                ))}
            </Stack>
        </>
    );
};

export default DurationField;
