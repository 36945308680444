import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateProduct, useGetProducts } from '../../queries';
import {
    getProductDtoToProduct,
    productToGetProductDto,
} from './product.converters';
import { Product } from './product.types';
import { GetProductsResDto } from '../../requests';

export { useCreateProduct };

type UseGetProductParamTypes = Parameters<typeof useGetProducts>;
export const useProducts = (
    params: UseGetProductParamTypes[0] = {},
    options: UseGetProductParamTypes[2] = {}
) => {
    const queryKey = ['ProductsServiceGetProducts', params];
    const { data, ...other } = useGetProducts(params, [params], {
        cacheTime: 0,
        ...options,
    });

    const queryClient = useQueryClient();
    const update = (productId: string, product: Product) => {
        queryClient.setQueryData<GetProductsResDto>(queryKey, products => {
            if (!products?.items) return undefined;
            const items = products.items.map(prd =>
                prd.id === productId ? productToGetProductDto(product) : prd
            );
            return { ...products, items };
        });
    };

    const parsedData = useMemo(
        () => data?.items?.map(getProductDtoToProduct),
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

export const useProduct = (id: string) => {
    const { data: products } = useProducts();
    const product = useMemo(() => {
        return products?.find(p => p.id === id);
    }, []);

    return {
        data: product,
    };
};
