import React, { useCallback, useEffect, useMemo } from 'react';
import {
    Box,
    Divider,
    InputAdornment,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    MultiSelect,
    Switch,
    TextField,
    useForm,
    WeekdayPicker,
    Weekdays,
} from '@travelity/form';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import {
    Language,
    User,
    userDetailsToUserDetailsDto,
    userWeekdaysToWorkweek,
} from '@travelity/api';
import { convertEnumToList } from '@travelity/utils';
import { useRegisterUsers, useUpdateUser } from '@travelity/api/src/queries';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getLastCompletedStep } from './register-steps.utils';
import OccupationSelect from './components/occupation-select';
import WeekdayRow from './components/weekday-row';
import { PersonalInfo } from './components/personal-info';

export interface RegisterStepsProps {
    user?: User;
}

export const RegisterSteps: React.FC<RegisterStepsProps> = props => {
    const { user } = props;
    const { user: auth0User } = useAuth0();
    const [currentStep, setCurrentStep] = React.useState(
        getLastCompletedStep(user) + 1
    );

    const languages = useMemo(() => convertEnumToList<Language>(Language), []);
    const { mutate: create } = useRegisterUsers({
        onSuccess: () => {
            setCurrentStep(currentStep + 1);
        },
    });

    const navigate = useNavigate();
    const { mutate: update } = useUpdateUser({
        onSuccess: () => {
            if (currentStep === 7) {
                navigate(0);
            } else {
                setCurrentStep(currentStep + 1);
            }
        },
    });

    const { Form, getValues, setValue, watch } = useForm({
        onSubmit: useCallback(
            (data: Partial<User>) => {
                if (currentStep === 1) {
                    const [first, second, third] = (
                        data.fullName as string
                    ).split(' ');
                    const last = !third ? second : third;
                    const middle = !third ? undefined : second;

                    if (auth0User?.email) {
                        create({
                            requestBody: {
                                contact_details: {
                                    emails: [auth0User.email],
                                },
                                name: {
                                    first,
                                    middle,
                                    last,
                                },
                            },
                        });
                    }
                } else {
                    update({
                        requestBody:
                            currentStep === 2
                                ? {
                                      languages: data.languages,
                                  }
                                : currentStep === 3
                                ? {
                                      occupation: data.occupation,
                                  }
                                : currentStep === 4
                                ? {
                                      app_settings: {
                                          vendibility: {
                                              workload: {
                                                  max_events_per_day:
                                                      data.maxEventsUnlimited
                                                          ? 10000
                                                          : data.maxEvents,
                                                  max_booked_hours_per_day:
                                                      data.maxHoursFullDay
                                                          ? 24
                                                          : data.maxHours,
                                              },
                                          },
                                      },
                                  }
                                : currentStep === 5
                                ? {
                                      app_settings: {
                                          vendibility: {
                                              workweek:
                                                  userWeekdaysToWorkweek(data),
                                          },
                                      },
                                  }
                                : currentStep === 6
                                ? {
                                      app_settings: {
                                          vendibility: {
                                              concurrency: {
                                                  enabled:
                                                      data.concurrencyValue,
                                                  auto: data.concurrencyAuto,
                                              },
                                          },
                                      },
                                  }
                                : userDetailsToUserDetailsDto(data),
                    });
                }
            },
            [currentStep, auth0User]
        ),
        defaultValues: user || {},
        mode: 'onChange',
        validateInitially: true,
    });

    useEffect(() => {
        if (auth0User) {
            const userForm = getValues();
            if (!userForm.emails?.length) {
                setValue('emails', [{ value: auth0User.email as string }]);
            }
        }
    }, [auth0User]);

    const weekdays = watch('weekdays');
    const weekdayHours = watch('weekdayHours');
    useEffect(() => {
        if (weekdays) {
            const values = getValues();
            const currentWeekdayHours = values.weekdayHours;
            const weekdayHoursNew: Partial<{
                [v in Weekdays]: { start: number; end: number }[];
            }> = {};

            weekdays.forEach(w => {
                weekdayHoursNew[w] = currentWeekdayHours?.[w] || [
                    {
                        start: 0,
                        end: 1439,
                    },
                ];
            });

            setValue('weekdayHours', weekdayHoursNew);
        }
    }, [weekdays]);

    return (
        <Box
            sx={{
                bgcolor: '#fff',
                mt: 2,
                height: 'calc(100% - 16px)',
                '> form': { height: 1 },
            }}
        >
            <PerfectScrollbar>
                <Form>
                    <Stack
                        sx={{
                            py: 5,
                            height: 1,
                            minHeight: 'calc(100vh - 84px)',
                        }}
                        gap={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {currentStep === 1 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What is your full name?
                                </Typography>
                                <TextField
                                    name="fullName"
                                    label=""
                                    placeholder="Type Full Name"
                                />
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                            !watch('fullName')?.match(
                                                /\s[a-zA-Z]/
                                            )
                                        }
                                        sx={{
                                            px: 10,
                                            py: 1,
                                            '&&&': { pointerEvents: 'initial' },
                                        }}
                                        endIcon={
                                            !watch('fullName')?.match(
                                                /\s[a-zA-Z]/
                                            ) ? (
                                                <Tooltip
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                borderRadius:
                                                                    '8px',
                                                            },
                                                        },
                                                    }}
                                                    title={
                                                        <>
                                                            The following info
                                                            is required:
                                                            <li>Full Name</li>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        sx={{
                                                            color: '#55B5CF',
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : null
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 2 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What languages you speak?
                                </Typography>
                                <MultiSelect
                                    placeholder="Select Language"
                                    name="languages"
                                    label="Languages"
                                    options={languages}
                                />
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        disabled={!watch('languages')?.length}
                                        sx={{
                                            px: 10,
                                            py: 1,
                                            '&&&': { pointerEvents: 'initial' },
                                        }}
                                        endIcon={
                                            !watch('languages')?.length ? (
                                                <Tooltip
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                borderRadius:
                                                                    '8px',
                                                            },
                                                        },
                                                    }}
                                                    title={
                                                        <>
                                                            The following info
                                                            is required:
                                                            <li>Languages</li>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        sx={{
                                                            color: '#55B5CF',
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : null
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 3 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What do you do?
                                </Typography>
                                <Stack>
                                    <OccupationSelect name="occupation" />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        disabled={!watch('occupation')}
                                        sx={{
                                            px: 10,
                                            py: 1,
                                            '&&&': { pointerEvents: 'initial' },
                                        }}
                                        endIcon={
                                            !watch('occupation') ? (
                                                <Tooltip
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                borderRadius:
                                                                    '8px',
                                                            },
                                                        },
                                                    }}
                                                    title={
                                                        <>
                                                            The following info
                                                            is required:
                                                            <li>Occupation</li>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        sx={{
                                                            color: '#55B5CF',
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : null
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 4 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Workload Settings
                                </Typography>
                                <Box sx={{ width: '388px' }}>
                                    <Divider sx={{ my: 3 }}>Max Events</Divider>
                                    <Box sx={{ px: 1 }}>
                                        <Switch
                                            name="maxEventsUnlimited"
                                            label="Unlimited"
                                            LabelProps={{
                                                sx: {
                                                    color: '#2B395B',
                                                    pl: 1,
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="maxEvents"
                                            label=""
                                            placeholder=""
                                            sx={{ my: 2 }}
                                            disabled={watch(
                                                'maxEventsUnlimited'
                                            )}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Box
                                                            sx={{
                                                                color: '#2B395B',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            EVENTS/DAY
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            regExp={/^(\d+)?$/}
                                        />
                                    </Box>

                                    <Divider sx={{ my: 3 }}>
                                        Max Booked Hours
                                    </Divider>
                                    <Box sx={{ px: 1 }}>
                                        <Switch
                                            name="maxHoursFullDay"
                                            label="Full Day"
                                            LabelProps={{
                                                sx: {
                                                    color: '#2B395B',
                                                    pl: 1,
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="maxHours"
                                            label=""
                                            placeholder=""
                                            sx={{ my: 2 }}
                                            disabled={watch('maxHoursFullDay')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Box
                                                            sx={{
                                                                color: '#2B395B',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            HOURS/DAY
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            regExp={/^(\d+)?$/}
                                        />
                                    </Box>
                                </Box>
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                            (!watch('maxEvents') &&
                                                !watch('maxEventsUnlimited')) ||
                                            (!watch('maxHours') &&
                                                !watch('maxHoursFullDay'))
                                        }
                                        sx={{
                                            px: 10,
                                            py: 1,
                                            '&&&': { pointerEvents: 'initial' },
                                        }}
                                        endIcon={
                                            (!watch('maxEvents') &&
                                                !watch('maxEventsUnlimited')) ||
                                            (!watch('maxHours') &&
                                                !watch('maxHoursFullDay')) ? (
                                                <Tooltip
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                borderRadius:
                                                                    '8px',
                                                            },
                                                        },
                                                    }}
                                                    title={
                                                        <>
                                                            The following info
                                                            is required:
                                                            {!watch(
                                                                'maxEvents'
                                                            ) &&
                                                                !watch(
                                                                    'maxEventsUnlimited'
                                                                ) && (
                                                                    <li>
                                                                        Max
                                                                        Events
                                                                    </li>
                                                                )}
                                                            {!watch(
                                                                'maxHours'
                                                            ) &&
                                                                !watch(
                                                                    'maxHoursFullDay'
                                                                ) && (
                                                                    <li>
                                                                        Max
                                                                        Booked
                                                                        Hours
                                                                    </li>
                                                                )}
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        sx={{
                                                            color: '#55B5CF',
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : null
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 5 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Setup Your Workweek
                                </Typography>

                                <Box sx={{ width: '560px' }}>
                                    <WeekdayPicker
                                        name="weekdays"
                                        chipSx={{
                                            py: '12px',
                                            px: '16px',
                                            borderRadius: '26px',
                                            background: '#F4F6FA',
                                            border: '1px solid transparent',
                                            '&.MuiChip-colorPrimary': {
                                                border: '1px solid #55B5CF',
                                                background: '#DDF0F5',
                                                color: '#2B395B',
                                            },
                                        }}
                                    />
                                    <Divider sx={{ mx: 10, my: 3 }} />
                                    {weekdayHours &&
                                        Object.keys(weekdayHours).map(w => (
                                            <WeekdayRow
                                                key={w}
                                                name={`weekdayHours.${w}`}
                                                weekday={w as Weekdays}
                                            />
                                        ))}
                                </Box>
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        disabled={!watch('weekdays')?.length}
                                        sx={{
                                            px: 10,
                                            py: 1,
                                            '&&&': { pointerEvents: 'initial' },
                                        }}
                                        endIcon={
                                            !watch('weekdays')?.length ? (
                                                <Tooltip
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                borderRadius:
                                                                    '8px',
                                                            },
                                                        },
                                                    }}
                                                    title={
                                                        <>
                                                            The following info
                                                            is required:
                                                            <li>Weekdays</li>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        sx={{
                                                            color: '#55B5CF',
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : null
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 6 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Vendibility Preferences
                                </Typography>

                                <Box
                                    sx={{
                                        width: '364px',
                                        borderRadius: '12px',
                                        background: '#FBFBFE',
                                        p: 3,
                                    }}
                                >
                                    <Switch
                                        name="concurrencyValue"
                                        label="Concurrency"
                                        LabelProps={{
                                            sx: {
                                                color: '#2B395B',
                                                pl: 1,
                                            },
                                        }}
                                    />
                                    <Switch
                                        name="concurrencyAuto"
                                        label="Auto"
                                        LabelProps={{
                                            sx: {
                                                color: '#2B395B',
                                                pl: 1,
                                            },
                                        }}
                                    />
                                </Box>
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                            '&&&': { pointerEvents: 'initial' },
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 7 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Personal Information
                                </Typography>
                                <PersonalInfo
                                    onSkip={() => {
                                        navigate(0);
                                    }}
                                />
                            </>
                        )}
                    </Stack>
                </Form>
            </PerfectScrollbar>
        </Box>
    );
};
