/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DayOfWeek } from './DayOfWeek';

export type WeeklyRecurrenceData = {
    /**
     * Type of the recurrence. In case it is enabled
     */
    type: WeeklyRecurrenceData.type;
    /**
     * indicates whether the schedule is recurring or not
     */
    enabled: boolean;
    /**
     * A list of days of the week when the event is repeated
     */
    days_of_week: Array<DayOfWeek>;
};

export namespace WeeklyRecurrenceData {

    /**
     * Type of the recurrence. In case it is enabled
     */
    export enum type {
        WEEKLY = 'weekly',
    }


}
