import React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { AgeBands } from '@travelity/api';
import { Tag } from '@travelity/ui';
import { EventItem as IEventItem } from '@travelity/api/src/services/event/event.types';
import { format } from 'date-fns';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { has } from 'lodash';
import { EventStatus } from '../event-status';
import { ProductTypeIcon } from '../product-type-icon';

export interface EventItemMinimalProps {
    event: IEventItem;
    selected: boolean;
    onSelect: () => void;
}

const EventItemMinimal: React.FC<EventItemMinimalProps> = ({
    event,
    selected,
    onSelect,
}) => {
    const { t } = useTranslation('product');

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 0.5,
                    ml: 4,
                }}
            >
                <Stack direction="row" gap={1.5} alignItems="center">
                    <Typography
                        sx={{
                            color: '#6B748C',
                            fontSize: '14px',
                            fontWeight: '600',
                        }}
                    >
                        {event.product.name}
                    </Typography>
                    <CircleIcon sx={{ fontSize: '8px', color: '#D9D9D9' }} />
                    <ProductTypeIcon type={event.product.type} />
                    <Typography
                        sx={{
                            ml: '-8px',
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '500',
                        }}
                    >
                        {t(event.product.type)}
                    </Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                    <EventStatus status={event.status} />
                    {event.date.end && (
                        <Chip
                            sx={{
                                color: '#6B748C',
                                bgcolor: '#FFF',
                                borderRadius: '8px',
                                px: 0.5,
                                py: 0.5,
                                boxShadow:
                                    '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                                '& .MuiChip-label': {
                                    pl: 1.5,
                                    pr: 0.5,
                                    lineHeight: '18px',
                                },
                            }}
                            icon={
                                <AccessTimeFilledIcon
                                    sx={{
                                        fontSize: '14px',
                                        color: '#6B748C',
                                    }}
                                />
                            }
                            label={`Ends at ${format(event.date.end, 'HH:mm')}`}
                        />
                    )}
                </Stack>
            </Stack>

            <Stack direction="row">
                <Stack
                    justifyContent="center"
                    sx={{
                        mt: 1,
                    }}
                >
                    <Box
                        onClick={onSelect}
                        sx={{
                            mr: '14px',
                            cursor: 'pointer',
                            width: '18px',
                            height: '18px',
                            borderStyle: 'solid',
                            borderWidth: selected ? '5px' : '2px',
                            borderColor: selected ? '#6B748C' : '#B5B9C5',
                            borderRadius: '50%',
                        }}
                    />
                </Stack>
                <Stack
                    direction="row"
                    gap={1}
                    flexGrow={2}
                    sx={{
                        mt: 1,
                        width: 1,
                        zIndex: 2,
                        alignItems: 'flex-start',
                    }}
                >
                    <Stack
                        flexGrow={2}
                        sx={{
                            zIndex: 3,
                            border: '1px solid transparent',
                            bgcolor: '#FFFFFF',
                            boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                            borderRadius: '12px',
                        }}
                    >
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{ p: 2 }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography
                                    sx={{ color: '#B7BCC7', fontSize: '12px' }}
                                >
                                    Capacity
                                </Typography>
                                {!!event.availableSeats && (
                                    <Tag
                                        label="Available Seats"
                                        values={[`${event.availableSeats}`]}
                                        valueProps={{
                                            sx: { backgroundColor: '#CDEFDB' },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Stack direction="row" gap={1} alignItems="center">
                                {has(
                                    event.capacitySelection.capacity,
                                    'max_count'
                                ) ? (
                                    <>
                                        <Tag
                                            label="Min Count"
                                            values={[
                                                // @ts-ignore
                                                `${event.capacitySelection.capacity.min_count}`,
                                            ]}
                                        />
                                        <Tag
                                            label="Max Count"
                                            values={[
                                                // @ts-ignore
                                                `${event.capacitySelection.capacity.max_count}`,
                                            ]}
                                        />
                                    </>
                                ) : (
                                    Object.values(AgeBands).map(
                                        name =>
                                            // @ts-ignore
                                            !!event.capacitySelection.capacity[
                                                name
                                            ] && (
                                                <Tag
                                                    label={t(name)}
                                                    values={[
                                                        // @ts-ignore
                                                        event.capacitySelection
                                                            .capacity[name],
                                                    ]}
                                                />
                                            )
                                    )
                                )}

                                <Typography
                                    sx={{
                                        borderLeft: '1px solid #C9CEDC',
                                        pl: 1.5,
                                        color: '#B7BCC7',
                                        fontSize: '12px',
                                    }}
                                >
                                    {event.capacitySelection.capacity.name}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
};

export default EventItemMinimal;
