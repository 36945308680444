import React, { useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate } from 'react-router-dom';
import { useDeleteOrder } from '@travelity/api';
import { OrderItem as IOrderItem } from '@travelity/api/src/services/order/order.types';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import OutboundIcon from '@mui/icons-material/Outbound';
import { formatMoney } from '@travelity/utils';
import { ConfirmationDialog } from '../confirmation-dialog';
import { SquareCard } from '../square-card';
import { OrderStatus } from '../order-status';
import { PaymentStatusTag } from '../payment-status';

export interface OrderItemProps {
    order: IOrderItem;
    refetch: () => void;
}

const OrderItem: React.FC<OrderItemProps> = ({ order, refetch }) => {
    // const { t } = useTranslation('order');
    const { financials } = order;

    const [deletingOrder, setDeletingOrder] = useState<string>();
    const { mutate: removeOrder } = useDeleteOrder({
        onSuccess: () => {
            setDeletingOrder(undefined);
            refetch();
        },
    });
    const discountFormatted =
        financials?.price?.discount?.type === 'absolute'
            ? formatMoney(
                  financials.price.discount?.amount,
                  financials.price.currency_conversion?.base_currency.abbr
              )
            : `${financials?.price?.discount?.amount}%`;

    const finalPrice = financials?.price?.price_after_reduction
        ? formatMoney(
              financials?.price?.price_after_reduction,
              financials?.price?.currency_conversion?.base_currency.abbr
          )
        : '';

    const navigate = useNavigate();

    return (
        <>
            <Stack
                direction="row"
                gap={1}
                sx={{
                    width: 1,
                    height: '190px',
                    alignItems: 'flex-start',
                }}
            >
                <Stack
                    flexGrow={2}
                    sx={{
                        bgcolor: '#FFFFFF',
                        boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                        borderRadius: '12px',
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                >
                    <Stack sx={{ width: '50%' }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                px: 2,
                                height: '46px',
                                py: '10px',
                                bgcolor: '#F4F6FA',
                                borderRadius: '12px 0px 0px 0px',
                            }}
                        >
                            <Box component="span" sx={{ color: '#8F97AA' }}>
                                Order #{order.number}
                            </Box>
                        </Stack>
                        <Stack
                            sx={{ grow: 2, p: 2, pt: '10px', height: '144px' }}
                            direction="row"
                            justifyContent="space-evenly"
                            gap={1}
                        >
                            <SquareCard
                                title="Products"
                                value={`${order.productsCount}`}
                                Icon={ListAltIcon}
                            />
                            <SquareCard
                                title="Bookings"
                                value={`${order.bookingsCount}`}
                                Icon={CalendarMonthIcon}
                            />
                            <SquareCard
                                title="Travelers"
                                value={`${order.travelersCount}`}
                                Icon={GroupsIcon}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            width: '50%',
                            borderLeft: '2px solid #C9CEDC',
                            position: 'relative',
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="end"
                            gap={2}
                            sx={{
                                px: 2,
                                height: '46px',
                                py: '10px',
                                bgcolor: '#F4F6FA',
                                borderRadius: '0px 12px 0px 0px',
                            }}
                        >
                            <OrderStatus status={order.status} />
                            {order.financials?.status && (
                                <PaymentStatusTag
                                    status={order.financials?.status}
                                />
                            )}
                        </Stack>
                        <Stack
                            sx={{ height: '144px', overflow: 'hidden', p: 2 }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ mb: 1 }}
                            >
                                <Box
                                    sx={{
                                        color: '#6B748C',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    }}
                                >
                                    Customer
                                </Box>
                                <Box
                                    sx={{
                                        color: '#6B758D',
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                    }}
                                >
                                    {`${order.participants.customer.firstName} ${order.participants.customer.lastName}`}
                                </Box>
                            </Stack>
                            <Divider sx={{ mb: 1 }} />
                            {financials?.price && (
                                <>
                                    {financials?.price
                                        ?.price_before_reduction && (
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            sx={{ mb: 1 }}
                                        >
                                            <Box
                                                sx={{
                                                    color: '#6B748C',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                Total Before Discount
                                            </Box>
                                            <Box
                                                sx={{
                                                    color: '#6B758D',
                                                    textAlign: 'right',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                {formatMoney(
                                                    financials.price
                                                        .price_before_reduction,
                                                    financials.price
                                                        .currency_conversion
                                                        ?.base_currency.abbr
                                                )}
                                            </Box>
                                        </Stack>
                                    )}
                                    {financials.price.discount?.amount && (
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            sx={{ mb: 1 }}
                                        >
                                            <Box
                                                sx={{
                                                    color: '#6B748C',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                Discount
                                            </Box>
                                            <Box
                                                sx={{
                                                    color: '#6B758D',
                                                    textAlign: 'right',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {discountFormatted}
                                            </Box>
                                        </Stack>
                                    )}
                                    <Divider />
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        sx={{ mt: 1, mb: 1.5 }}
                                    >
                                        <Box
                                            sx={{
                                                color: '#6B748C',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                            }}
                                        >
                                            {financials.price.discount?.amount
                                                ? 'Total after discount'
                                                : 'Total'}
                                        </Box>
                                        <Box
                                            sx={{
                                                color: '#2B395B',
                                                textAlign: 'right',
                                                fontSize: '15px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {finalPrice}
                                        </Box>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    flexShrink={0}
                    direction="column"
                    gap={1}
                    sx={{
                        width: '34px',
                    }}
                >
                    <Stack
                        direction="column"
                        sx={{
                            width: '34px',
                            background: '#FFFFFF',
                            boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                            borderRadius: '12px',
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'center',
                                px: 0.5,
                                py: 1,
                                lineHeight: '12px',
                                color: '#37C16E',
                                '&:hover': {
                                    // color: '#164d2c',
                                },
                                cursor: 'default',
                                pointerEvents: 'none',
                            }}
                            href="#"
                            component="a"
                            onClick={() => {}}
                        >
                            <PaymentsOutlinedIcon fontSize="small" />
                        </Box>
                        <Divider sx={{ mx: 1 }} />
                        <Box
                            sx={{
                                textAlign: 'center',
                                px: 0.5,
                                py: 1,
                                lineHeight: '12px',
                                color: '#C9CEDC',
                                '&:hover': {
                                    // color: 'secondary.main',
                                },
                                cursor: 'default',
                                pointerEvents: 'none',
                            }}
                            href="#"
                            component="a"
                            onClick={() => {}}
                        >
                            <CachedIcon fontSize="small" />
                        </Box>
                    </Stack>
                    <Stack
                        direction="column"
                        sx={{
                            width: '34px',
                            background: '#FFFFFF',
                            boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                            borderRadius: '12px',
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'center',
                                px: 0.5,
                                py: 1,
                                lineHeight: '12px',
                                color: '#C9CEDC',
                                '&:hover': {
                                    color: 'secondary.main',
                                },
                            }}
                            href="#"
                            component="a"
                            onClick={() => navigate(`/order/${order.id}`)}
                        >
                            <OutboundIcon fontSize="small" />
                        </Box>
                        <Divider sx={{ mx: 1 }} />
                        <Box
                            sx={{
                                textAlign: 'center',
                                px: 0.5,
                                py: 1,
                                lineHeight: '12px',
                                color: '#C9CEDC',
                                '&:hover': {
                                    color: 'secondary.main',
                                },
                            }}
                            href="#"
                            component="a"
                            onClick={() => navigate(`/orders/${order.id}`)}
                        >
                            <VisibilityIcon fontSize="small" />
                        </Box>
                        <Divider sx={{ mx: 1 }} />
                        <Box
                            sx={{
                                textAlign: 'center',
                                px: 0.5,
                                py: 1,
                                lineHeight: '12px',
                                color: '#C9CEDC',
                                '&:hover': {
                                    // color: 'error.main',
                                },
                                cursor: 'default',
                                pointerEvents: 'none',
                            }}
                            href="#"
                            component="a"
                            onClick={() => setDeletingOrder(order.id)}
                        >
                            <DeleteIcon fontSize="small" />
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <ConfirmationDialog
                title="Are you sure you want to delete this order?"
                content="This will delete this order permanently. You cannot undo
                    this action."
                confirmText="Delete"
                open={!!deletingOrder}
                handleCancel={() => setDeletingOrder(undefined)}
                handleConfirm={() => {
                    removeOrder({ orderId: deletingOrder as string });
                }}
            />
        </>
    );
};

export default OrderItem;
