import React, { useEffect } from 'react';
import { PaymentMethodData } from '@travelity/api';
import { CurrencyField, TagsList, useForm } from '@travelity/form';
import { Box, Paper } from '@mui/material';
import { ConfirmationDialog } from '../../confirmation-dialog';

export interface TransactionModalProps {
    currency: string;
    handleCancel: () => void;
    isLoading?: boolean;
    handleConfirm: (v: { method: PaymentMethodData; amount: number }) => void;
    open: boolean;
    title?: string;
    content?: string;
    confirmText?: string;
}

const paymentMethodOptions = [
    { value: 'cash', label: 'Cash' },
    { value: 'card', label: 'Card' },
    { value: 'transfer', label: 'Transfer' },
];

export const TransactionModal: React.FC<TransactionModalProps> = props => {
    const { handleConfirm, currency, ...other } = props;

    const { Form, getValues, reset, watch } = useForm({
        isModal: true,
        mode: 'onChange',
        defaultValues: { method: undefined, amount: undefined },
    });

    const method = watch('method');
    const amount = watch('amount');

    useEffect(() => {
        reset({});
    }, [other.open]);

    return (
        <ConfirmationDialog
            {...other}
            disableConfirm={!method || !amount}
            handleConfirm={() => {
                const data = getValues();
                if (data.method && data.amount) {
                    handleConfirm(
                        data as unknown as {
                            method: PaymentMethodData;
                            amount: number;
                        }
                    );
                }
            }}
        >
            <Form>
                <Paper
                    sx={{
                        mb: 2,
                        py: 1,
                        px: 2,
                        bgColor: '#F4F6FA',
                        maxWidth: '388px',
                    }}
                >
                    <Box
                        sx={{
                            color: '#2B395B',
                            fontSize: '14px',
                            width: 1,
                            justifyContent: 'space-between',
                            margin: 0,
                        }}
                    >
                        Select Payment Method
                    </Box>

                    <TagsList
                        name="method"
                        selectAll={false}
                        options={paymentMethodOptions}
                    />
                </Paper>

                <CurrencyField
                    label="Amount"
                    placeholder="Type amount"
                    name="amount"
                    currency={currency}
                />
            </Form>
        </ConfirmationDialog>
    );
};
