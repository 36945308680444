import { has } from 'lodash';
import {
    Order,
    OrderItem,
    PriceAgeBand,
    PriceFlat,
    PriceSummary,
} from './order.types';
import {
    ComputePriceBookingsResFinancialsPriceDto,
    GetBookingsResItemDto,
    GetCustomerResItemDto,
    GetOrderResDto,
    GetOrdersResItemDto,
    PricingType,
    UpdateOrderReqDto,
} from '../../requests';
import {
    customerToCustomerDto,
    getCustomerDtoToCustomer,
} from '../customer/customer.converters';
import { getBookingDtoToBooking } from '../booking/booking.converters';

const priceDtoToPrice = (price: any): PriceFlat | PriceAgeBand => {
    const { base_amount: base, ...other } = price;

    return other.unit_amount
        ? {
              type: PricingType.PER_TICKET,
              // @ts-ignore
              count: other.pax_count,
              base,
              // @ts-ignore
              unitPrice: other.unit_amount,
          }
        : {
              type: PricingType.PER_AGEBAND,
              base,
              ...Object.keys(other).reduce(
                  (obj, ageBand) =>
                      // @ts-ignore
                      other[ageBand]
                          ? {
                                ...obj,
                                [ageBand]: {
                                    // @ts-ignore
                                    count: other[ageBand].pax_count,
                                    // @ts-ignore
                                    unitPrice: other[ageBand].unit_amount,
                                },
                            }
                          : obj,
                  {}
              ),
          };
};

export const PriceDtoToPriceSummary = (
    price: ComputePriceBookingsResFinancialsPriceDto
): PriceSummary => {
    const { base_amount: base, ...other } = price.main_price;

    return {
        currency: price.currency.abbr,
        beforeDiscount: price.price_before_reduction,
        final: price.price_after_reduction,
        options: price.option_prices?.map(option => ({
            name: option.name || '',
            price: priceDtoToPrice(option.price),
        })),
        main: has(other, 'unit_amount')
            ? {
                  type: PricingType.PER_TICKET,
                  // @ts-ignore
                  count: other.pax_count,
                  base,
                  // @ts-ignore
                  unitPrice: other.unit_amount,
              }
            : {
                  type: PricingType.PER_AGEBAND,
                  base,
                  ...Object.keys(other).reduce(
                      (obj, ageBand) =>
                          // @ts-ignore
                          other[ageBand]
                              ? {
                                    ...obj,
                                    [ageBand]: {
                                        // @ts-ignore
                                        count: other[ageBand].pax_count,
                                        // @ts-ignore
                                        unitPrice: other[ageBand].unit_amount,
                                    },
                                }
                              : obj,
                      {}
                  ),
              },
    };
};

export const orderToGetOrderItemDto = (
    order: OrderItem
): Omit<GetOrdersResItemDto, 'created'> => ({
    id: order.id,
    number: order.number,
    participants: {
        // @ts-ignore
        customer: customerToCustomerDto(order.participants.customer),
    },
    financials: order.financials,
    status: order.status,
    summary: {
        bookings_count: order.bookingsCount,
        products_count: order.productsCount,
        travelers_count: order.travelersCount,
    },
});

export const patchOrderToOrderDto = (
    order: Partial<OrderItem>
): UpdateOrderReqDto => ({
    participants: order.participants
        ? {
              customer: customerToCustomerDto(order.participants.customer),
          }
        : undefined,
    financials: order.financials,
});

export const getOrderItemDtoToOrder = (
    order: GetOrdersResItemDto
): OrderItem => ({
    id: order.id,
    number: order.number,
    participants: {
        customer: getCustomerDtoToCustomer(
            order.participants.customer as GetCustomerResItemDto
        ),
    },
    // bookings: order.bookings,
    financials: order.financials,
    status: order.status,
    bookingsCount: order.summary?.bookings_count,
    productsCount: order.summary?.products_count,
    travelersCount: order.summary?.travelers_count,
});

export const getOrderDtoToOrder = (order: GetOrderResDto): Order => ({
    id: order.id,
    number: order.number,
    participants: {
        customer: getCustomerDtoToCustomer(
            order.participants.customer as GetCustomerResItemDto
        ),
    },
    // bookings: order.bookings,
    financials: order.financials,
    status: order.status,
    bookings:
        order.bookings?.map(v =>
            getBookingDtoToBooking(v as GetBookingsResItemDto)
        ) || [],
});
