import React, {
    ReactElement,
    createContext,
    useContext,
    useMemo,
    useState,
    useCallback,
} from 'react';

export interface IHelperContext {
    info?: ReactElement;
    close: () => void;
    setInfo: (v?: ReactElement) => void;
}

export interface HelperProviderProps {
    children: ReactElement;
}

const HelperContext = createContext<IHelperContext>({
    close: () => null,
    setInfo: () => null,
});

export function useHelperContext() {
    return useContext(HelperContext) as IHelperContext;
}

function HelperProvider({ children }: HelperProviderProps) {
    const [info, setInfo] = useState<ReactElement>();
    const [open, setOpen] = useState(true);

    const close = useCallback(() => {
        setOpen(false);
    }, []);

    const context = useMemo(
        () => ({
            info: open ? info : undefined,
            close,
            setInfo,
        }),
        [open, info, close, setInfo]
    );

    return (
        <HelperContext.Provider value={context}>
            {children}
        </HelperContext.Provider>
    );
}

export default HelperProvider;
