import React from 'react';
import { BookingStatus } from '@travelity/api';
import { StatusTag, StatusTagStatuses } from '@travelity/ui';
import { useTranslation } from 'react-i18next';

export interface BookingStatusTagProps {
    status: BookingStatus;
}

const statusMap: Record<BookingStatus, StatusTagStatuses> = {
    [BookingStatus.DRAFT]: StatusTagStatuses.ERROR,
    [BookingStatus.HOLD]: StatusTagStatuses.WARNING,
    [BookingStatus.RESERVED]: StatusTagStatuses.SUCCESS,
    [BookingStatus.CANCELLED]: StatusTagStatuses.NEUTRAL,
};

export const BookingStatusTag: React.FC<BookingStatusTagProps> = props => {
    const { t } = useTranslation('order');
    const { status } = props;
    return (
        <StatusTag status={statusMap[status]} value={t(status).toUpperCase()} />
    );
};
