import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Stack,
} from '@mui/material';
import { EventItem } from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import { useForm } from '@travelity/form';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { format } from 'date-fns';
import { travelersToTravelersForm } from '../../../components/booking-travelers/booking-travelers.converters';
import travelersSchema from '../../../components/booking-travelers/booking-travelers.schema';
import { BookingTravelersForm } from '../../../components/booking-travelers';
import productSchema from '../../../components/booking-product/booking-product.schema';
import { BookingProductForm } from '../../../components/booking-product';
import scheduleSchema from '../../../components/booking-schedule/booking-schedule.schema';
import { BookingScheduleForm } from '../../../components/booking-schedule';
import { EventStatus } from '../../../components/event-status';

export interface EventPreviewProps {
    event: EventItem;
    // updateEvent: () => void;
    // updateBooking: (id: string, booking: Booking) => void;
}

const EventPreview: React.FC<EventPreviewProps> = ({
    event,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // updateBooking,
}) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState<number | undefined>(
        location.state || 0
    );

    const [editing, setEditing] = useState(false);
    const toggleExpanded = (number: number) => {
        if (editing) return;
        setExpanded(s => (s === number ? undefined : number));
    };

    useEffect(() => {
        if (location.state && !editing) setExpanded(location.state);
    }, [location.state]);

    // const { enqueueSnackbar } = useSnackbar();
    // const { mutate: update, isLoading: isSaving } = useUpdateBooking({
    //     onSuccess: () => {
    //         setEditing(false);
    //     },
    //     onError: (error: any) => {
    //         if (error?.status === 409) {
    //             enqueueSnackbar('Nothing to save', { variant: 'error' });
    //         } else if (error?.body) {
    //             enqueueSnackbar(error.body, { variant: 'error' });
    //         }
    //     },
    // });
    // const onSubmit = useCallback(
    //     (data: any, e?: React.BaseSyntheticEvent) => {
    //         e?.preventDefault();
    //         const key = e?.target.id;
    //         update(
    //             {
    //                 bookingId: booking.id,
    //                 requestBody: patchBookingToBookingDto({
    //                     [key]:
    //                         // eslint-disable-next-line no-nested-ternary
    //                         key === 'travelers'
    //                             ? travelersFormToTravelers(data)
    //                             : key === 'notes'
    //                             ? data.notes?.map(
    //                                   ({ value }: { value: string }) => value
    //                               )
    //                             : data,
    //                 }),
    //             },
    //             {
    //                 onSuccess: () => {
    //                     updateBooking();
    //                 },
    //             }
    //         );
    //     },
    //     [booking]
    // );
    const {
        Form: ScheduleForm,
        reset: resetSchedule,
        // formState: { errors: scheduleErrors },
    } = useForm({
        defaultValues: {
            start: event.date.start,
        },
        // onSubmit,
        mode: 'onChange',
        schema: scheduleSchema,
    });
    const {
        Form: ProductForm,
        reset: resetProduct,
        // formState: { errors: productErrors },
    } = useForm({
        defaultValues: { values: [] },
        // onSubmit,
        mode: 'onChange',
        schema: productSchema,
    });

    const {
        Form: TravelersForm,
        reset: resetTravelers,
        // formState: { errors: travelersErrors },
    } = useForm({
        defaultValues: travelersToTravelersForm(
            event.bookingsSummary?.travelers_summary?.travelers || []
        ),
        // onSubmit,
        mode: 'onChange',
        schema: travelersSchema,
    });

    const reset = useCallback(() => {
        switch (expanded) {
            case 0:
                resetSchedule({
                    start: event.date.start,
                });
                break;
            case 1:
                resetProduct({ values: [] });
                break;
            case 2: {
                resetTravelers(
                    travelersToTravelersForm(
                        event.bookingsSummary?.travelers_summary?.travelers ||
                            []
                    )
                );
                break;
            }
            default:
        }
    }, [expanded, event]);

    useEffect(() => {
        reset();
        setEditing(false);
    }, [event]);

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: 'calc(100% - 30px)',
                },
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '8px 18px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '0px 0px 12px 0px',
                        background: '#6B748C',
                        color: '#FFF',
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '100%',
                        textTransform: 'capitalize',
                    }}
                >
                    Event Preview
                </Box>
            </Stack>
            <PerfectScrollbar>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={0.75}
                    sx={{
                        px: 2,
                        height: '46px',
                        py: '10px',
                        borderRadius: '0px 12px 0px 0px',
                    }}
                >
                    <EventStatus status={event.status} />
                    {event.date.end && (
                        <Chip
                            sx={{
                                color: '#6B748C',
                                bgcolor: '#FFF',
                                borderRadius: '8px',
                                px: 0.5,
                                py: 0.5,
                                boxShadow:
                                    '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                                '& .MuiChip-label': {
                                    pl: 1.5,
                                    pr: 0.5,
                                    lineHeight: '18px',
                                },
                            }}
                            icon={
                                <AccessTimeFilledIcon
                                    sx={{ fontSize: '14px', color: '#6B748C' }}
                                />
                            }
                            label={`Ends at ${format(event.date.end, 'HH:mm')}`}
                        />
                    )}
                    {event.ephemeral && (
                        <Chip
                            label="Virtual"
                            sx={{
                                borderRadius: '8px',
                                background: '#EC8031',
                                padding: '6px 8px',
                                color: '#FFF',
                                fontSize: '14px',
                            }}
                        />
                    )}
                </Stack>
                <Box
                    sx={{
                        '&&& .MuiAccordionSummary-root': {
                            borderBottom: '1px solid #DFE1ED',
                        },
                        '&&& .MuiAccordionDetails-root': {
                            py: 3,
                        },
                    }}
                >
                    <ScheduleForm id="schedule">
                        <Accordion
                            disableGutters
                            expanded={expanded === 0}
                            onChange={() => toggleExpanded(0)}
                        >
                            <AccordionSummary>Date and Time</AccordionSummary>
                            <AccordionDetails>
                                <BookingScheduleForm
                                    date={{
                                        start: event.date.start.getTime(),
                                        end: event.date.end?.getTime(),
                                    }}
                                    preview={!editing || expanded !== 0}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ScheduleForm>
                    <ProductForm id="product">
                        <Accordion
                            disableGutters
                            expanded={expanded === 1}
                            onChange={() => toggleExpanded(1)}
                        >
                            <AccordionSummary>Product</AccordionSummary>
                            <AccordionDetails>
                                <BookingProductForm
                                    product={event.product}
                                    preview={!editing || expanded !== 1}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ProductForm>
                    <TravelersForm id="travelers">
                        <Accordion
                            disableGutters
                            expanded={expanded === 2}
                            onChange={() => toggleExpanded(2)}
                        >
                            <AccordionSummary>
                                Travelers
                                {/* {expanded === 2 && ( */}
                                {/*    <AccordionButtons */}
                                {/*        editing={editing} */}
                                {/*        saving={isSaving} */}
                                {/*        errors={travelersErrors} */}
                                {/*        onEditCancel={onCancel} */}
                                {/*        onEdit={() => setEditing(true)} */}
                                {/*    /> */}
                                {/* )} */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <BookingTravelersForm
                                    eventId={event.id}
                                    // preview={!editing || expanded !== 2}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </TravelersForm>
                </Box>
            </PerfectScrollbar>
        </Box>
    );
};

export default React.memo(EventPreview);
