import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

export interface ProductItemProps {}

const ProductItemSkeleton: React.FC<ProductItemProps> = () => {
    return (
        <Stack
            direction="row"
            gap={1}
            sx={{
                width: 1,
                height: '185px',
                alignItems: 'flex-start',
            }}
        >
            <Stack
                flexGrow={2}
                sx={{
                    bgcolor: '#FFFFFF',
                    boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                    borderRadius: '12px',
                }}
                direction="row"
                justifyContent="space-evenly"
            >
                <Stack sx={{ width: '50%' }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            px: 2,
                            height: '46px',
                            py: '10px',
                            bgcolor: '#F4F6FA',
                            borderRadius: '12px 0px 0px 0px',
                        }}
                    >
                        <Skeleton width={150} sx={{ fontSize: '24px' }} />
                        <Skeleton width={60} sx={{ fontSize: '24px' }} />
                    </Stack>
                    <Stack
                        sx={{ grow: 2, p: 2, pt: '10px', height: '139px' }}
                        direction="row"
                        justifyContent="space-evenly"
                        gap={1}
                    >
                        <Skeleton
                            variant="rectangular"
                            width="33%"
                            height={100}
                        />
                        <Skeleton
                            variant="rectangular"
                            width="33%"
                            height={100}
                        />
                        <Skeleton
                            variant="rectangular"
                            width="33%"
                            height={100}
                        />
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        width: '50%',
                        borderLeft: '2px solid rgba(201, 206, 220, 0.5)',
                        position: 'relative',
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                        sx={{
                            px: 2,
                            height: '46px',
                            py: '10px',
                            bgcolor: '#F4F6FA',
                            borderRadius: '0px 12px 0px 0px',
                        }}
                    >
                        <Skeleton variant="circular" width={24} height={24} />
                        <Box
                            component="span"
                            sx={{
                                color: '#8F97AA',
                            }}
                        >
                            <Skeleton width={90} sx={{ fontSize: '24px' }} />
                        </Box>
                    </Stack>
                    <Box sx={{ p: 2 }}>
                        <Skeleton width={120} />
                        <Skeleton width={100} />
                        <Skeleton width={140} />
                    </Box>
                </Stack>
            </Stack>
            <Stack
                flexShrink={0}
                direction="column"
                sx={{
                    width: '34px',
                    background: '#FFFFFF',
                    boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                    borderRadius: '12px',
                    p: '6px',
                }}
                gap={0.5}
            >
                <Skeleton variant="circular" width={22} height={22} />
                <Skeleton variant="circular" width={22} height={22} />
                <Skeleton variant="circular" width={22} height={22} />
            </Stack>
        </Stack>
    );
};

export default ProductItemSkeleton;
