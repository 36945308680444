import React, { ReactElement, createContext, useContext } from 'react';

export interface NestedFormProviderProps {
    children: ReactElement;
    submit?: (v?: any) => void;
}

const NestedFormContext = createContext<{ submit?: (v?: any) => void }>({});

export const useIsFormNested = () => {
    return !!useContext(NestedFormContext).submit;
};

export const useFormSubmit = () => {
    const context = useContext(NestedFormContext);

    return context?.submit;
};

const NestedFormProvider: React.FC<NestedFormProviderProps> = ({
    children,
    submit,
}) => {
    return (
        <NestedFormContext.Provider value={{ submit }}>
            {children}
        </NestedFormContext.Provider>
    );
};

export default NestedFormProvider;
