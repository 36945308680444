import React from 'react';
import { useStepperContext } from '../../../components/stepper/stepper.provider';
import ProductDetailsStep from './product-details-step';
import RouteStep from './route-step';
import CapacityStep from './capacity-step';
import ScheduleStep from './schedule-step';
import FinancingStep from './financing-step';
import OptionsStep from './options-step';
import PreviewStep from './preview-step';

export interface StepsProps {}

const Steps: React.FC<StepsProps> = () => {
    const { activeIndex, steps } = useStepperContext();
    const activeStep = steps[activeIndex];

    return (
        <>
            {activeStep.value === 'product-details' && <ProductDetailsStep />}
            {activeStep.value === 'route' && <RouteStep />}
            {activeStep.value === 'capacity' && <CapacityStep />}
            {activeStep.value === 'schedule' && <ScheduleStep />}
            {activeStep.value === 'financial-information' && <FinancingStep />}
            {activeStep.value === 'product-options' && <OptionsStep />}
            {activeStep.value === 'preview' && <PreviewStep />}
        </>
    );
};

export default React.memo(Steps);
