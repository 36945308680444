import { Controller, useController, useFormContext } from 'react-hook-form';
import React from 'react';
import {
    OutlinedInputProps,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';

export type NumberFieldProps = OutlinedInputProps & {
    name: string;
    label: string;
    showErrors?: boolean;
    required?: boolean;
};

const numberCheck = (val: string | number) =>
    String(val).match(/[0-9]+/) && val < 1000 && val >= 0;

const NumberField: React.FC<NumberFieldProps> = ({
    name,
    required,
    label,
    showErrors,
    ...rest
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);
    const { field } = useController({ name, control });

    return (
        <OutlinedInput
            error={!!error}
            disabled={false}
            inputProps={{
                min: 0,
                max: 99,
                sx: {
                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                        {
                            '-webkit-appearance': 'none',
                            margin: 0,
                        },
                    '-moz-appearance': 'textfield',

                    textAlign: 'center',
                    fontWeight: '700',
                },
            }}
            type="number"
            startAdornment={
                <InputAdornment position="start">
                    <IconButton
                        tabIndex={-1}
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => {
                            if (numberCheck(field.value - 1)) {
                                field.onChange(Number(field.value - 1));
                            }
                        }}
                        edge="start"
                    >
                        <RemoveIcon sx={{ color: '#B2B9CD' }} />
                    </IconButton>
                </InputAdornment>
            }
            value={field.value || ''}
            onChange={e => {
                if (numberCheck(e.target.value)) {
                    field.onChange(Number(e.target.value));
                } else if (e.target.value === '') {
                    field.onChange('');
                }
            }}
            {...rest}
            sx={{
                ...(rest.sx || {}),
                '&&&': {
                    px: rest.size === 'small' ? '8px' : '12px',
                },
                '&::before, &::after': {
                    border: 'none!important',
                },
            }}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        size="small"
                        onClick={() => {
                            if (numberCheck(field.value + 1) || !field.value) {
                                field.onChange(
                                    Number(field.value ? field.value + 1 : 1)
                                );
                            }
                        }}
                        edge="end"
                    >
                        <AddIcon sx={{ color: '#B2B9CD' }} />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};

export default NumberField;
