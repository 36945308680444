import items from './items';
// import utilities from './utilities';
import { MenuItem } from './types';

const menuItems: { items: MenuItem[] } = {
    items: [...items],
};

export * from './types';
export default menuItems;
