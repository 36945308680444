import React from 'react';
import { Stack, Typography } from '@mui/material';

export interface SingleLineKeyValueProps {
    dark?: boolean;
    darkValue?: boolean;
    label: string;
    value: string;
    onValueClick?: () => void;
}

export const SingleLineKeyValue: React.FC<SingleLineKeyValueProps> = props => {
    const { dark, label, value, darkValue, onValueClick } = props;

    return (
        <Stack direction="row" gap={0.5} justifyContent="space-between">
            <Typography
                sx={{
                    pt: 1,
                    color: dark ? '#2B395B' : '#9198AA',
                    fontSize: '14px',
                }}
            >
                {label}
            </Typography>

            <Typography
                sx={{
                    textAlign: 'right',
                    pt: 1,
                    color: dark || darkValue ? '#2B395B' : '#9198AA',
                    fontSize: '14px',
                    ...(onValueClick
                        ? {
                              textDecoration: 'underline',
                              cursor: 'pointer',
                          }
                        : {}),
                }}
                onClick={onValueClick}
            >
                {value}
            </Typography>
        </Stack>
    );
};
