import React from 'react';
import { AddProductForm } from '@travelity/api';
import { Box, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import Stepper from '../../components/stepper/stepper';
import StepperProvider from '../../components/stepper/stepper.provider';
import Steps from './components/steps';
import HelperProvider from '../../contexts/helper-context/helper-provider';

const steps = [
    {
        label: 'Product Details',
        value: 'product-details',
    },
    {
        label: 'Route',
        value: 'route',
    },
    {
        label: 'Capacity',
        value: 'capacity',
    },
    {
        label: 'Schedule',
        value: 'schedule',
    },
    {
        label: 'Financial Information',
        value: 'financial-information',
    },
    {
        label: 'Product Options',
        value: 'product-options',
    },
    {
        label: 'Preview',
        value: 'preview',
    },
];

function AddProduct() {
    const location = useLocation();

    return (
        <StepperProvider<Partial<AddProductForm>>
            steps={steps}
            defaultState={location.state || {}}
        >
            <HelperProvider>
                <Stack direction="row" sx={{ height: 1 }} spacing={1} p={2}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            bgcolor: '#FFFFFF',
                            p: 3,
                            pr: 2,
                            overflow: 'auto',
                            position: 'relative',
                        }}
                    >
                        <Steps />
                    </Box>
                    <Box
                        sx={{
                            p: 0.5,
                            flexShrink: 0,
                            width: 260,
                            bgcolor: '#FFFFFF',
                            overflow: 'auto',
                        }}
                    >
                        <PerfectScrollbar>
                            <Stepper />
                        </PerfectScrollbar>
                    </Box>
                </Stack>
            </HelperProvider>
        </StepperProvider>
    );
}

export default AddProduct;
