import { styled } from '@mui/material/styles';

export const UncheckedIcon = styled('span')(() => ({
    borderRadius: 4,
    border: '1px solid #DDDEE0',
    width: 20,
    height: 20,
    backgroundColor: '#FFFFFF',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
}));

export const CheckedIcon = styled(UncheckedIcon)({
    '&:before': {
        display: 'block',
        width: 19,
        height: 19,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage:
            'url(\'data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="12" height="12" rx="3" fill="%23DDDEE0"/></svg>\')',
        content: '""',
    },
});
