import { array, object } from 'yup';

const schema = object({
    capacities: array()
        .of(object())
        .min(1, 'At least one capacity should be defined')
        .notInEdit('Capacity item(s) are in edit mode'),
});

export default schema;
