import React, { forwardRef } from 'react';
import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

export interface DailyViewHeaderProps {
    date: Date;
    sx?: Record<string, any>;
}

export const DailyViewHeader = forwardRef<HTMLDivElement, DailyViewHeaderProps>(
    (props, ref) => {
        const { date, sx } = props;

        return (
            <Stack ref={ref} sx={sx} data-day={date.getTime()}>
                <Typography
                    sx={{
                        color: '#9198AA',
                        fontSize: '12px',
                    }}
                >
                    {format(date, 'EEEE')}
                </Typography>
                <Typography
                    sx={{
                        color: '#B2B9CD',
                        fontSize: '20px',
                        fontWeight: '700',
                    }}
                >
                    {format(date, 'dd MMMM yyyy')}
                </Typography>
            </Stack>
        );
    }
);
