import React, { useMemo } from 'react';
import { Box, Chip, ChipProps, Divider, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useController, useFormContext } from 'react-hook-form';

export interface TagDropdownProps extends Partial<ChipProps> {
    label: string;
    name: string;
    options: { value: string | number; label: string }[];
}

const TagDropdown: React.FC<TagDropdownProps> = ({
    label,
    options,
    name,
    ...rest
}) => {
    const { control } = useFormContext();
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
    });

    const selectedValue = useMemo(() => {
        return options?.find(o => o.value === value)?.label;
    }, [value, options]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Chip
            {...rest}
            sx={{
                ...(rest.sx || {}),
                ...(label
                    ? {
                          '& .MuiChip-label': {
                              textWrap: 'nowrap',
                              mt: '-4px',
                          },
                      }
                    : {
                          color: '#9198AA',
                          background: 'rgba(85, 181, 207, 0.2)',
                          p: '8px 10px',
                          fontSize: '14px',
                          boxShadow: 0,
                      }),
            }}
            variant="outlined"
            color="primary"
            size="small"
            label={
                <>
                    <Box sx={{ px: 0.5, fontWeight: 400 }} component="span">
                        {label}
                    </Box>
                    <Box
                        component="span"
                        onClick={handleClick}
                        sx={{
                            background: 'rgba(85, 181, 207, 0.2)',
                            color: '#9198AA',
                            lineHeight: '14px',
                            fontSize: '14px',
                            fontWeight: 400,
                            display: 'inline-flex',
                            textAlign: 'center',
                            borderRadius: '12px',
                            minWidth: '25px',
                            height: '22px',
                            cursor: 'pointer',
                            py: 0.5,
                            px: 1,
                            ml: 0.5,
                            mt: 0.5,
                        }}
                    >
                        <span>{selectedValue}</span>
                        <KeyboardArrowDownIcon
                            sx={{
                                fontSize: '14px',
                                ml: '3px',
                            }}
                        />
                    </Box>

                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        PaperProps={{ sx: { mt: '6px' } }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {options.map((o, index) => (
                            <React.Fragment key={o.value}>
                                {!!index && (
                                    <Divider
                                        sx={{ mx: 2, '&&&': { my: 0.5 } }}
                                    />
                                )}

                                <MenuItem
                                    sx={{
                                        justifyContent: 'start',
                                        color: '#2B395B',
                                        fontSize: '12px',
                                        px: 1,
                                        margin: '0 8px',
                                        borderRadius: '4px',
                                        '&:before': {
                                            borderTop: index
                                                ? '1px solid #DFE1ED'
                                                : 0,
                                        },
                                        '&:hover': {
                                            backgroundColor: '#D7E7EB',
                                        },
                                    }}
                                    onClick={() => {
                                        onChange(o.value);
                                        handleClose();
                                    }}
                                >
                                    {o.label}
                                </MenuItem>
                            </React.Fragment>
                        ))}
                    </Menu>
                </>
            }
        />
    );
};

export default React.memo(TagDropdown);
