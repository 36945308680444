import React from 'react';
import Timeline, { TimelineProps } from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import Dashes from './assets/dashes.svg';
import DashesGrey from './assets/dashes-grey.svg';

export interface RouteProps {
    stops: string[];
    timelineProps?: Omit<Partial<TimelineProps>, 'ref' | 'sx'> & {
        sx?: SystemStyleObject<Theme>;
    };
    color?: 'grey' | 'primary';
}

const Route: React.FC<RouteProps> = ({
    stops,
    timelineProps = {},
    color = 'primary',
}) => {
    return (
        <Timeline
            {...timelineProps}
            sx={{
                '&&': timelineProps.sx || {},
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}
        >
            {stops.map((stop, i) => (
                <TimelineItem
                    key={`${stop}${i === 0 ? 'start' : ''}`}
                    sx={{
                        minHeight: '26px',
                    }}
                >
                    <TimelineSeparator>
                        <TimelineDot
                            color={color}
                            sx={{
                                p: '2px',
                                mt: '4px',
                                mb: '1.5px',
                                boxShadow: 0,
                            }}
                        />
                        {i !== stops.length - 1 && (
                            <TimelineConnector
                                sx={{
                                    // borderLeft: '2px dashed #55B5CF',
                                    backgroundColor: 'transparent',
                                    backgroundSize: 'contain',
                                    backgroundImage: `url(${
                                        color === 'grey' ? DashesGrey : Dashes
                                    })`,
                                }}
                            />
                        )}
                    </TimelineSeparator>
                    <TimelineContent
                        sx={{
                            color: '#6B758D',
                            pt: 0,
                            lineHeight: 1,
                            pb: i === stops.length - 2 ? 0 : 1,
                        }}
                    >
                        {i === 0 && (
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    color: '#C0C4CE',
                                    p: 0,
                                }}
                            >
                                Beginning of route
                            </Typography>
                        )}
                        <Typography
                            sx={{
                                fontSize: '14px',
                                lineHeight: '14px',
                                p: 0,
                            }}
                        >
                            {stop}
                        </Typography>
                        {i === stops.length - 2 && (
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    color: '#C0C4CE',
                                    mt: 1,
                                    p: 0,
                                }}
                            >
                                End of route
                            </Typography>
                        )}
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default Route;
