import * as React from 'react';
import MuiStepper from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useStepperContext } from './stepper.provider';

// interface Step {
//     label: string;
//     value: string;
//     isCompleted?: boolean;
// }

export interface StepperProps {}

const Stepper: React.FC<StepperProps> = () => {
    const { steps, activeIndex } = useStepperContext();

    return (
        <MuiStepper
            activeStep={activeIndex}
            orientation="vertical"
            sx={{ pt: 2.5 }}
        >
            {steps.map((step, index) => (
                <MuiStep key={step.label} completed={false}>
                    <StepLabel
                        classes={{
                            label:
                                index < activeIndex ? 'completed' : undefined,
                            iconContainer:
                                index < activeIndex ? 'completed' : undefined,
                        }}
                    >
                        {step.label}
                    </StepLabel>
                </MuiStep>
            ))}
        </MuiStepper>
    );
};

export default Stepper;
