/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductType {
    PRIVATE_TOUR = 'private_tour',
    GROUP_TOUR = 'group_tour',
    TRANSFER = 'transfer',
    TOUR_PACKAGE = 'tour_package',
}
