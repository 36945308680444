import React, { ReactNode, useState } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

export interface CarouselOption {
    value: string | number;
    label: string;
    children: ReactNode;
}

export interface CarouselProps {
    options: CarouselOption[];
    inPaper?: boolean;
}

const Carousel: React.FC<CarouselProps> = props => {
    const { options, inPaper } = props;

    const [selected, setSelected] = useState<number>(0);
    const { children } = options[selected] || {};

    return (
        <>
            <Stack
                sx={{
                    borderRadius: '6px',
                    background: inPaper ? undefined : '#FFF',
                    p: '6px',
                }}
                component={inPaper ? Paper : 'div'}
                direction="row"
                justifyContent="space-between"
            >
                {selected === 0 ? (
                    <Box
                        sx={{
                            borderRadius: '6px',
                            color: '#FEFEFE',
                            fontSize: '14px',
                            lineHeight: '14px',
                            background: '#B2B9CD',
                            padding: '4px 12px',
                            cursor: 'default',
                        }}
                        component="span"
                    >
                        {options[0]?.label}
                    </Box>
                ) : (
                    <Box
                        component="span"
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: '#959CAD',
                            fontSize: '14px',
                            lineHeight: '14px',
                            padding: '4px 12px',
                        }}
                        onClick={() => setSelected(selected - 1)}
                    >
                        {options[selected - 1]?.label}
                    </Box>
                )}

                <Stack
                    direction={selected ? 'row' : 'row-reverse'}
                    gap={1}
                    alignItems="center"
                >
                    <CircleIcon sx={{ fontSize: '8px', color: '#CACEDC' }} />
                    <CircleIcon sx={{ fontSize: '8px', color: '#B2B9CD' }} />
                </Stack>

                {selected === options.length - 1 ? (
                    <Box
                        sx={{
                            borderRadius: '6px',
                            color: '#FEFEFE',
                            fontSize: '14px',
                            lineHeight: '14px',
                            background: '#B2B9CD',
                            padding: '4px 12px',
                            cursor: 'default',
                        }}
                        component="span"
                    >
                        {options[selected]?.label}
                    </Box>
                ) : (
                    <Box
                        component="span"
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: '#959CAD',
                            fontSize: '14px',
                            lineHeight: '14px',
                            padding: '4px 12px',
                        }}
                        onClick={() => setSelected(selected + 1)}
                    >
                        {options[selected + 1]?.label}
                    </Box>
                )}
            </Stack>
            {children}
        </>
    );
};

export default Carousel;
