/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Language {
    ARMENIAN = 'armenian',
    ENGLISH = 'english',
    RUSSIAN = 'russian',
    MANDARIN = 'mandarin',
    HINDI = 'hindi',
    SPANISH = 'spanish',
    ITALIAN = 'italian',
    FRENCH = 'french',
    ARABIC = 'arabic',
    BENGALI = 'bengali',
    PORTUGUESE = 'portuguese',
    INDONESIAN = 'indonesian',
    URDU = 'urdu',
    JAPANESE = 'japanese',
    GERMAN = 'german',
    PUNJABI = 'punjabi',
    TURKISH = 'turkish',
    KOREAN = 'korean',
}
