import React, {
    forwardRef,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import Edit from '@mui/icons-material/Edit';
import DoNotDisturbOn from '@mui/icons-material/DoNotDisturbOn';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { string, addMethod, array } from 'yup';
import { useForm, UseFormParams } from '../../use-form';

export interface RenderContentProps {
    editing: boolean;
    item: Record<string, any>;
    listeners?: SyntheticListenerMap[];
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
}

interface CardItemProp {
    index: number;
    onDelete: (i: number) => void;
    onEdit: (i: number) => void;
    onEditSave: (i: number, data: any) => void;
    onEditDiscard: (i: number) => void;
    items: any[];
    item: any;
    placeholder?: boolean;
    listeners?: SyntheticListenerMap[];
    style?: Record<string, string>;
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    editing?: boolean;
    disableReorder?: boolean;
    disableRemove?: boolean;
    renderHeader: (p: RenderContentProps) => ReactElement;
    renderContent: (p: RenderContentProps) => ReactElement;
    headerSx?: Record<string, any>;
    formParams?: Partial<UseFormParams<Record<string, any>>>;
}

addMethod(array, 'notInEdit', function (message, mapper = (a: any) => a) {
    return this.when('$items', (items, sch) => {
        return sch.test('notInEdit', message, function (list) {
            return !list?.filter(obj => obj.isNew || obj.isEdit).length;
        });
    });
});

addMethod(
    string,
    'uniqName',
    function (message = 'Name already in use', mapper = (a: any) => a) {
        return this.when(['$items', 'id'], ([items, id], sch) => {
            const otherItems = items.filter((it: any) => it.id !== id);
            return sch.test('uniqName', message, function (name = '') {
                return !otherItems.find(
                    (it: any) => it.name?.trim() === name.trim()
                );
            });
        });
    }
);

const CardItem = forwardRef<HTMLDivElement, CardItemProp>(
    (
        {
            index,
            onDelete,
            onEdit,
            onEditSave,
            onEditDiscard,
            listeners,
            style,
            items,
            item,
            placeholder,
            setActivatorNodeRef,
            editing,
            renderHeader,
            renderContent,
            headerSx = {},
            formParams = {},
            disableReorder = false,
            disableRemove = false,
        },
        ref
    ) => {
        const onSubmit = useCallback(
            (data: any) => {
                onEditSave(index, data);
            },
            [index, onEditSave]
        );

        const { Form, handleSubmit, reset } = useForm({
            onSubmit,
            ...formParams,
            context: { items, ...(formParams.context || {}) },
        });
        const submit = handleSubmit(data => {
            onEditSave(index, data);
        });
        useEffect(() => {
            reset(item);
        }, [item, reset]);

        const contentRef = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (editing) {
                contentRef.current?.querySelector('input')?.focus();
            }
        }, [editing]);

        // TODO add prevent default on all onClicks
        return (
            <Stack
                ref={ref}
                style={style}
                direction="row"
                gap={1}
                sx={{
                    opacity: placeholder ? 0.2 : 1,
                    width: 1,
                    alignItems: 'flex-start',
                }}
            >
                <Stack
                    flexGrow={2}
                    ref={contentRef}
                    sx={{
                        bgcolor: '#FFFFFF',
                        boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                        borderRadius: '12px',
                    }}
                >
                    <Form>
                        <Box
                            sx={{
                                p: 0.75,
                                color: '#9298ab',
                                bgcolor: '#f7f8fa',
                                height: '36px',
                                fontWeight: 600,
                                borderRadius: '12px 12px 0px 0px',
                                lineHeight: '10px',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                ...headerSx,
                            }}
                        >
                            {!editing && !disableReorder && (
                                <Box
                                    component="span"
                                    ref={setActivatorNodeRef}
                                    sx={{
                                        cursor: 'grab',
                                    }}
                                    {...(listeners || {})}
                                >
                                    <DragIndicator sx={{ color: '#cacedc' }} />
                                </Box>
                            )}
                            {renderHeader({
                                item,
                                editing: !!editing,
                            })}
                        </Box>
                        {renderContent({
                            item,
                            editing: !!editing,
                        })}
                    </Form>
                </Stack>
                <Stack
                    flexShrink={0}
                    direction="column"
                    sx={{
                        width: '34px',
                        background: '#FFFFFF',
                        boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                        borderRadius: '12px',
                    }}
                >
                    {!editing && (
                        <>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    px: 0.5,
                                    py: 1,
                                    lineHeight: '12px',
                                    color: '#C9CEDC',
                                    '&:hover': {
                                        color: 'secondary.main',
                                    },
                                }}
                                href="#"
                                component="a"
                                onClick={() => onEdit(index)}
                            >
                                <Edit fontSize="small" />
                            </Box>
                            {!disableRemove && (
                                <>
                                    <Divider sx={{ mx: 1 }} />
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            px: 0.5,
                                            py: 1,
                                            lineHeight: '12px',
                                            color: '#C9CEDC',
                                            '&:hover': {
                                                color: 'error.main',
                                            },
                                        }}
                                        href="#"
                                        component="a"
                                        onClick={() => onDelete(index)}
                                    >
                                        <DoNotDisturbOn fontSize="small" />
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                    {editing && (
                        <>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    px: 0.5,
                                    py: 1,
                                    lineHeight: '12px',
                                    color: 'success.main',
                                    ':hover': {
                                        color: 'success.dark',
                                    },
                                }}
                                href="#"
                                component="a"
                                onClick={submit}
                            >
                                <Check fontSize="small" />
                            </Box>
                            <Divider sx={{ mx: 1 }} />
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    px: 0.5,
                                    py: 1,
                                    lineHeight: '12px',
                                    color: 'error.main',
                                    ':hover': {
                                        color: 'error.dark',
                                    },
                                }}
                                href="#"
                                component="a"
                                onClick={() => onEditDiscard(index)}
                            >
                                <Close fontSize="small" />
                            </Box>
                        </>
                    )}
                </Stack>
            </Stack>
        );
    }
);

export default CardItem;
