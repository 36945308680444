import { Auth0Provider } from '@auth0/auth0-react';
import { Outlet, useNavigate } from 'react-router-dom';
import React from 'react';

function Auth0ProviderLayout() {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    const onRedirectCallback = () => {
        // const onRedirectCallback = (appState?: AppState) => {
        navigate('/callback');
        // navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience,
                redirect_uri: redirectUri,
            }}
            useCookiesForTransactions
            onRedirectCallback={onRedirectCallback}
        >
            <Outlet />
        </Auth0Provider>
    );
}

export default Auth0ProviderLayout;
