import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

export interface SummaryProps {
    currency: string;
    before: number;
    after: number;
    discountAmount: number;
    discountType: 'absolute' | 'relative';
    isLoading: boolean;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export const Summary: React.FC<SummaryProps> = props => {
    const { before, after, discountAmount, discountType, currency, isLoading } =
        props;

    const discountFormatted =
        discountType === 'absolute'
            ? `${numberWithSpaces(discountAmount)} ${currency}`
            : `${discountAmount}%`;

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Box
                    sx={{
                        color: '#6B748C',
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                >
                    Total Before Discount
                </Box>
                <Box
                    sx={{
                        color: '#2B395B',
                        textAlign: 'right',
                        fontSize: '14px',
                        fontWeight: '600',
                    }}
                >
                    {isLoading ? (
                        <Skeleton width={60} />
                    ) : (
                        `${numberWithSpaces(before)} ${currency}`
                    )}
                </Box>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Box
                    sx={{
                        color: '#6B748C',
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                >
                    Discount
                </Box>
                <Box
                    sx={{
                        color: '#2B395B',
                        textAlign: 'right',
                        fontSize: '14px',
                        fontWeight: '600',
                    }}
                >
                    {isLoading ? <Skeleton width={60} /> : discountFormatted}
                </Box>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Box
                    sx={{
                        color: '#6B748C',
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                >
                    Discounted Amount
                </Box>
                <Box
                    sx={{
                        color: '#2B395B',
                        textAlign: 'right',
                        fontSize: '14px',
                        fontWeight: '600',
                    }}
                >
                    {isLoading ? (
                        <Skeleton width={60} />
                    ) : (
                        `${numberWithSpaces(before - after)} ${currency}`
                    )}
                </Box>
            </Stack>
        </>
    );
};
