import React from 'react';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';

export interface TabProps extends MuiTabProps {}

export const Tab: React.FC<TabProps> = props => {
    const { children, ...rest } = props;

    return (
        <MuiTab
            sx={{
                fontSize: '12px',
                lineHeight: '12px',
                padding: '8px 0',
                minHeight: 'auto',
                color: '#959CAD',
                borderRadius: '6px',
                '&.Mui-selected': {
                    color: '#FFF',
                    background: '#55B5CF',
                },
            }}
            {...rest}
        >
            {children}
        </MuiTab>
    );
};
