/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentMethodData {
    CASH = 'cash',
    CARD = 'card',
    TRANSFER = 'transfer',
    PROMO = 'promo',
    GIFT_CARD = 'gift_card',
}
