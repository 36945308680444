import React, { useState } from 'react';
import {
    Booking,
    BookingStatus,
    convertDurationDtoToDuration,
    useHoldBookingAvailability,
} from '@travelity/api';
import { Box, Divider, Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { format } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { durationToHumanReadable, formatMoney } from '@travelity/utils';
import { StatusTag, StatusTagStatuses, Tag } from '@travelity/ui';
import { formatValue } from '@travelity/form';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
    useAddTransactionBookings,
    useCancelBookings,
} from '@travelity/api/src/queries';
import { TransactionType } from '@travelity/api/src/requests';
import { SquareCard } from '../square-card';
import { ConfirmationDialog } from '../confirmation-dialog';
import { TransactionModal } from './components/transaction-modal';
import { ActionIconButton } from '../action-icon-button';

export interface BookingItemProps {
    booking: Booking;
    isSelected: boolean;
    refetch: () => void;
}

const BookingItem: React.FC<BookingItemProps> = ({
    booking,
    refetch,
    isSelected,
}) => {
    // const { t } = useTranslation('booking');
    const { t: pt } = useTranslation('product');
    const { financials } = booking;

    const [deletingBooking, setDeletingBooking] = useState<string>();

    const [payDraft, setPayDraft] = useState(false);
    const [holdBooking, setHoldBooking] = useState(false);
    const { mutate: holdAvailability, isLoading: isHoldBookingLoading } =
        useHoldBookingAvailability({
            onSuccess: () => {
                setHoldBooking(false);
                refetch();
            },
        });
    const [refundBooking, setRefundBooking] = useState(false);
    const [payBooking, setPayBooking] = useState(false);
    const { mutate: addTransaction, isLoading: isAddTransactionLoading } =
        useAddTransactionBookings({
            onSuccess: () => {
                setRefundBooking(false);
                setPayBooking(false);
                refetch();
            },
        });
    const { mutate: removeBooking, isLoading: isRemoveBookingLoading } =
        useCancelBookings({
            onSuccess: () => {
                setDeletingBooking(undefined);
                refetch();
            },
        });

    const navigate = useNavigate();
    const duration = booking.date.duration
        ? durationToHumanReadable(
              convertDurationDtoToDuration(booking.date.duration)
          )
        : '';

    const discountFormatted =
        financials.price.discount?.type === 'absolute'
            ? formatMoney(
                  financials.price.discount?.amount,
                  financials.price.currency.abbr
              )
            : `${financials.price.discount?.amount}%`;

    const finalPrice = financials.price
        ? formatMoney(
              financials.price.price_after_reduction,
              financials.price.currency.abbr
          )
        : '';

    return (
        <>
            <Stack
                direction="row"
                gap={1}
                sx={{
                    width: 1,
                    height: '190px',
                    alignItems: 'flex-start',
                }}
            >
                <Stack
                    flexGrow={2}
                    sx={{
                        border: isSelected
                            ? '1px solid #55B5CF'
                            : '1px solid transparent',
                        bgcolor: '#FFFFFF',
                        boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.3)',
                        borderRadius: '12px',
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                >
                    <Stack sx={{ width: '50%' }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                px: 2,
                                height: '46px',
                                py: '10px',
                                bgcolor: '#F4F6FA',
                                borderRadius: '12px 0px 0px 0px',
                            }}
                        >
                            <Box component="span" sx={{ color: '#8F97AA' }}>
                                {booking.product.name}
                            </Box>
                            <Tag
                                label="Type"
                                values={[pt(booking.product.type)]}
                            />
                        </Stack>
                        <Stack
                            sx={{ grow: 2, p: 2, pt: '10px', height: '144px' }}
                            direction="row"
                            justifyContent="space-evenly"
                            gap={1}
                        >
                            <SquareCard
                                title="Start Date"
                                value={format(
                                    booking.date.start * 1000,
                                    'dd MMM yyyy'
                                )}
                                Icon={CalendarMonthIcon}
                            />
                            <SquareCard
                                title="Start Time"
                                value={format(
                                    booking.date.start * 1000,
                                    'HH:mm'
                                )}
                                Icon={AccessTimeIcon}
                            />
                            <SquareCard
                                title="Duration"
                                value={duration}
                                Icon={AccessTimeIcon}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            width: '50%',
                            borderLeft: '2px solid #C9CEDC',
                            position: 'relative',
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={2}
                            sx={{
                                px: 2,
                                height: '46px',
                                py: '10px',
                                bgcolor: '#F4F6FA',
                                borderRadius: '0px 12px 0px 0px',
                            }}
                        >
                            <Box component="span" sx={{ color: '#8F97AA' }}>
                                Booking Summary
                            </Box>
                            {booking.financials.debt !== undefined && (
                                <StatusTag
                                    status={StatusTagStatuses.NEUTRAL}
                                    label="Debt"
                                    value={formatMoney(
                                        booking.financials.debt,
                                        booking.financials.price.currency.abbr
                                    )}
                                />
                            )}
                        </Stack>
                        <Stack
                            sx={{ height: '144px', overflow: 'hidden', p: 2 }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ mb: 1 }}
                            >
                                <Box
                                    sx={{
                                        color: '#6B748C',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    }}
                                >
                                    PAX
                                </Box>
                                <Box
                                    sx={{
                                        color: '#6B758D',
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                    }}
                                >
                                    {formatValue(booking.participants.pax)}
                                </Box>
                            </Stack>
                            <Divider sx={{ mb: 1 }} />
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ mb: 1 }}
                            >
                                <Box
                                    sx={{
                                        color: '#6B748C',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    }}
                                >
                                    Total Before Discount
                                </Box>
                                <Box
                                    sx={{
                                        color: '#6B758D',
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                    }}
                                >
                                    {formatMoney(
                                        financials.price.price_before_reduction,
                                        financials.price.currency.abbr
                                    )}
                                </Box>
                            </Stack>
                            {financials.price.discount?.amount && (
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{ mb: 1 }}
                                >
                                    <Box
                                        sx={{
                                            color: '#6B748C',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Discount
                                    </Box>
                                    <Box
                                        sx={{
                                            color: '#6B758D',
                                            textAlign: 'right',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                        }}
                                    >
                                        {discountFormatted}
                                    </Box>
                                </Stack>
                            )}
                            <Divider />
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ mt: 1, mb: 1.5 }}
                            >
                                <Box
                                    sx={{
                                        color: '#6B748C',
                                        fontSize: '15px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {financials.price.discount?.amount
                                        ? 'Total after discount'
                                        : 'Total'}
                                </Box>
                                <Box
                                    sx={{
                                        color: '#2B395B',
                                        textAlign: 'right',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                    }}
                                >
                                    {finalPrice}
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    flexShrink={0}
                    direction="column"
                    gap={1}
                    sx={{
                        width: '34px',
                    }}
                >
                    <Stack
                        direction="column"
                        sx={{
                            width: '34px',
                            background: '#FFFFFF',
                            boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                            borderRadius: '12px',
                        }}
                    >
                        <ActionIconButton
                            color="#37C16E"
                            hoverColor="#164d2c"
                            onClick={() => {
                                if (booking.status === BookingStatus.DRAFT) {
                                    setPayDraft(true);
                                } else {
                                    setPayBooking(true);
                                }
                            }}
                            disabled={!booking.financials.debt}
                            icon={<PaymentsOutlinedIcon fontSize="small" />}
                        />
                        <Divider sx={{ mx: 1 }} />
                        <ActionIconButton
                            onClick={() => setRefundBooking(true)}
                            icon={<ReplayIcon fontSize="small" />}
                            disabled={
                                !booking.financials.transaction_details
                                    ?.transactions?.length
                            }
                        />
                    </Stack>
                    <Stack
                        direction="column"
                        sx={{
                            width: '34px',
                            background: '#FFFFFF',
                            boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                            borderRadius: '12px',
                        }}
                    >
                        <ActionIconButton
                            onClick={() => setHoldBooking(true)}
                            icon={<ScheduleIcon fontSize="small" />}
                            disabled={booking.status === BookingStatus.HOLD}
                        />
                        <Divider sx={{ mx: 1 }} />
                        <ActionIconButton
                            onClick={() =>
                                navigate(
                                    `/order/${booking.orderId}/${booking.id}`
                                )
                            }
                            icon={<VisibilityIcon fontSize="small" />}
                        />
                        <Divider sx={{ mx: 1 }} />
                        <ActionIconButton
                            hoverColor="error.main"
                            onClick={() => setDeletingBooking(booking.id)}
                            icon={<DeleteIcon fontSize="small" />}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <ConfirmationDialog
                title="Are you sure you want to delete this booking?"
                content="This will delete this booking permanently. You cannot undo
                    this action."
                confirmText="Delete"
                open={!!deletingBooking}
                isLoading={isRemoveBookingLoading}
                handleCancel={() => setDeletingBooking(undefined)}
                handleConfirm={() => {
                    removeBooking({ bookingId: deletingBooking as string });
                }}
            />
            <ConfirmationDialog
                title="Are you sure you want to hold availability?"
                content=""
                confirmText="Confirm"
                open={holdBooking}
                isLoading={isHoldBookingLoading}
                handleCancel={() => setHoldBooking(false)}
                handleConfirm={() => {
                    holdAvailability({ bookingId: booking.id as string });
                }}
            />
            <ConfirmationDialog
                title="Cannot accept a payment for a draft booking"
                content="Please select one of the following actions"
                confirmText="Hold Availability"
                open={payDraft}
                handleCancel={() => setPayDraft(false)}
                handleConfirm={() => {
                    holdAvailability(
                        { bookingId: booking.id as string },
                        {
                            onSuccess: () => {
                                setPayDraft(false);
                                setPayBooking(true);
                            },
                        }
                    );
                }}
            />
            <TransactionModal
                currency={booking.financials.price.currency.abbr}
                title="Refund a Booking"
                content=""
                confirmText="Refund"
                open={refundBooking}
                isLoading={isAddTransactionLoading}
                handleCancel={() => setRefundBooking(false)}
                handleConfirm={data => {
                    addTransaction({
                        bookingId: booking.id as string,
                        requestBody: {
                            type: TransactionType.REFUND,
                            ...data,
                        },
                    });
                }}
            />
            <TransactionModal
                currency={booking.financials.price.currency.abbr}
                title="Pay For a Booking"
                content=""
                isLoading={isAddTransactionLoading}
                confirmText="Pay"
                open={payBooking}
                handleCancel={() => setPayBooking(false)}
                handleConfirm={data => {
                    addTransaction({
                        bookingId: booking.id as string,
                        requestBody: {
                            type: TransactionType.PAYMENT,
                            ...data,
                        },
                    });
                }}
            />
        </>
    );
};

export default BookingItem;
