import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

function Error404() {
    return (
        <Stack
            direction="row"
            sx={{
                height: 'calc(100vh - 60px)',
                overflow: 'hidden',
                pl: 2,
                pt: 2,
            }}
        >
            <Box sx={{ bgcolor: '#FFF', width: 1, p: 2 }}>
                <Typography variant="h5">Page not found</Typography>
            </Box>
        </Stack>
    );
}

export default Error404;
