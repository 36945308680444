/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComputeAvailabilityReqDto } from '../models/ComputeAvailabilityReqDto';
import type { ComputeAvailabilityResDto } from '../models/ComputeAvailabilityResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AvailabilityService {

    /**
     * Get availability
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns ComputeAvailabilityResDto Successful Response
     * @throws ApiError
     */
    public static computeAvailability(
authorization: string,
requestBody: ComputeAvailabilityReqDto,
): CancelablePromise<ComputeAvailabilityResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/availability',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
