import {
    ComputeAvailabilityResMatrixObjItem0Dto,
    ComputeAvailabilityResMatrixObjItem0EventsItemDto,
    ProductOptionType,
    TimezoneName,
} from '../../requests';
import {
    Availability,
    AvailabilityFilterValues,
    AvailableEvent,
    AvailableProduct,
} from './availability.types';

export const availabilityEventDtoToAvailableEvent = (
    event: ComputeAvailabilityResMatrixObjItem0EventsItemDto
): AvailableEvent => ({
    id: event.id || '',
    time: event.date.start * 1000,
    availableSeats: event.available_seats,
    rawDto: event,
});

export const availabilityProductDtoToAvailabilityProduct = (
    product: ComputeAvailabilityResMatrixObjItem0Dto
): AvailableProduct => ({
    id: product.id,
    type: product.type,
    name: product.name,
    minPrice: product.min_price,
    currency: product.financials.currency.abbr,
    options: product.options?.map(o => ({
        name: o.name as string,
        type: o.type as ProductOptionType,
    })),
    route: product.route.name
        ? {
              name: product.route.name,
              beginningLocation: product.route.beginning_of_route.name,
              endingLocation: product.route.end_of_route.name,
              repeatLocation: product.route.round_trip,
              stops:
                  product.route.stops?.map(stop => ({
                      name: stop?.location?.name as string,
                      arrivalOffset: { day: 0, hour: 0, minute: 0 },
                      duration: { day: 0, hour: 0, minute: 0 },
                  })) || [],
          }
        : undefined,
    events:
        product.events
            ?.sort((a, b) => a.date.start - b.date.start)
            .map(availabilityEventDtoToAvailableEvent) || [],
});

export const computeAvailabilityDtoToAvailability = (
    datetime: string,
    availability: ComputeAvailabilityResMatrixObjItem0Dto[]
): Availability => ({
    date: parseInt(datetime, 10) * 1000,
    products: availability.map(availabilityProductDtoToAvailabilityProduct),
});

export const filterToRequestBodyConverter = (
    filters: AvailabilityFilterValues
) => ({
    date_range:
        filters.dates && filters.dates.startDate && filters.dates.endDate
            ? {
                  tz_name: Intl.DateTimeFormat().resolvedOptions()
                      .timeZone as TimezoneName,
                  start: Math.round(filters.dates.startDate.getTime() / 1000),
                  end:
                      Math.round(filters.dates.endDate.getTime() / 1000) +
                      24 * 60 * 60 -
                      1,
              }
            : {
                  tz_name: Intl.DateTimeFormat().resolvedOptions()
                      .timeZone as TimezoneName,
              },
    pax: filters.pax,
    products: filters.products?.map(id => ({ id })),
    pricing: filters.pricing
        ? {
              currency: {
                  abbr: filters.pricing.currency || 'USD',
                  name: filters.pricing.currency || 'USD',
              },
              min_price: filters.pricing.minPrice,
              max_price: filters.pricing.maxPrice,
          }
        : undefined,
});
