import React from 'react';
import { Box, Chip, ChipProps } from '@mui/material';

export interface TagProps extends Partial<ChipProps> {
    label?: string;
    valueProps?: {
        sx?: Record<string, any>;
    };

    values?: (string | number)[];
}

export const Tag: React.FC<TagProps> = ({
    label,
    valueProps = {},
    values,
    ...rest
}) => {
    return (
        <Chip
            {...rest}
            sx={{
                ...(rest.sx || {}),
                ...(label
                    ? {
                          '& .MuiChip-label': {
                              textWrap:
                                  values?.length && values.length > 1
                                      ? 'wrap'
                                      : 'nowrap',
                              mt: '-4px',
                          },
                      }
                    : {
                          color: '#9198AA',
                          background: 'rgba(85, 181, 207, 0.2)',
                          p: '8px 10px',
                          fontSize: '14px',
                          boxShadow: 0,
                      }),
            }}
            variant="outlined"
            color="primary"
            size="small"
            label={
                <>
                    {label && (
                        <>
                            <Box
                                sx={{ px: 0.5, fontWeight: 400 }}
                                component="span"
                            >
                                {label}
                            </Box>
                            {values?.map(value => (
                                <Box
                                    component="span"
                                    {...valueProps}
                                    sx={{
                                        '&&': valueProps.sx || {},
                                        background: 'rgba(85, 181, 207, 0.2)',
                                        color: '#9198AA',
                                        lineHeight: '14px',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        display: 'inline-block',
                                        textAlign: 'center',
                                        borderRadius: '12px',
                                        minWidth: '25px',
                                        height: '22px',
                                        py: 0.5,
                                        px: 1,
                                        ml: 0.5,
                                        mt: 0.5,
                                    }}
                                >
                                    {value}
                                </Box>
                            ))}
                        </>
                    )}
                    {!label && values?.length && values[0]}
                </>
            }
        />
    );
};
