import { Box, FormControl, FormLabel } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';

export interface ReadonlyTextareaProps {
    content?: string;
    label?: string;
    sx?: Record<string, any>;
}

const ReadonlyTextarea: React.FC<ReadonlyTextareaProps> = props => {
    const { content, label, sx } = props;

    return (
        <FormControl sx={{ ...(sx || {}) }}>
            {label && (
                <FormLabel
                    sx={{
                        paddingLeft: '6px',
                        background: '#FFF',
                        marginBottom: '-6px',
                        marginLeft: '16px',
                        width: '79px',
                    }}
                >
                    {label}
                </FormLabel>
            )}
            <Box
                sx={{
                    borderRadius: label ? '26px' : '12px',
                    maxHeight: '116px',
                    overflow: 'hidden',
                    border: '1px solid #DFE1ED',
                    background: '#FFF',
                    p: 2,
                    pl: label ? '21px' : 2,
                    color: '#949BAC',
                    fontSize: '12px',
                    '& > div': {
                        maxHeight: '84px',
                    },
                }}
            >
                <PerfectScrollbar>{content}</PerfectScrollbar>
            </Box>
        </FormControl>
    );
};

export default ReadonlyTextarea;
