import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import {
    PriceAgeBand,
    PriceFlat,
} from '@travelity/api/src/services/order/order.types';
import { AgebandName, PricingType } from '@travelity/api';

export interface PriceItemProps {
    currency: string;
    price: PriceFlat | PriceAgeBand;
    isLoading: boolean;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export const PriceItem: React.FC<PriceItemProps> = props => {
    const { price, currency, isLoading } = props;

    const prices: { count: number; price: number; label: string }[] = [];
    if (price.type === PricingType.PER_TICKET) {
        prices.push({
            count: price.count,
            price: price.unitPrice,
            label: '',
        });
    } else {
        Object.values(AgebandName).forEach((k: AgebandName) => {
            const p = price[k];
            if (p) {
                prices.push({
                    count: p.count,
                    price: p.unitPrice,
                    label: k,
                });
            }
        });
    }

    const totalPrice = prices.reduce((s, p) => s + p.count * p.price, 0);
    const totalPriceFormatted = price
        ? `${numberWithSpaces(totalPrice)} ${currency}`
        : '';

    return (
        <>
            {!!price.base && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mb: 1 }}
                >
                    <Box
                        sx={{
                            color: '#6B748C',
                            fontSize: '14px',
                            fontWeight: '500',
                        }}
                    >
                        Base Price
                    </Box>
                    <Box
                        sx={{
                            color: '#2B395B',
                            textAlign: 'right',
                            fontSize: '14px',
                            fontWeight: '600',
                        }}
                    >
                        {isLoading ? (
                            <Skeleton width={60} />
                        ) : (
                            `${numberWithSpaces(price.base)} ${currency}`
                        )}
                    </Box>
                </Stack>
            )}
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Box
                    sx={{
                        color: '#6B748C',
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                >
                    {price.type === PricingType.PER_AGEBAND
                        ? 'Total Price Per Agebands'
                        : 'Total Price Per Person'}
                </Box>
                <Box
                    sx={{
                        color: '#6B748C',
                        textAlign: 'right',
                        fontSize: '14px',
                        fontWeight: '600',
                    }}
                >
                    {isLoading ? <Skeleton width={60} /> : totalPriceFormatted}
                </Box>
            </Stack>
            {prices.map(p => (
                <Stack direction="row" justifyContent="space-between">
                    <Box
                        sx={{
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '400',
                        }}
                    >
                        {p.label}
                    </Box>
                    <Box
                        sx={{
                            color: '#6B748C',
                            textAlign: 'right',
                            fontSize: '12px',
                            fontWeight: '400',
                        }}
                    >
                        {isLoading ? (
                            <Skeleton width={60} />
                        ) : (
                            `${p.count} x ${numberWithSpaces(
                                p.price
                            )} ${currency}`
                        )}
                    </Box>
                </Stack>
            ))}
        </>
    );
};
