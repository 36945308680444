import { TFunction } from 'i18next';

export function convertEnumToList<T>(
    en: Record<string, T>,
    translationFunc?: TFunction
): { label: string; value: T }[] {
    //@ts-ignore
    return Object.entries(en).map(([key, value]) => {
        return {
            label: translationFunc
                ? translationFunc(key)
                : key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
            value,
        };
    });
}
