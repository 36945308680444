import React, { forwardRef, memo } from 'react';
// import { useTranslation } from 'react-i18next';
import { Box, Chip, Skeleton, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { AvailableProduct, ProductType } from '@travelity/api';
import { ProductTypeIcon } from '../product-type-icon';

export interface DayProductAvailability {
    product: {
        name: string;
        id: string;
        type: ProductType;
    };
    availability: {
        start: number;
    }[];
}

export interface AvailableItemProps {
    products: AvailableProduct[];
    expanded?: boolean;
    day: number;
    date: Date;
    onEventClick?: (product: AvailableProduct, time: number) => void;
    toggleExpand?: (v: { day: number; date: Date }) => void;
    active?: boolean;
    isToday?: boolean;
    width?: number;
    isLoading?: boolean;
    maxHeight?: number;
}

const DayAvailability = forwardRef<HTMLDivElement, AvailableItemProps>(
    (props, ref) => {
        const {
            products,
            active,
            isToday,
            isLoading,
            expanded,
            day,
            date,
            onEventClick,
            toggleExpand,
            width,
            maxHeight,
        } = props;

        // const weekDay = date ? format(date, 'EEE', { locale: enUS }) : '';

        return (
            <>
                <Stack
                    sx={{
                        maxHeight: maxHeight ? maxHeight - 16 : undefined,
                        display: 'flex',
                        flexGrow: 1,
                        position: 'relative',
                        overflow: 'hidden',
                        background: '#FFFFFF',
                        borderRadius: '8px',
                        ...(expanded
                            ? {
                                  boxShadow:
                                      '0px 0px 20px rgba(178, 185, 205, 0.7)',
                                  // left:
                                  //     weekDay !== 'Sun' && weekDay !== 'Mon'
                                  //         ? -32
                                  //         : 0,
                                  width: width ? `${width + 64}px` : 'auto',
                                  height: 'auto',
                              }
                            : {
                                  height: '100%',
                                  boxShadow:
                                      '0px 0px 4px rgba(178, 185, 205, 0.5)',
                                  border: products.length
                                      ? '1px solid #55B5CF'
                                      : undefined,
                              }),
                    }}
                >
                    <div ref={ref} data-day={active ? day : undefined} />
                    {isLoading ? (
                        <Stack direction="row" justifyContent="center">
                            <Skeleton width={24} sx={{ fontSize: '24px' }} />
                        </Stack>
                    ) : (
                        <>
                            <Box sx={{ textAlign: 'center', mt: '6px' }}>
                                <Box
                                    component="span"
                                    sx={{
                                        color: '#2B395B',
                                        height: '24px',
                                        width: '24px',
                                        display: 'inline-block',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        ...(isToday
                                            ? {
                                                  color: '#36869C',
                                                  background: '#EAEBED',
                                                  borderRadius: '32px',
                                              }
                                            : {}),
                                        ...(products.length === 0
                                            ? {
                                                  color: '#B8BDCA',
                                              }
                                            : {}),
                                        ...(expanded
                                            ? {
                                                  width: 'auto',
                                                  fontWeight: 400,
                                                  letterSpacing: '0.4px',
                                                  mb: 2,
                                              }
                                            : {}),
                                    }}
                                >
                                    {!expanded || !date
                                        ? day
                                        : format(date, 'MMM d, EEE', {
                                              locale: enUS,
                                          }).toUpperCase()}
                                </Box>
                            </Box>
                            <Box
                                sx={{ pb: 1, flexGrow: 1 }}
                                component={expanded ? PerfectScrollbar : 'div'}
                            >
                                {products.map(
                                    (
                                        {
                                            name,
                                            type,
                                            id,
                                            events,
                                            minPrice,
                                            currency,
                                        },
                                        i
                                    ) => (
                                        <Box
                                            key={id}
                                            sx={{
                                                px: '6px',
                                                position: 'relative',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    ...(i !== 0
                                                        ? {
                                                              mt: 1,
                                                              pt: 1,
                                                              borderTop:
                                                                  '1px solid #DFE1ED',
                                                          }
                                                        : {}),
                                                    color: '#67728B',
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'nowrap',
                                                }}
                                            >
                                                <ProductTypeIcon type={type} />
                                                <Box
                                                    sx={{
                                                        ml: '6px',
                                                        flexGrow: 2,
                                                        color: '#67728B',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {name}
                                                </Box>
                                                {expanded && (
                                                    <Box
                                                        sx={{
                                                            color: '#67728B',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            opacity: 0.5,
                                                        }}
                                                    >
                                                        {minPrice} {currency}
                                                    </Box>
                                                )}
                                            </Box>
                                            <Stack
                                                direction="row"
                                                flexWrap={
                                                    expanded
                                                        ? 'wrap'
                                                        : undefined
                                                }
                                                sx={{ ml: '17px' }}
                                            >
                                                {events.map(({ time }) => (
                                                    <Chip
                                                        key={time}
                                                        size="small"
                                                        clickable
                                                        onClick={() =>
                                                            onEventClick?.(
                                                                products[i],
                                                                time
                                                            )
                                                        }
                                                        sx={{
                                                            m: '1px 2px 2px 1px',
                                                            color: '#3B4D7D',
                                                            height: 'auto',
                                                            fontWeight: 500,
                                                            '& > .MuiChip-label':
                                                                {
                                                                    px: '6px',
                                                                },
                                                            bgcolor: '#DDF0F5',
                                                            '&:hover, &:active':
                                                                {
                                                                    bgcolor:
                                                                        '#DDF0F5',
                                                                },
                                                        }}
                                                        variant="filled"
                                                        label={format(
                                                            time,
                                                            'HH:mm'
                                                        )}
                                                    />
                                                ))}
                                            </Stack>
                                            {!expanded && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 0,
                                                        bottom: 0,
                                                        background:
                                                            'linear-gradient(270deg, #FFFFFF 19.66%, rgba(255, 255, 255, 0) 100%)',
                                                        height: '40px',
                                                        width: '33%',
                                                        borderRadius:
                                                            '0 0 8px 0',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    )
                                )}
                                {!expanded && !!products.length && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            width: 1,
                                            background: '#F4F6FA',
                                            borderRadius: '0px 0px 6px 6px',
                                            textAlign: 'center',
                                            fontSize: '12px',
                                            '& > a': {
                                                color: '#2B395B',
                                            },
                                        }}
                                    >
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a
                                            onClick={e => {
                                                e.preventDefault();
                                                toggleExpand?.({ day, date });
                                            }}
                                            href="#"
                                        >
                                            See All Products
                                        </a>
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                </Stack>
                {active === false && !isLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: '#FFFFFF',
                            opacity: 0.7,
                            width: '100%',
                            height: '100%',
                            borderRadius: '8px',
                        }}
                    />
                )}
            </>
        );
    }
);

export default memo(DayAvailability);
