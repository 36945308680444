import React from 'react';
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';

export interface TabsProps extends MuiTabsProps {}

export const Tabs: React.FC<TabsProps> = props => {
    const { children, ...rest } = props;

    return (
        <MuiTabs
            textColor="inherit"
            variant="fullWidth"
            {...rest}
            sx={{
                minHeight: 'auto',
                '& .MuiTabs-indicator': {
                    display: 'none',
                },
                ...(rest.sx || {}),
            }}
        >
            {children}
        </MuiTabs>
    );
};
