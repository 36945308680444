import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from '@travelity/form';
import { Box } from '@mui/material';
import { ScheduleOptionTypes } from '@travelity/api';
import { ProductScheduleForm } from '../../../components/product-schedule-form';
import schema from '../../../components/product-schedule-form/product-schedule-form.schema';
import StepContent from './step-content';
import { useStepperContext } from '../../../components/stepper/stepper.provider';
import { AddProductFormState } from '../add-product.types';
import { useHelperContext } from '../../../contexts/helper-context';

export interface ScheduleStepProps {}

const ScheduleStep: React.FC<ScheduleStepProps> = () => {
    const { state, next } = useStepperContext<AddProductFormState>();

    const onSubmit = useCallback((data: Record<string, any>) => {
        next({ ...state, schedule: data });
    }, []);
    const { Form, formState, watch } = useForm({
        onSubmit,
        schema,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: state.schedule,
    });
    const errors = useMemo(() => {
        return Object.values(formState.errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [formState]);

    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo();
    }, [setInfo]);

    useEffect(() => {
        const callback = () => {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}> Supported types</Box>
                    Travelity supports two types of product schedules - seasonal
                    and custom.
                    <br />
                    These types can be specified/used in a mix.
                </>
            );
        };
        const el = document.querySelector('.add-button');
        el?.addEventListener('click', callback);
        return () => el?.removeEventListener('focus', callback);
    }, []);

    const items = watch('items');
    useEffect(() => {
        const lastItem = items?.length ? items[items.length - 1] : undefined;
        if (lastItem?.isNew) {
            if (lastItem.type === ScheduleOptionTypes.SEASON) {
                setInfo(
                    <>
                        <Box sx={{ fontWeight: 'bold' }}>
                            Seasonal scheduling
                        </Box>
                        Season is a period of arbitrary days, during which
                        product has a specific recurrence and starting hours.
                        <br />
                        You can have as many seasons as you like.
                    </>
                );
            } else {
                setInfo(
                    <>
                        <Box sx={{ fontWeight: 'bold' }}>Custom dates</Box>
                        You can also select specific dates for one-time
                        occurrences of your product.
                    </>
                );
            }
        }
    }, [items]);

    return (
        <StepContent title="Schedule" errors={errors}>
            <Form id="step">
                <ProductScheduleForm />
            </Form>
        </StepContent>
    );
};

export default React.memo(ScheduleStep);
