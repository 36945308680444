import React from 'react';
import { Stack } from '@mui/material';

export interface ProductDetailsFormProps {
    preview?: boolean;
}

const OrderFinancialInformationForm: React.FC<ProductDetailsFormProps> = ({
    preview,
}) => {
    // const { watch } = useFormContext();

    return preview ? <Stack spacing={2} /> : <Stack spacing={3} />;
};

export default React.memo(OrderFinancialInformationForm);
