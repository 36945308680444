import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Copyright from '../../components/copyright';
import { useRegisterUser } from '../../hooks/api';

function AuthRegister() {
    const { t } = useTranslation('auth');
    const navigate = useNavigate();
    const { registerUser, success } = useRegisterUser();
    const { user } = useAuth0();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (success) navigate('/');
    }, [success]);

    const onSubmit = handleSubmit(validData => {
        registerUser({
            name: {
                first: validData.firstName,
                last: validData.lastName,
            },
            occupation: validData.occupation,
            contact_details: {
                emails: user?.email ? [user.email] : [],
                phone_numbers: [
                    {
                        type: 'work',
                        calling_code: 374,
                        number: 588885,
                    },
                ],
                primary_email: user?.email,
                primary_phone_number: {
                    type: 'work',
                    calling_code: 374,
                    number: 588885,
                },
            },
        });
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('signUpTitle')}
                </Typography>
                <Box
                    component="form"
                    noValidate
                    onSubmit={onSubmit}
                    sx={{ mt: 3 }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                id="firstName"
                                label={t('firstName')}
                                {...register('firstName', { required: true })}
                                autoFocus
                                error={!!errors.firstName}
                                helperText={
                                    errors.firstName
                                        ? t('This field is required')
                                        : undefined
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label={t('lastName')}
                                autoComplete="family-name"
                                {...register('lastName', { required: true })}
                                error={!!errors.lastName}
                                helperText={
                                    errors.lastName
                                        ? t('This field is required')
                                        : undefined
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({
                                        field,
                                    }: {
                                        field: Record<string, any>;
                                    }) => (
                                        <Select
                                            labelId="occupation"
                                            id="occupation"
                                            label={t('occupation')}
                                            {...field}
                                        >
                                            <MenuItem value="guide">
                                                Guide
                                            </MenuItem>
                                            <MenuItem value="driver">
                                                Driver
                                            </MenuItem>
                                            <MenuItem value="tour_agent">
                                                Tour Agent
                                            </MenuItem>
                                        </Select>
                                    )}
                                    name="occupation"
                                    rules={{ required: true }}
                                    control={control}
                                />
                                <InputLabel
                                    id="demo-simple-select-label"
                                    required
                                >
                                    {t('occupation')}
                                </InputLabel>
                                {errors.occupation && (
                                    <FormHelperText>
                                        {t('This field is required.')}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t('finishSignUp')}
                    </Button>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}

export default AuthRegister;
